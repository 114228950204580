import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Footer from '../footer/footer'
import { useAlertBox } from '../../contexts'
import HeaderLogged from '../header/header-logged'
import { getUser } from '../../utils/storage/user'
import styled from 'styled-components'

const BaseLayout = ({ children, menuDefault, menuFooter }) => {
  const { alertBox } = useAlertBox()
  const user = getUser()
  const [menu, setMenu] = useState({
    menuDefault: [],
    menuFooter: []
  })

  useEffect(() => {
    (async () => {
      try {
        const { menuItems: menuItemsFooter } = await menuFooter.load()
        const { menuItems: menuItemsDefault } = await menuDefault.load()
        setMenu({
          menuDefault: menuItemsDefault,
          menuFooter: menuItemsFooter
        })
      } catch (error) {
        alertBox(error.message)
      }
    })()
  }, [])

  return (
    <>
      <HeaderLogged menu={menu.menuDefault} user={user ? user.user : null} />
      <FullWrapper>
        {children}
      </FullWrapper>
      <Footer menu={menu.menuFooter} />
    </>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  menuDefault: PropTypes.object.isRequired,
  menuFooter: PropTypes.object.isRequired
}

export default BaseLayout

const FullWrapper = styled.div`
  min-height: calc(100vh -  271px);
`
