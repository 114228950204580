import axios from 'axios'
import { Auth } from 'aws-amplify'

export class AxiosHttpClient {
  #api
  #baseUrl

  constructor (baseUrl) {
    this.#baseUrl = baseUrl
    const api = axios
    api.interceptors.response.use(this.#handleSuccess, this.#handleError)
    this.#api = api
  }

  async request ({ url, method, data, headers = {}, params }) {
    let axiosResponse

    try {
      const token = await this.#updateAmplifyToken()
      axiosResponse = await this.#api.request({
        url: this.#baseUrl + url,
        method,
        data,
        headers: {
          ...token,
          ...headers
        },
        params
      })
    } catch (error) {
      axiosResponse = error.response
    }

    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data
    }
  }

  #updateAmplifyToken = async () => {
    try {
      const session = await Auth.currentSession()
      return {
        Authorization: session.getIdToken().getJwtToken()
      }
    } catch (_) {
      return {}
    }
  }

  #handleSuccess = (response) => {
    return response
  }

  #handleError = (error) => {
    const { status } = error.response
    switch (status) {
      case 401:
        this.#redirectTo(document, '/sair')
        break
      case 403:
        this.#redirectTo(document, '/403')
        break
      case 404:
        this.#redirectTo(document, '/404')
        break
    }
    return Promise.reject(error)
  }

  #redirectTo = (document, path) => {
    document.location = path
  }
}
