import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col } from '../index'
import { customTheme } from '../../../../styles/theme'
import { getImage } from '../../../../utils/image'
import { useTranslation } from 'react-i18next'
import TransLateHook from '../../../../pages/contents/skill-page/translate-hook'

const CardDefaultItem = ({
  content,
  exclusiveContentModal,
  favoriteContent,
  col = 4
}) => {
  const { t } = useTranslation()
  const redirectTo = content.folder['@type'] === 'Skill'
    ? `/habilidades/${content?.folder?.id}/${content.slug}`
    : `/${content?.folder?.slug}/${content?.slug}`

  const TRANSLATE_TITLE = TransLateHook()

  // const TRANSLATE_TITLE_HEADER = {
  //   'Maternidade e Carreira': `${t('video_header_title.title1')}`,
  //   'Disciplina Positiva': `${t('video_header_title.title2')}`,
  //   Networking: `${t('video_header_title.title3')}`,
  //   'Desenvolvimento de Equipes': `${t('video_header_title.title4')}`,
  //   'Comunicação Estratégica': `${t('video_header_title.title5')}`

  // }

  const TRANSLATE_TITLE_HEADER = {
    'A ciência da autoconfiança': `${t('skills.title_01')}`,
    'Posicionamento Estratégico': `${t('skills.title_02')}`,
    'Alta Performance Emocional': `${t('skills.title_03')}`,
    'Foco e Atenção Plena': `${t('skills.title_04')}`,
    'Autoralidade e Protagonismo': `${t('skills.title_05')}`,
    Adaptabilidade: `${t('skills.title_06')}`,
    'Disciplina Positiva': `${t('skills.title_07')}`,
    'Negociação e Influência': `${t('skills.title_08')}`,
    'Pensamento Crítico': `${t('skills.title_09')}`,
    'Marca Pessoal': `${t('skills.title_10')}`,
    Inovação: `${t('skills.title_11')}`,
    Liderança: `${t('skills.title_12')}`,
    Networking: `${t('skills.title_13')}`,
    'Comunicação Estratégica': `${t('skills.title_14')}`,
    'Maternidade e Carreira': `${t('skills.title_15')}`,
    'Desenvolvimento de Equipes': `${t('skills.title_16')}`,
    'Rolou ao vivo': `${t('watch.watch_live')}`
  }

  const TRANSLATE_JOBS = {
    'Co-Fundadora Todas Group': `${t('video_job_title.title1')}`,
    'Fund. B2Mamy': `${t('video_job_title.title2')}`,
    Neurocientista: `${t('video_job_title.title3')}`,
    'Linkedln TopVoice e Conselheira de empresas': `${t('skills.details_01')}`,
    'Consultora de Diversidade': `${t('skills.details_02')}`,
    'Diretora de RH Diageo e TedXSpeaker': `${t('skills.details_03')}`,
    'Dir. Comercial Cielo': `${t('skills.details_04')}`,
    'Psiquiatra especialista em estilo de vida': `${t('skills.details_05')}`,
    'Head de MKT Tik Tok Am. Latina': `${t('skills.details_06')}`
  }

  return (
    <Col pos="relative" sm={col}>
      <Card to={redirectTo}>
        <BookCover>
          <img src={getImage(content.thumb, false)} alt={content.title} />
        </BookCover>
        <CardBody>
          <TagStyled>{TRANSLATE_TITLE_HEADER[content?.folder?.title] ? TRANSLATE_TITLE_HEADER[content?.folder?.title] : content?.folder?.title}</TagStyled>
          <h3>{TRANSLATE_TITLE[content.title] ? TRANSLATE_TITLE[content.title] : content.title}</h3>
          <span>
            {content.author.name}
            {content.author.companyJobRole &&
              ` - ${TRANSLATE_JOBS[content.author.companyJobRole] ? TRANSLATE_JOBS[content.author.companyJobRole] : content.author.companyJobRole}`}
          </span>
        </CardBody>
      </Card>
    </Col>
  )
}

CardDefaultItem.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    author: PropTypes.object.isRequired,
    thumb: PropTypes.object,
    folder: PropTypes.object,
    slug: PropTypes.string,
    hasAccess: PropTypes.string,
    currentContentUserCollection: PropTypes.object,
    duration: PropTypes.string
  }),
  exclusiveContentModal: PropTypes.func,
  favoriteContent: PropTypes.any,
  col: PropTypes.any
}

export default CardDefaultItem

const Card = styled(Link)`
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.2);
  text-decoration: none;
  display: flex;
  padding: 15px;
  border-radius: 8px;
  overflow: hidden;
`

const BookCover = styled.div`
  position: relative;
  aspect-ratio: 9 / 13;
  overflow: hidden;
  width: 30%;
  margin: auto;
  border-radius: 8px;

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
`

const CardBody = styled.div`
  width: 75%;
  padding: 0 5px 0 15px;
  aspect-ratio: 3/1.45;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 18px;
    margin: 0;
    color: ${customTheme.colors.purple};
  }

  span {
    font-size: 14px;
    color: ${customTheme.colors.purple};
  }
`

const TagStyled = styled.span`
  color: ${customTheme.colors.purple};
  border: solid 1px ${customTheme.colors.purple};
  width: fit-content;
  padding: 1px 15px 0;
  font-size: 12px;
  font-weight: bold;
  border-radius: 12px;
`
