import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '../ui/components'
import Header from '../header/header'
import Footer from '../footer/footer'
import { useAlertBox } from '../../contexts'

const AuthLayout = ({ children, menuFooter }) => {
  const { alertBox } = useAlertBox()
  const [menu, setMenu] = useState({
    menuFooter: []
  })

  useEffect(() => {
    (async () => {
      try {
        const { menuItems: menuItemsFooter } = await menuFooter.load()
        setMenu({
          menuFooter: menuItemsFooter
        })
      } catch (error) {
        alertBox(error.message)
      }
    })()
  }, [])

  return (
    <Box>
      <Header />
      {children}
      <Footer menu={menu.menuFooter} />
    </Box>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  menuFooter: PropTypes.object.isRequired
}

export default AuthLayout
