import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from '../../components'
import { Icon } from '../../../../assets/icons'

const CardFolderItem = ({ icon, title, description, slug }) => {
  return (
    <Item key={slug}>
      {!!icon && <Icon name={`${icon.name}-svg`} size="480" style={{ marginBottom: 10 }} />}
      <Typography attr="h3">{title}</Typography>
      {description && <CustomHtml dangerouslySetInnerHTML={{ __html: description }} />}
    </Item>
  )
}

CardFolderItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  description: PropTypes.string,
  slug: PropTypes.string
}

export default CardFolderItem

const Item = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  height: 100%;
  text-align: center; 

  i,
  svg {
    width: 55px;
    height: 45px;
    fill: #e3e1e0;
    color: #e3e1e0;
  }
`

const CustomHtml = styled.div`
  font-size: 12px;
  margin-top: 5px;
`
