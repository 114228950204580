import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Facebook } from '../social/facebook.svg'
import { ReactComponent as Instagram } from '../social/instagram.svg'
import { ReactComponent as Linkedin } from '../social/linkedin.svg'
import { ReactComponent as Twitter } from '../social/twitter.svg'
import { ReactComponent as Youtube } from '../social/youtube.svg'
import { customTheme } from '../../../styles/theme'

const IconSocial = ({ name, ...rest }) => {
  Object.assign(rest, {
    width: customTheme.social.width,
    height: customTheme.social.height,
    fill: customTheme.social.color
  })
  switch (true) {
    case /facebook/.test(name): return <Facebook {...rest} />
    case /instagram/.test(name): return <Instagram {...rest} />
    case /linkedin/.test(name): return <Linkedin {...rest} />
    case /twitter/.test(name): return <Twitter {...rest} />
    case /youtube/.test(name): return <Youtube {...rest} />
    default: return null
  }
}

IconSocial.propTypes = {
  name: PropTypes.string.isRequired
}

export default IconSocial
