import React from 'react'
import PropTypes from 'prop-types'
import Reaptcha from 'reaptcha'
import { SettingsHelper } from '../../../../helpers/settings-helper/settings-helper'

const Recaptcha = ({ name, isSubmitting, ...el }) => {
  const settings = new SettingsHelper()
  const handleVerify = (token) => {
    if (el.setFieldValue) {
      el.setFieldValue(name, token)
    }
  }

  return (
    <Reaptcha
      sitekey={settings.loadRecaptchaSiteKey()}
      size="normal"
      theme="light"
      explicit={false}
      onVerify={handleVerify}
    />
  )
}

Recaptcha.propTypes = {
  name: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

export default Recaptcha
