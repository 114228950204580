import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { IconStyled } from './default_styled'

export const Compass = (props) => {
  return (
    <CompassStyled {...props} color={props.color}>
      <svg viewBox={`0 0 ${props.size} ${props.size}`} height={18} width={18} xmlns="http://www.w3.org/2000/svg">
        <path strokeWidth="8" d="m240 0c-132.546875 0-240 107.453125-240 240s107.453125 240 240 240 240-107.453125 240-240c-.148438-132.484375-107.515625-239.851562-240-240zm0 464c-123.710938 0-224-100.289062-224-224s100.289062-224 224-224 224 100.289062 224 224c-.140625 123.652344-100.347656 223.859375-224 224zm0 0" />
        <path strokeWidth="8" d="m240 40c-110.457031 0-200 89.542969-200 200s89.542969 200 200 200 200-89.542969 200-200c-.125-110.40625-89.59375-199.875-200-200zm0 384c-101.621094 0-184-82.378906-184-184s82.378906-184 184-184 184 82.378906 184 184c-.113281 101.574219-82.425781 183.886719-184 184zm0 0" />
        <path strokeWidth="8" d="m357.824219 122.175781c-2.546875-2.539062-6.472657-3.066406-9.601563-1.289062l-142.824218 81.511719c-.085938.058593-.125.152343-.214844.203124-.914063.785157-1.769532 1.640626-2.558594 2.558594-.050781.085938-.144531.128906-.199219.214844l-81.539062 142.824219c-1.785157 3.132812-1.253907 7.070312 1.292969 9.621093 2.550781 2.546876 6.488281 3.078126 9.621093 1.292969l142.800781-81.511719c.085938-.058593.125-.152343.214844-.203124.914063-.785157 1.769532-1.640626 2.558594-2.558594.050781-.085938.144531-.128906.199219-.214844l81.539062-142.800781c1.800781-3.136719 1.273438-7.09375-1.289062-9.648438zm-208.511719 208.511719 61.808594-108.246094 46.480468 46.4375zm119.566406-73.085938-46.398437-46.402343 108.246093-61.808594zm0 0" />
        <path strokeWidth="8" d="m112 232h-32c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h32c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0" />
        <path strokeWidth="8" d="m400 232h-32c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h32c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0" />
        <path strokeWidth="8" d="m240 120c4.417969 0 8-3.582031 8-8v-32c0-4.417969-3.582031-8-8-8s-8 3.582031-8 8v32c0 4.417969 3.582031 8 8 8zm0 0" />
        <path strokeWidth="8" d="m240 360c-4.417969 0-8 3.582031-8 8v32c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-32c0-4.417969-3.582031-8-8-8zm0 0" />
      </svg>
    </CompassStyled>
  )
}

Compass.propTypes = {
  props: PropTypes.shape({
    size: PropTypes.any,
    color: PropTypes.any
  })
}

const CompassStyled = styled(IconStyled)`
  svg {
    fill: ${props => props.color ? props.color : '#828282'};
    stroke: ${props => props.color ? props.color : '#828282'}
  }
`
