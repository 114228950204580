import {
  addLocalStorage,
  getLocalStorage,
  removeLocalStorage
} from '../../../infra/storage/localStorage'

export const currentLastUpdated = () => {
  return new Date().toLocaleString().slice(0, 13)
}

export const getLastUpdated = () => {
  return getLocalStorage('lastUpdated')
}

export const setLastUpdated = async () => {
  await addLocalStorage('lastUpdated', currentLastUpdated())
}

export const removeLastUpdated = () => {
  removeLocalStorage('lastUpdated')
}

export const outdatedCache = async () => {
  const updateCache = getLastUpdated() !== currentLastUpdated()

  if (updateCache) {
    await setLastUpdated()
  }

  return updateCache
}
