import React from 'react'
import styled from 'styled-components'
// import { FaRegShareSquare } from 'react-icons/fa'
import BannerCoach from './banner-coach'
const SessionTwo = () => {
  return (
    <Container>
      <SubContent>
        <HeaderTitle>Passo 2. Conheça sua coach e prepare-se!</HeaderTitle>
        <NormalTextGray>Para guiar este desenvolvimento sua Coach de carreira será:</NormalTextGray>
      </SubContent>

      {/* <ButtonStep>
        <FaRegShareSquare size={20} color="#fff" />
        Clique aqui para agendar
      </ButtonStep> */}

      <BannerCoach />
    </Container>
  )
}

export default SessionTwo

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:13px;
  
`
const HeaderTitle = styled.h4`
  font-size:22.4px;
  font-weight: 400;
  color: #121214;
  line-height: 22.4px;
  font-family: "Playfair Display","Roboto","Open Sans",sans-serif;
`

const NormalTextGray = styled.p`
  font-size: 16px;
  font-weight:400;
  color: #7A7A7A;
  line-height: 24px;
  font-family: "Roboto","Open Sans",sans-serif;`

const SubContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:10px;
  margin-top:10px;
`

// const ButtonStep = styled.button`
//   display: flex;
//   width: 270px;
//   min-width: 270px;
//   height: 50px;
//   border-radius: 7px;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   background-color: #e14b2b;
//   border-color: #e14b2b;
//   color: #FFF;
//   font-weight: 700;
//   font-size: 16px;
//   gap: 10px;
//   `
