import React from 'react'
import styled from 'styled-components'
import { IconStyled } from './default_styled'

export const Book = (props) => {
  return (
    <BookStyled {...props}>
      <svg focusable="false"  viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M19.3288 2.09904L17.9269 2.09897V0.647731C17.9269 0.315477 17.6838 0.0331882 17.3552 -0.0159699C17.2978 -0.0245894 15.932 -0.222973 14.2874 0.0762856C12.4539 0.409887 10.9917 1.23258 9.99997 2.47628C9.00818 1.23258 7.54597 0.409887 5.71257 0.0762856C4.06786 -0.222905 2.70208 -0.0245894 2.64477 -0.0159699C2.31615 0.0332555 2.07306 0.315544 2.07306 0.647731V2.09897H0.671109C0.300538 2.09897 0 2.39951 0 2.77015V15.4169C0 15.6342 0.105185 15.838 0.282356 15.964C0.459527 16.0899 0.686664 16.1222 0.891849 16.0507C0.942219 16.0333 5.97136 14.3169 9.72145 16.0275C9.89842 16.1082 10.1016 16.1082 10.2786 16.0275C14.0181 14.3218 19.058 16.0334 19.1081 16.0507C19.1801 16.0758 19.2545 16.0881 19.3288 16.0881C19.4663 16.0881 19.6025 16.0458 19.7176 15.964C19.8948 15.8381 20 15.6343 20 15.417V2.77022C19.9999 2.39958 19.6995 2.09904 19.3288 2.09904ZM1.34229 14.5189V3.44126H2.07306V12.379C2.07306 12.574 2.15838 12.7592 2.30592 12.8867C2.45346 13.0142 2.64969 13.0713 2.84255 13.0428C2.87959 13.0375 5.79513 12.6372 7.92523 14.0987C5.30133 13.65 2.67521 14.169 1.34229 14.5189ZM9.32886 13.4934C8.39068 12.6457 7.16866 12.0725 5.71257 11.8076C5.01183 11.6801 4.36174 11.6429 3.84187 11.6429C3.68753 11.6429 3.54463 11.6462 3.41541 11.6514V1.26403H3.41534C4.70524 1.20584 7.84307 1.34376 9.32886 3.86247V13.4934ZM10.6711 3.86253C12.1515 1.3546 15.2943 1.21042 16.5846 1.26571V11.6514C16.0201 11.6286 15.196 11.6422 14.2874 11.8076C12.8313 12.0725 11.6093 12.6457 10.6711 13.4934V3.86253ZM12.0725 14.0991C14.2028 12.6367 17.1199 13.0374 17.1563 13.0428C17.3496 13.0718 17.5457 13.015 17.6937 12.8876C17.8418 12.76 17.9269 12.5744 17.9269 12.379V3.44132H18.6576V14.5189C17.3243 14.1688 14.6971 13.6496 12.0725 14.0991Z" />
      </svg>
    </BookStyled>
  )
}

const BookStyled = styled(IconStyled)``
