import React from 'react'
import Cropper from 'react-easy-crop'
import PropTypes from 'prop-types'

const ReactEasyCrop = (props) => {
  return (
    <Cropper {...props} />
  )
}

ReactEasyCrop.propTypes = {
  image: PropTypes.string,
  crop: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  zoom: PropTypes.number.isRequired,
  aspect: PropTypes.number.isRequired,
  rotation: PropTypes.number.isRequired,
  onCropChange: PropTypes.func.isRequired,
  onZoomChange: PropTypes.func.isRequired,
  onRotationChange: PropTypes.func.isRequired,
  onCropComplete: PropTypes.func.isRequired
}

export default ReactEasyCrop
