import { RemoteLoadContent } from '../../../data/usecases/contents/remote-load-content'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'

export const makeLoadContent = (slug) => {
  return new RemoteLoadContent(`/contents/slug/${slug}`, makeAxiosHttpClient())
}

export const makeLoadContentInternal = () => {
  return new RemoteLoadContent('', makeAxiosHttpClient())
}
