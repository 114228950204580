import * as Yup from 'yup'
import { I18n } from 'aws-amplify'
import { isCPF } from '../yup-methods-validations/identity'
import { isValidCardNumber } from '../yup-methods-validations/credit-card'
import { isValidDateExp } from '../yup-methods-validations/isValidDateExp'

Yup.addMethod(Yup.string, 'cpf', function (message) {
  return Yup.mixed().test('cpf', message, (value) => isCPF(value))
})

Yup.addMethod(Yup.string, 'isValidCardNumber', function (message) {
  return Yup.mixed().test('isValidCardNumber', message, (value) => isValidCardNumber(value))
})

Yup.addMethod(Yup.string, 'inputDateExp', function (message) {
  return Yup.mixed().test('isValidaDateExp', message, (value) => isValidDateExp(value))
})

export const getValidationSchemaByName = (name) => {
  if (!Array.isArray(name)) {
    switch (name) {
      case 'inputRequired':
        return Yup.string()
          .required(I18n.get('ValidateRequiredField'))
      case 'emailRequired':
        return Yup.string()
          .email(I18n.get('ValidateInvalidEmail'))
          .required(I18n.get('ValidateRequiredField'))
      case 'passwordMinRequired':
        return Yup.string()
          .min(8, I18n.get('ValidateMinPassword'))
          .required(I18n.get('ValidateRequiredField'))
      case 'passwordWithMatches':
        return Yup.string()
          .required(I18n.get('ValidateRequiredField'))
          .min(6, I18n.get('ValidateMinPassword'))
          .max(24, I18n.get('ValidateMaxPassword'))
          // .matches(/[0-9]/, I18n.get('ValidateOneNumber'))
          // .matches(/[a-zA-z]+/, I18n.get('ValidateOneLetter'))
          // .matches(/[a-z]/, I18n.get('ValidateOneLowerChar'))
          // .matches(/[A-Z]/, I18n.get('ValidateOneUpperChar'))
          // .matches(/[!@#$%*()_/\\\-+^&{}:;?.]/, I18n.get('ValidateOneSpecialChar'))
      case 'confirmPassword':
        return Yup.string().when('password', {
          is: (val) => val && val.length >= 6,
          then: Yup.string()
            .oneOf([Yup.ref('password')], I18n.get('ValidatePasswordNotEquals'))
            .required(I18n.get('ValidateRequiredField'))
        })
      case 'inputIdentity':
        return Yup.string()
          .required(I18n.get('ValidateRequiredField'))
          .cpf(I18n.get('ValidateInvalidCPF'))
      case 'inputCreditCard':
        return Yup.string()
          .required(I18n.get('ValidateRequiredField'))
          .isValidCardNumber(I18n.get('ValidateInvalidCardNumber'))
      case 'inputDateExp':
        return Yup.string()
          .required(I18n.get('ValidateRequiredField'))
          .inputDateExp(I18n.get('ValidateInvalidDateExp'))
      case 'inputRequiredCode':
        return Yup.string()
          .required(I18n.get('ValidateRequiredField'))
          .matches(/[0-9]/, I18n.get('ValidateOnlyNumbers'))
      case 'isValidName':
        return Yup.string()
          .required(I18n.get('ValidateRequiredField'))
          .min(2, I18n.get('ValidateMinName'))
      default: throw new Error(`Validation ${name} don't found`)
    }
  }
}
