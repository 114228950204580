import React from 'react'
import styled from 'styled-components'
import { IconStyled } from './default_styled'

export const GiftIcon = (props) => {
  return (
    <GiftStyled {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.27273 10.9091C3.87522 10.9091 4.36364 11.3975 4.36364 12V21.8182H19.6364V12C19.6364 11.3975 20.1248 10.9091 20.7273 10.9091C21.3298 10.9091 21.8182 11.3975 21.8182 12V22.9091C21.8182 23.5116 21.3298 24 20.7273 24H3.27273C2.67023 24 2.18182 23.5116 2.18182 22.9091V12C2.18182 11.3975 2.67023 10.9091 3.27273 10.9091Z" fill="#25D366" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 6.54545C0 5.94296 0.488417 5.45455 1.09091 5.45455H22.9091C23.5116 5.45455 24 5.94296 24 6.54545V12C24 12.6025 23.5116 13.0909 22.9091 13.0909H1.09091C0.488417 13.0909 0 12.6025 0 12V6.54545ZM2.18182 7.63636V10.9091H21.8182V7.63636H2.18182Z" fill="#25D366" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 5.45455C12.6025 5.45455 13.0909 5.94296 13.0909 6.54545V22.9091C13.0909 23.5116 12.6025 24 12 24C11.3975 24 10.9091 23.5116 10.9091 22.9091V6.54545C10.9091 5.94296 11.3975 5.45455 12 5.45455Z" fill="#25D366" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.09091 2.18182C6.65692 2.18182 6.2407 2.35422 5.93383 2.6611C5.62695 2.96798 5.45455 3.38419 5.45455 3.81818C5.45455 4.25217 5.62695 4.66839 5.93383 4.97527C6.2407 5.28214 6.65692 5.45455 7.09091 5.45455H10.5317C10.3985 5.10612 10.2299 4.723 10.0214 4.34391C9.33526 3.09638 8.39335 2.18182 7.09091 2.18182ZM12 6.54545C13.0697 6.33151 13.0697 6.33114 13.0696 6.33074L13.069 6.32768L13.0678 6.32185L13.0641 6.30429C13.0611 6.29 13.0569 6.2706 13.0515 6.24642C13.0407 6.19808 13.025 6.13053 13.004 6.0465C12.962 5.87865 12.8985 5.64374 12.8099 5.36385C12.6339 4.80798 12.3527 4.05529 11.9331 3.29245C11.1193 1.81272 9.60665 0 7.09091 0C6.07826 0 5.1071 0.402271 4.39105 1.11832C3.675 1.83437 3.27273 2.80554 3.27273 3.81818C3.27273 4.83083 3.675 5.802 4.39105 6.51804C5.1071 7.23409 6.07826 7.63636 7.09091 7.63636H12C12.3268 7.63636 12.6364 7.48983 12.8436 7.23708C13.0509 6.98433 13.1337 6.65123 13.0696 6.33074L12 6.54545Z" fill="#25D366" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0669 3.29245C12.8807 1.81272 14.3933 0 16.9091 0C17.9217 0 18.8929 0.402271 19.609 1.11832C20.325 1.83437 20.7273 2.80554 20.7273 3.81818C20.7273 4.83083 20.325 5.802 19.609 6.51804C18.8929 7.23409 17.9217 7.63636 16.9091 7.63636H12C11.6732 7.63636 11.3636 7.48983 11.1564 7.23708C10.9491 6.98433 10.8662 6.65199 10.9303 6.33151L12 6.54545C10.9303 6.33151 10.9302 6.3319 10.9303 6.33151L10.9306 6.32985L10.931 6.32768L10.9322 6.32185L10.9359 6.30429C10.9389 6.29 10.9431 6.2706 10.9485 6.24642C10.9593 6.19808 10.975 6.13053 10.996 6.0465C11.038 5.87865 11.1015 5.64374 11.1901 5.36385C11.3661 4.80798 11.6473 4.05529 12.0669 3.29245ZM13.4683 5.45455H16.9091C17.3431 5.45455 17.7593 5.28214 18.0662 4.97527C18.3731 4.66839 18.5455 4.25217 18.5455 3.81818C18.5455 3.38419 18.3731 2.96798 18.0662 2.6611C17.7593 2.35422 17.3431 2.18182 16.9091 2.18182C15.6067 2.18182 14.6647 3.09638 13.9786 4.34391C13.7701 4.723 13.6015 5.10612 13.4683 5.45455ZM13.0694 6.76124C13.0693 6.76158 13.0693 6.76153 13.0694 6.76124Z" fill="#25D366" />
      </svg>

    </GiftStyled>
  )
}

const GiftStyled = styled(IconStyled)``
