import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import CallInputs from './call_inputs'
import { getInitialValues, getValidationSchema } from './utils/util'
import { Heading } from '../ui/components'
import { Col, Row } from '../ui/layout'
import AvatarUpload from '../avatar-upload/avatar-upload'
import { getUser } from '../../utils/storage/user'

const FormFactory = ({ data, onSubmit, children }) => {
  const validationSchema = getValidationSchema(data)
  const initialValues = getInitialValues(data)
  const user = getUser()

  const options = {
    enableReinitialize: true,
    initialValues,
    onSubmit
  }

  if (validationSchema) Object.assign(options, { validationSchema })

  return (
    <Formik {...options}>
      {(actions) => (
        <Form>
          {children || data.map(({ name, component: Component, items }, idx) => (
            <div key={idx}>
              {name && name !== 'Faça seu cadastro' && !Component && <Heading>{name}</Heading>}
              {name && name !== 'Faça seu cadastro' && Component && <Component>{name}</Component>}
              <Row>
                {items.filter(item => item.component).map(({ col, ...item }, index) => (
                  <Col w={col || '100%'} key={`${idx}-${index}`} mb={item.mb || 15} mt={item.mt}>
                    {item.name === 'avatar'
                      ? <AvatarUpload user={user} uploadAvatar={item.uploadAvatar} />
                      : <CallInputs {...item} {...actions} />}
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </Form>
      )}
    </Formik>
  )
}

export const FormFactoryParams = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  items: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ]),
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ]),
      col: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
      ]),
      placeholder: PropTypes.string,
      isRequired: PropTypes.bool,
      tabIndex: PropTypes.number,
      mask: PropTypes.string,
      submittingText: PropTypes.string,
      validations: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.string,
      withDisable: PropTypes.bool
    })
  ])).isRequired
})).isRequired

FormFactory.propTypes = {
  data: FormFactoryParams,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node
}

export default FormFactory
