import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Container } from '../../components/container'
import { Col, Row, Title } from '../index'
import LeftBar from '../../../left-bar/left-bar'
import { Box } from '../../components'
import HeaderListPage from '../../../../components/ui/layout/header-list-page/header-list-page'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ProfileContentBase = ({ children }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const TRANSLATE = {
    'Editar perfil': `${t('profile.edit')}`,
    'Minha assinatura': `${t('profile.signature')}`,
    'Alterar senha': `${t('profile.password')}`
  }

  const titles = {
    '/editar-perfil': TRANSLATE['Editar perfil'],
    '/meu-plano': TRANSLATE['Minha assinatura'],
    '/trocar-senha': TRANSLATE['Alterar senha']
  }

  return (
    <Box>
      <Container>
        <HeaderListPage title={t('profile.about')} />
        <Title
          title={titles[pathname]}
          bar
        />

        <Row>
          <Col xs={12} sm={3} d={['none', 'block', 'block']}>
            <LeftBar current={pathname} />
          </Col>
          <Col xs={12} sm={9}>
            <Card>
              {children}
            </Card>
          </Col>
        </Row>
      </Container>
    </Box>
  )
}

ProfileContentBase.propTypes = {
  children: PropTypes.node.isRequired
}

export default ProfileContentBase

const Card = styled.div`
  padding: 0 10px;
`
