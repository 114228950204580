import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { FormControl, FormLabel } from '../form'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'

const CheckboxOption = ({ name, title, tabIndex, maxChecked = 3, options = [], isRequired, ...el }) => {
  const [check, setCheck] = useState(el.value)

  const handleChange = (value) => {
    if (isSelected(value)) {
      const newValues = check.filter(item => item.value !== value.value)

      setCheck(newValues)
      if (el.setFieldValue) {
        el.setFieldValue(name, newValues)
      }
      return
    }

    if (check.length >= maxChecked) {
      return
    }

    setCheck([...check, value])
    if (el.setFieldValue) {
      el.setFieldValue(name, [...check, value])
    }
  }

  const isSelected = (option) => {
    return check.filter(item => item.value === option.value).length > 0
  }

  return (
    <FormControl style={{ marginBottom: 15 }}>
      {title && (
        <FormLabel htmlFor={name}>
          <BoxStyled bordered={!!el.border}>
            <MinTitle bordered={!!el.border}>{title}</MinTitle>
            <Field name={name}>
              {({ field }) => (
                <Container>
                  {options.map((option, index) => (
                    <Item className="checkbox" key={`${name}-${index}`}>
                      <input
                        type="checkbox"
                        {...field}
                        tabIndex={tabIndex}
                        checked={isSelected(option)}
                        id={`${index}-${name}`}
                        onChange={() => handleChange(option)}
                      />
                      <label htmlFor={`${index}-${name}`} dangerouslySetInnerHTML={{ __html: option.text }} />
                    </Item>
                  ))}
                </Container>
              )}
            </Field>
          </BoxStyled>
        </FormLabel>
      )}
    </FormControl>
  )
}

CheckboxOption.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    text: PropTypes.string.isRequired
  })).isRequired,
  tabIndex: PropTypes.number,
  maxChecked: PropTypes.number,
  isRequired: PropTypes.bool,
  checked: PropTypes.bool
}

export default CheckboxOption

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

`
const Item = styled.div`
  width: fit-content;
  display: flex;
  border-radius: 26px;
  height: 32px;
  overflow: hidden;
  margin: 7px 15px 7px 0;
  cursor: pointer;
`

const BoxStyled = styled.div`
  padding: ${props => props.bordered ? '15px' : 'unset'};
  border-radius: 6px;
  border: ${props => props.bordered ? '1px solid #D8D8D8' : 'none'};
`

const MinTitle = styled.h3`
  color: ${customTheme.colors.purple}!important;
  margin: ${props => props.bordered ? '8px 0 16px' : '0 0 10px'};
  font-size: ${props => props.bordered ? '18px' : 'unset'};
`
