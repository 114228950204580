import { HttpStatusCode } from '../../protocols/http/http-client'
import { UnauthorizedError } from '../../../domain/errors/unauthorized-error'
import { AccessDeniedError } from '../../../domain/errors/access-denied-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'

export class RemoteRatingContent {
  #url
  #httpClient

  constructor (url, httpClient) {
    this.#url = url
    this.#httpClient = httpClient
  }

  async post (body) {
    const httpResponse = await this.#httpClient.request({
      url: this.#url,
      method: 'post',
      data: body
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
