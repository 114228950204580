import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Heart, HeartFull } from '../../../../assets/icons/heart'
import { Star, StarFull } from '../../../../assets/icons/star'
import avatares from '../../../../assets/images/avatares.png'
import AlertBox from '../alert_box'
import { customTheme } from '../../../../styles/theme'
import { Flex } from '../../components'
import { useTranslation } from 'react-i18next'

const EvaluationContent = ({ contentId, favoriteContent, ratingContent, collection, likes = 0 }) => {
  const [favorite, setFavorite] = useState(false)
  const [rating, setRating] = useState([])
  const { t } = useTranslation()

  const handleFavoriteContent = async () => {
    try {
      setFavorite(prev => !prev)
      await favoriteContent.post({
        content: `/v1/contents/${contentId}`
      })
    } catch (error) {
      AlertBox(error.message)
    }
  }

  const handleRatingContent = async (rate) => {
    try {
      const stars = []
      for (let i = 0; i < 5; i++) {
        rate > i ? stars.push(true) : stars.push(false)
      }
      setRating(stars)
      await ratingContent.post({
        content: `/v1/contents/${contentId}`,
        rating: rate
      })
    } catch (error) {
      AlertBox(error.message)
    }
  }

  useEffect(() => {
    const stars = []
    if (collection) {
      setFavorite(collection.like)
      for (let i = 0; i < 5; i++) {
        collection.rating > i ? stars.push(true) : stars.push(false)
      }
      setRating(stars)
    }
  }, [collection])

  return (
    <EvaluationStyled>
      <RatingWrapper>
        {!!rating.length && rating.map((item, i) => (
          <RatingHandle key={i} onClick={() => handleRatingContent(i + 1)}>
            {item
              ? (<StarFull />)
              : (<Star />)}
          </RatingHandle>
        ))}
        <small>{t('watch_content.watch17')}</small>
      </RatingWrapper>

      <Flex>
        {likes >= 3 &&
          <LikeCount>
            <img src={avatares} alt="Avatares" />
            <span>{likes} {likes > 1 ? t('watch_content.watch05') : t('watch_content.watch16')}</span>
          </LikeCount>}
        <FavoriteHandle onClick={() => handleFavoriteContent()}>
          {favorite
            ? (<HeartFull height="21" />)
            : (<Heart height="21" />)}
          <span>{t('watch_content.watch18')}</span>
        </FavoriteHandle>
      </Flex>
    </EvaluationStyled>
  )
}

EvaluationContent.propTypes = {
  contentId: PropTypes.anyTitle,
  favoriteContent: PropTypes.any.isRequired,
  ratingContent: PropTypes.any.isRequired,
  collection: PropTypes.any,
  likes: PropTypes.number
}

export default EvaluationContent

const EvaluationStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 16px;
    color: #2A3C4C;
    display: block;
    margin: 0 5px;
  }
`

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 5px 0 0;

  small {
    color: ${customTheme.colors.purple};
    margin-left: 5px;
  }
`

const RatingHandle = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  
  i {
    cursor: pointer;
    svg {
      fill: ${customTheme.colors.purple};
      height: 20px;
    }
  }
`

const FavoriteHandle = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin-left: 25px;
  display: flex;
  align-items: center;

  span {
    color: ${customTheme.colors.purple};
    font-size: 14px;
  }

  i {
    cursor: pointer;
    color: ${customTheme.colors.purple};

    svg {
      height: 18px;
    }
  }
`

const LikeCount = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 20px;
  }
  
  span {
    margin-right: 10px;
    font-weight: bold;
    font-size: 14px;
    color: ${customTheme.colors.purple}
  }
`
