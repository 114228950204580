import { RemoteLoadContents } from '../../../data/usecases/contents/remote-load-contents'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'

export const makeLoadContents = () => {
  return new RemoteLoadContents('/contents', makeAxiosHttpClient())
}

export const makeLoadContentsByEndpoint = (endpoint) => {
  return new RemoteLoadContents(endpoint, makeAxiosHttpClient())
}
