import { RemoteFavoriteContent } from '../../../data/usecases/evaluations/remote-favorite-content'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'

export const makeFavoriteContent = () => {
  return new RemoteFavoriteContent('/content_user_collection_favorites', makeAxiosHttpClient())
}

export const makeLikeContent = () => {
  return new RemoteFavoriteContent('/content_user_collection_likes', makeAxiosHttpClient())
}
