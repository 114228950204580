import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'

export const CustomHeading = styled.h2`
  position: relative;
  margin-bottom: 16px;
  font-size: 16px;
  
  @media (min-width: ${customTheme.breakpoints[0]}) {
    font-size: 18px;
  }
  color: ${customTheme.colors.purple};
  font-weight: bold;

  /* &:after {
    content: '';
    background: ${customTheme.colors.pink['600']};
    height: 3px;
    width: 43px;
    display: flex;
    margin: 7px 0 0;
  } */
`
