import React, { Suspense, useEffect } from 'react'
import { Route as ReactRoute, Switch, useLocation } from 'react-router-dom'
import { GuardedRoute } from 'react-router-guards'
import PropTypes from 'prop-types'
import Loading from '../loading'
import ReactGA from 'react-ga'
import { getUser } from '../../utils/storage/user'
import ReactPixel from 'react-facebook-pixel'
import { getUrlValues } from '../../utils/get_url_values'
import { getSource } from '../../utils/get-source'
ReactGA.initialize('UA-31441615-2', { debug: false })
ReactPixel.init(794028678094359, {
  autoConfig: true,
  debug: false
})

const Route = ({ data }) => {
  const localization = useLocation()
  const urlMetaInfo = getUrlValues(localization.search.replace('?', ''))

  useEffect(() => {
    const metaGa = { page: window.location.pathname }
    if (getUser() && getUser().user) {
      metaGa.userId = getUser().user.id
      metaGa.dimension1 = getUser().user.id
    }

    ReactGA.set(metaGa)

    ReactGA.pageview(window.location.pathname + window.location.search)
    ReactPixel.pageView()

    Object.keys(urlMetaInfo).forEach((key) => {
      sessionStorage.setItem(key, urlMetaInfo[key])
    })

    if (!JSON.stringify(urlMetaInfo).includes('utm_source')) {
      sessionStorage.setItem('utm_source', getSource(document.referrer))
    }
  }, [localization])

  return (
    <Switch>
      {data.map(({ layout: Layout, data: item }, index) => (
        <ReactRoute key={index} path={item.map(i => i.path)}>
          <Layout>
            <Suspense fallback={<Loading />}>
              <Switch>
                {item.map(({ path, component, ...route }, idx) => (
                  <GuardedRoute
                    key={`${index}-${idx}`}
                    path={path}
                    component={component}
                    {...route}
                    exact={route.exact || true}
                  />
                ))}
              </Switch>
            </Suspense>
          </Layout>
        </ReactRoute>
      ))}
    </Switch>
  )
}

Route.propTypes = {
  data: PropTypes.arrayOf(PropTypes.exact({
    layout: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      path: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired.isRequired,
      exact: PropTypes.bool,
      meta: PropTypes.object
    })).isRequired
  })).isRequired
}

export default Route
