import { HttpStatusCode } from '../../protocols/http/http-client'
import { UnauthorizedError } from '../../../domain/errors/unauthorized-error'
import { AccessDeniedError } from '../../../domain/errors/access-denied-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'

export class RemoteNetwork {
  #url
  #httpClient

  constructor (url, httpClient) {
    this.#url = url
    this.#httpClient = httpClient
  }

  async load (params) {
    const httpResponse = await this.#httpClient.request({
      url: this.#url,
      method: 'get',
      params: {
        ...params
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return {
        list: httpResponse.body['hydra:member'],
        total: httpResponse.body['hydra:totalItems']
      }
      default: throw new UnexpectedError()
    }
  }

  async loadFull (params) {
    const httpResponse = await this.#httpClient.request({
      url: this.#url,
      method: 'get',
      params: {
        ...params
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async delete () {
    const httpResponse = await this.#httpClient.request({
      url: this.#url,
      method: 'delete'
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  async post (body) {
    const httpResponse = await this.#httpClient.request({
      url: this.#url,
      method: 'post',
      data: body
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  async loadItem (id, params = {}) {
    const httpResponse = await this.#httpClient.request({
      url: `/networks/${id}`,
      method: 'get',
      params: {
        ...params
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }
}

export class RemoteChat {
  #url
  #httpClient

  constructor (url, httpClient) {
    this.#url = url
    this.#httpClient = httpClient
  }

  async load (id, params) {
    const httpResponse = await this.#httpClient.request({
      url: `/networks/${id}/messages`,
      method: 'get',
      params: {
        ...params
      }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return {
        list: httpResponse.body['hydra:member'],
        total: httpResponse.body['hydra:totalItems']
      }
      default: throw new UnexpectedError()
    }
  }

  async post (body) {
    const httpResponse = await this.#httpClient.request({
      url: this.#url,
      method: 'post',
      data: body
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
