import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Heart, HeartFull } from '../../../../assets/icons/heart'
import { Col } from '../index'
import noImage from '../../../../assets/images/no-image.jpg'
import { Icon } from '../../../../assets/icons'
import AlertBox from '../alert_box'

const CardContentItem = ({ content, exclusiveContentModal, col = 4, favoriteContent }) => {
  const [favorite, setFavorite] = useState(false)

  const typeIcon = {
    talks: 'ballons',
    todaspro: 'diamond',
    masterclass: 'compass'
  }

  const handleFavoriteContent = async () => {
    try {
      const res = await favoriteContent.post({
        content: `/v1/contents/${content.id}`
      })
      setFavorite(!!res.createdAt)
    } catch (error) {
      AlertBox(error.message)
    }
  }

  const redirectTo = content.hasAccess ? `/${content.folder.slug}/${content.slug}` : null

  useEffect(() => {
    setFavorite(content.currentContentUserCollection.favorite)
  }, [])

  return (
    <Col pos="relative" sm={col}>
      <HandleFavorite onClick={handleFavoriteContent}>
        {!favorite
          ? (<Heart height="21" />)
          : (<HeartFull height="21" />)}
      </HandleFavorite>

      <Card to={redirectTo}>
        <CardHead>
          <img src={content.thumb ? content.thumb.url : noImage} alt={content.title} />

        </CardHead>
        <CardBody>
          <span>
            <Icon name={`${typeIcon[content.folder.slug]}-svg`} size="480" />
            {content.season ? content.season.title : content.folder.title}
          </span>
          <p>{content.title}</p>
        </CardBody>
      </Card>

      {!content.hasAccess && (
        <CardOverlay onClick={exclusiveContentModal}>
          <span>Exclusivo</span>
        </CardOverlay>
      )}
    </Col>
  )
}

CardContentItem.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    thumb: PropTypes.object,
    season: PropTypes.object,
    folder: PropTypes.object,
    slug: PropTypes.string,
    hasAccess: PropTypes.bool,
    currentContentUserCollection: PropTypes.object
  }),
  exclusiveContentModal: PropTypes.func,
  favoriteContent: PropTypes.any.isRequired,
  col: PropTypes.any
}

export default CardContentItem

const Card = styled(Link)`
  background: #fff;
  box-shadow: 0px 4px 8px rgba(189, 189, 189, 0.2);
  text-decoration: none;
  display: block;
  position: relative;
`

const CardHead = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 41.44%;
  overflow: hidden;

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  i {
    position: absolute;
    top: 15px;
    right: 13px;
    color: #fff;
  }
`

const CardBody = styled.div`
  padding: 15px;
  height: 100px;

  span {
    display: flex;
    color: #828282;
    font-size: 14px;
    margin-bottom: 5px;

    i,
    svg {
      margin-right: 5px;
      width: 18px;
      height: 18px;
      fill: #AE7576;
      color: #AE7576;
    }
  }

  p {
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    color: #4F4F4F;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

const CardOverlay = styled.div`
  position: absolute;
  top: -0.5px;
  bottom: 0;
  left: 10px;
  right: 10px;
  background: transparent;
  cursor: pointer;
  background: rgba(255, 255, 255, .4);
  
  span {
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, .5);
    position: absolute;
    top: 15px;
    left: 15px;
    border-radius: 10px;
    background: #AE7576;
    color: #fff;
    padding: 2px 10px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.3px;
  }
`

const HandleFavorite = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 13px;
  right: 13px;
  z-index: 1;
  
  i {
    cursor: pointer;
    color: #fff;
  }
`
