import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container } from '../../components/ui/components'
import HeaderListPage from '../../components/ui/layout/header-list-page/header-list-page'
import { makeNotesItem } from '../../../main/usecases/notes/notes-factory'
import { Col, Row } from '../../components/ui/layout'
import { tagsNotes } from '../../helpers/enums/enums'
import { getFullTimeBySeconds } from '../../helpers/date-helper/date-helper'
import { customTheme } from '../../styles/theme'
import { getImage } from '../../utils/image'
import noImage from '../../assets/images/no-image.jpg'
import { Link, useHistory } from 'react-router-dom'
import { useAlertBox } from '../../contexts'

const NotesInterna = () => {
  const [data, setData] = useState([])
  const [tag, setTag] = useState({})
  const [values, setValues] = useState({
    title: '',
    description: ''
  })
  const history = useHistory()
  const { alertBox } = useAlertBox()
  const index = history.location.state.index

  const handleDelete = async () => {
    try {
      await makeNotesItem(history.location.pathname.split('/')[2]).delete()
      alertBox('Nota excluída')
      history.replace('/notas')
    } catch (error) {
      console.error('NotesInterna.handleDelete', error)
    }
  }

  useEffect(async () => {
    try {
      const res = await makeNotesItem(history.location.pathname.split('/')[2]).load()
      setData(res)
      setValues({
        title: res.title,
        description: res.description
      })
      setTag(tagsNotes.find(i => i.value === res.tag))
    } catch (error) {
      console.error('NotesInterna.useEffect', error)
    }
  }, [])

  if (!data) return null

  return (
    <Container>
      <HeaderListPage title="Notas" setQuery={false} />
      <Row>
        <Col sm={8}>
          <Status>
            <span style={{
              background: tag?.backgroundColor,
              color: tag?.fontColor
            }}
            >{tag.label}
            </span>
            <small>Nota {String(index).padStart(2, '0')}, em {getFullTimeBySeconds(data.timestamp, 'min')} min</small>
          </Status>
          <Title
            value={values?.title}
            onChange={e => setValues({ ...values, title: e.target.value })}
          />
          <Description
            value={values?.description}
            onChange={e => setValues({ ...values, description: e.target.value })}
          />

          <ButtonBox>
            <Button onClick={() => {}}>
              Salvar
            </Button>
            <Button delete onClick={handleDelete}>Excluir</Button>
          </ButtonBox>

        </Col>
        <Col sm={4}>
          <ContentOuTitle>Esta nota pertence ao conteúdo:</ContentOuTitle>
          <ContentCardStyledOut>
            <ContentCardStyled>
              <ContentThumb>
                {data?.content?.author?.avatar
                  ? <img src={getImage(data?.content?.author?.avatar, true)} alt="" />
                  : <img src={noImage} alt="" />}
              </ContentThumb>
              <ContentContainer>
                <ContentStatusBar>
                  <ContentStatus>
                    <span>
                      {data?.content?.currentContentUserCollection?.view?.watched
                        ? 'Você ja assistiu'
                        : 'Assistir'}
                    </span>
                  </ContentStatus>
                </ContentStatusBar>
                <ContentTitle>{data?.content?.title}</ContentTitle>
                <ContentSubTitle>
                  {data?.content?.author?.name}
                  {!!data?.content?.author?.companyJobRole &&
                  ` - ${data?.content?.author?.companyJobRole}`}
                </ContentSubTitle>
              </ContentContainer>
            </ContentCardStyled>
            <LinkStyled to={`/habilidades/${data?.content?.folder?.id}/${data?.content?.slug}`}>Ir para conteúdo</LinkStyled>
          </ContentCardStyledOut>
        </Col>
      </Row>
    </Container>
  )
}

export default NotesInterna

const Title = styled.input`
  min-height: 40px;
  border-radius: 6px;
  background: #fff;
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  font-weight: bold;
  font-size: 18px;
  color: ${customTheme.colors.purple};
  text-align: left;
  width: 100%;
`

const Description = styled.textarea`
  border-radius: 6px;
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  border: none;
  width: 100%;
  min-height: 250px;
`

const Status = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  span {
    border-radius: 26px;
    font-size: 14px;
    height: 32px;
    border: none;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-weight: bold;
    color: #fff;
    margin-right: 15px;
  }
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
`

const Button = styled.button`
  height: 32px;
  background: ${props => props.delete ? 'rgba(235, 87, 87, 0.12)' : 'rgba(203, 207, 209, 0.44)'};
  border-radius: 6px;
  border: none;
  display: flex;
  align-items: center;
  padding: 0 60px;
  font-weight: bold;
  color: ${props => props.delete ? '#EB5757' : customTheme.colors.purple};
  cursor: pointer;
  margin-left: 15px;
`

const ContentCardStyledOut = styled.div`
  padding: 15px;
  border: 1px solid #d8d8d8;
  margin: 15px 0;
  border-radius: 8px;
`

const ContentCardStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ContentThumb = styled.div`
  width: 20%;
  aspect-ratio: 68/100;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ContentContainer = styled.div`
  width: 78%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  aspect-ratio: 295/111;
`
const ContentStatusBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  >span {
    border: 1px ${customTheme.colors.purple} solid;
    color: ${customTheme.colors.purple};
    border-radius: 30px;
    padding: 2px 7px;
    font-size: 12px;
    font-weight: bold;
  }
`

const ContentStatus = styled.div`
  display: flex;
  
  span {
    color: ${customTheme.colors.gray[300]};
    font-size: 12px;
  }
`
const ContentTitle = styled.h2`
  color: ${customTheme.colors.purple};
  line-Height: 1;
  font-size: 22px;
  margin-bottom: 5px;
`

const ContentSubTitle = styled.p`
  color: ${customTheme.colors.purple};
  line-Height: 1;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
`

const ContentOuTitle = styled.h3`
  color: ${customTheme.colors.purple};
  line-Height: 1;
  font-size: 18px;
  margin-bottom: 5px;
`

const LinkStyled = styled(Link)`
  height: 30px;
  margin-top: 15px;
  background: #E3E4E4;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${customTheme.colors.purple};
  text-decoration: none;
  font-size: 14px; 
  font-weight: bold;
`
