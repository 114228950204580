import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'
import { Flex, Box } from '../../components'
import { Icon } from '../../../../assets/icons'
import { useTranslation } from 'react-i18next'

const HeaderListPage = ({ title, setQuery }) => {
  const [searchContent, setSearchContent] = useState('')
  const input = useRef(null)
  const { t } = useTranslation()
  const verifyContent = () => {
    setQuery(searchContent)
  }

  const searchAction = (e) => {
    e.preventDefault()
    verifyContent()
  }

  const enterSub = e => {
    if (e.key === 'Enter') {
      verifyContent()
    }
  }

  return (
    <CustomFlex mb={30} justifyContent="space-between" alignItems="center">
      <Title>
        {title}
      </Title>
      {setQuery &&
        <FormStyled>
          <Input
            id="input"
            ref={input}
            type="text"
            placeholder={t('components.search')}
            onChange={e => setSearchContent(e.target.value)}
            onKeyUp={e => enterSub(e)}
          />
          <CustomIcon name="search" onClick={e => searchAction(e)} />
        </FormStyled>}
    </CustomFlex>
  )
}

HeaderListPage.propTypes = {
  title: PropTypes.string,
  setQuery: PropTypes.function
}

export default HeaderListPage

const CustomFlex = styled(Flex)`
  padding: 20px 0;
  border-bottom: 1px solid ${customTheme.colors.gray[200]};
`

const FormStyled = styled(Box)`
  position: relative;
  background: #f6f4f3;
  overflow: hidden;
  transition: ease all 300ms;
  width: 350px;
  max-width: 100%;
  box-shadow: 5px 5px 5px -5px #939393;
`

const Title = styled.h2`
  font-size: ${customTheme.fontSizes.lg};
  color: ${customTheme.colors.purple};
  margin-bottom: 0;
  line-height: 1;
`

const Input = styled.input`
  border-radius: 4px;
  background: ${customTheme.colors.white};
  height: 40px;
  filter: drop-shadow(0px 4px 8px rgba(189, 189, 189, 0.2));
  border: 0;
  width: 100%;
  padding: 0 40px 0 20px;
`

const CustomIcon = styled(Icon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  width: 20px;
  cursor: pointer;
  color: ${customTheme.colors.purple};  
`
