/* eslint-disable no-console */
import React from 'react'
import styled from 'styled-components'
import { useFormik, FormikProvider, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Question from '../../stepTwo/components/question'
import { getUser } from '../../../../utils/storage/user'
import { CreateQuestionsAnswer, GetQuestionsAnswer, UpdateQuestionsAnswer } from '../../../../pages/mentor/controller'

const Questions = ['question_01', 'question_02', 'question_03', 'question_04', 'question_05', 'question_06']

const validationSchema = Yup.object().shape({
  question_01: Yup.string().required('Campo obrigatório*'),
  question_02: Yup.string().required('Campo obrigatório*'),
  question_03: Yup.string().required('Campo obrigatório*'),
  question_04: Yup.string().required('Campo obrigatório*'),
  question_05: Yup.string().required('Campo obrigatório*'),
  question_06: Yup.string().required('Campo obrigatório*')
})
const SessionFour = () => {
  const user = getUser()
  const [userInfo, setUserInfo] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const fase = 1

  const formik = useFormik({
    initialValues: {
      question_01: '',
      question_02: '',
      question_03: '',
      question_04: '',
      question_05: '',
      question_06: ''
    },
    validationSchema,
    onSubmit: values => handleSubmit(values)
  })

  React.useEffect(() => {
    if (userInfo !== null) {
      formik.setValues({
        question_01: userInfo?.data?.item?.find(item => item.question === 'question_01')?.response || '',
        question_02: userInfo?.data?.item?.find(item => item.question === 'question_02')?.response || '',
        question_03: userInfo?.data?.item?.find(item => item.question === 'question_03')?.response || '',
        question_04: userInfo?.data?.item?.find(item => item.question === 'question_04')?.response || '',
        question_05: userInfo?.data?.item?.find(item => item.question === 'question_05')?.response || '',
        question_06: userInfo?.data?.item?.find(item => item.question === 'question_06')?.response || ''
      })
    }
  }, [userInfo])

  const GetMyAnswer = React.useCallback(async () => {
    try {
      setLoading(true)
      const response = await GetQuestionsAnswer(user?.user?.email, fase)
      setUserInfo(JSON.parse(response))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  React.useEffect(() => {
    GetMyAnswer()
  }, [GetMyAnswer])

  const handleSubmit = async values => {
    const details = Object.values(values)
    const questionsAnswer = details?.map((item, index) => {
      return {
        id: Questions[index],
        question: Questions[index],
        response: item
      }
    })
    const create = {
      email: user?.user?.email,
      item: questionsAnswer,
      fase: 1,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }

    const update = {
      _id: userInfo?.data?._id,
      email: user?.user?.email,
      item: questionsAnswer,
      fase: 1,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()

    }

    if (userInfo?.data?._id) {
      await UpdateQuestionsAnswer(update)
    } else {
      await CreateQuestionsAnswer(create)
    }

    await GetMyAnswer()
  }

  return (
    <Container>
      <HeaderTitle>Objetivos:</HeaderTitle>
      <NormalText>Aqui direcionamos sua atenção para trabalhar os resultados da pesquisa perfilática, que é a combinação do seu assessment com a percepção dos seus stakeholders a respeito de você.</NormalText>
      <NormalTextGray>O objetivo é ajudá-la a alcançar seu pleno potencial, reforçando e desenvolvendo as habilidades necessárias para se desenvolver com excelência.</NormalTextGray>
      <NormalTextGray>Com base nos seus objetivos individuais, concentre-se nas áreas que você deseja aprimorar.
        É importante voltar a esta área para lembrar por que começou.
      </NormalTextGray>

      {loading
        ? (
          <NormalTextGrayBold>Carregando...</NormalTextGrayBold>
          )
        : (
          <FormikProvider value={formik}>
            <SubContainer onSubmit={formik.handleSubmit}>
              <Question placeHolder="Seu objetivo com o programa:" name="question_01" />
              <ErrorMessage name="question_01" component={ErrorTitle} className="error" />
              <Question placeHolder="Potências a serem trabalhadas" name="question_02" />
              <ErrorMessage name="question_02" component={ErrorTitle} className="error" />
              <Question placeHolder="Managing Gaps" name="question_03" />
              <ErrorMessage name="question_03" component={ErrorTitle} className="error" />
              <Question placeHolder="Onde você quer chegar no final do programa" name="question_04" />
              <ErrorMessage name="question_04" component={ErrorTitle} className="error" />
              <Question placeHolder="Onde você quer chegar em 3 meses após o programa" name="question_05" />
              <ErrorMessage name="question_05" component={ErrorTitle} className="error" />
              <Question placeHolder="Como vamos mensurar este progresso" name="question_06" />
              <ErrorMessage name="question_06" component={ErrorTitle} className="error" />
              <StepBtn type="submit">
                {userInfo?.data?._id ? 'Atualizar' : 'Cadastrar'}
              </StepBtn>
            </SubContainer>
          </FormikProvider>
          )}

    </Container>
  )
}

export default SessionFour

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:13px;
  margin-top: 30px;
`

const HeaderTitle = styled.h4`
  font-size: 25.6px;
  font-weight: 400;
  color: #121214;
  line-height: 25.6px;
  font-family: "Playfair Display","Roboto","Open Sans",sans-serif;
`

const NormalText = styled.p`
  font-size: 16px;
  font-weight:500;
  color: #e14b2b;
  line-height: 16px;
  font-family: "Roboto","Open Sans",sans-serif;`

const NormalTextGray = styled.span`
  font-size: 16px;
  font-weight:400;
  color: #7A7A7A;
  line-height: 24px;
  font-family: "Roboto","Open Sans",sans-serif;
  `

const NormalTextGrayBold = styled.span`
font-size: 16px;
font-weight:500;
color: #7A7A7A;
line-height: 24px;
text-align: center;
font-family: "Roboto","Open Sans",sans-serif;
`

const SubContainer = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:13px;
`
const StepBtn = styled.button`
display: flex;
border: none;
width: 170px;
min-width: 90px;
height: 50px;
align-items: center;
justify-content: center;
border-radius: 7px;
cursor: pointer;
background-color: #e14b2b;
color:white;
font-size: 1.0rem;
margin-top: 15px;
`

const ErrorTitle = styled.div`
font-size: 15px;
color: #121214;
margin-left: 15px;
`
