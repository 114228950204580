import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
const SideStepArray = [{
  id: '1',
  title: '01. Pesquisa Perfilática',
  active: true,
  disabled: false
},

{
  id: '2',
  title: '02. Direcionamento',
  active: false,
  disabled: false
},
{
  id: '3',
  title: '03. Desenvolvimento',
  active: false,
  disabled: false
},
{
  id: '4',
  title: '04. Fechamento',
  active: false,
  disabled: false
},
{
  id: '5',
  title: '05. Evolução',
  active: false,
  disabled: false
},
{
  id: '6',
  title: 'Material de apoio',
  active: false,
  disabled: false
}

]

const SideStep = ({ setSession }) => {
  const [items, setItems] = React.useState(SideStepArray)

  const handleItemClick = (id) => {
    const updatedItems = items.map(item => {
      if (item.disabled) {
        return item
      }
      return {
        ...item,
        active: item.id === id
      }
    })

    const findSession = updatedItems.find(item => item.id === id)
    setSession(findSession)
    setItems(updatedItems)
  }

  return (
    <Container>
      {items.map((item) => {
        return (
          <StepBtn
            key={item.id} onClick={() => {
              handleItemClick(item.id)
            }} active={item.active}
            disabled={item.disabled}
          >
            <Title active={item.active}>{item.title}</Title>
          </StepBtn>
        )
      })}
    </Container>
  )
}

SideStep.propTypes = {
  setSession: PropTypes.func
}

export default SideStep

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:8px;
`

const StepBtn = styled.button`
display: flex;
border-width: 0.5px;
border-color:  ${({ active }) => active ? '#e14b2b' : '#121214'};
opacity: ${({ disabled }) => disabled ? 0.5 : 1};
width: 320px;
min-width: 320px;
height: 55px;
align-items: center;
cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
background-color: ${({ active }) => active ? '#e14b2b' : 'white'};
:hover {
  background-color: ${({ active }) => active ? '#e14b2b' : '#f4f4f4'};
}`

const Title = styled.p`
  color:${({ active }) => active ? 'white' : '#121214'};
  font-size: 16px;
  font-weight: ${({ active }) => active ? 'bold' : '600'};
  margin-top: 10px;
  margin-left: 25px;

`
