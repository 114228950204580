/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LogoV2 from '../../assets/images/logoV2.png'
// import LogoMobile from '../../assets/images/logo.png'
// import LogoDesktop from '../../assets/images/logo_full.png'
import { settings } from '../../../main/configs/settings'
import { Col, Row } from '../ui/layout'
import { Container, Flex } from '../ui/components'
import { customTheme } from '../../styles/theme'
import PropTypes from 'prop-types'
// import { StringHelper } from '../../helpers/string-helper/string-helper'
import SearchWidget from '../search/search-widget/search-widget'
import UserWidget from '../user-widget/user-widget'
// import SearchFormWidget from '../search/search-form-widget/search-form-widget'
// import MenuMobile from '../menu-mobile/menu-mobile'
import NavBar from '../ui/layout/navbar/navbar'
import { Icon } from '../../assets/icons'
import { makeNetworks } from '../../../main/usecases/network/network-factory'
import { makePosts } from '../../../main/usecases/todasjuntas/todasjuntas-factory'
import { getUser } from '../../utils/storage/user'

import { handleGetUserCompany } from '../../pages/assessment/controller/opt-in'
import MenuMobile02 from '../menu-mobile/menu-mobile-02'

import { useTranslation } from 'react-i18next'
import SelectTranslateI18n from '../i18n/i18n'

const HeaderLogged = ({ menu, user }) => {
  const fullUser = getUser()
  const userId = fullUser?.user?.id
  const [unreadValues, setUnreadValues] = useState(0)

  const [dropdown, setDropdown] = useState(false)

  const toggleDropDown = () => {
    setDropdown(prev => !prev)
  }

  const [headerPotencia, setHeaderPotencia] = useState(false)

  const { t } = useTranslation()

  const verifyInviter = (content) => {
    return fullUser.user['@id'] === content?.inviter['@id']
  }

  useEffect(async () => {
    try {
      const networks = await makeNetworks().load({ perPage: 1000 })
      let count = 0

      networks?.list?.forEach((content) => {
        if (
          !(verifyInviter(content) && content?.status === 'pending') &&
          content.unread
        ) {
          count++
        }
      })

      const posts = await makePosts().load()
      posts?.list?.forEach((post) => {
        if (post.unread) {
          // console.log(post)
          count++
        }
      })

      setUnreadValues(count)
    } catch (error) {
      console.error('NetworkHeader.useEffect', error)
    }

    // função responsavel por verficar o id da empresa acessociado ao cliente e retornar o header
    // id Sankhya, Hotmart, sanfoni
    try {
      const res = await handleGetUserCompany(userId)
      const companyId = res[1][0]?.id
      const allowedCompanyIds = [22622, 28357, 27404, 29695, 26399, 27575, 3621, 29708, 4025, 29597, 3571, 30370, 31310, 31689, 31682, 32068]

      if (allowedCompanyIds.includes(companyId)) {
        setHeaderPotencia(true)
      }
    } catch (error) {
      console.error('Erro', error)
    }
  }, [headerPotencia, userId])

  return (
    <>
      <CustomFlex>
        <Container>
          <Row alignItems="center">
            <Col w="20%" mb={0}>
              <WelcomeBox>
                <Link to={settings.dashboardRoute}>
                  <img src={LogoV2} alt={settings.siteName} maxW="31px" />
                </Link>
                {user && (
                  <h2 style={{ fontSize: '15px' }}>
                    {t('header_logged.welcome')}, {user.nickname || user.name}
                  </h2>
                )}
              </WelcomeBox>
            </Col>
            <Col w="65%" mb={0} pr={35} d={['none', 'flex', 'flex']}>
              <NavBar menu={menu} />
              {headerPotencia &&
                <Potencias>
                  <Icon name="potencia" />
                  <a href="/resultado-perfil-inicial">{t('header_logged.potencias')}</a>
                </Potencias>}
              <SearchWidget />
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <CustomFlags>
                  <SelectTranslateI18n />
                </CustomFlags>
              </div>
            </Col>
            {user && (
              <>
                <ProfileBox
                  justifyContent="flex-end"
                  w="15%"
                  mb={0}
                  d={['none', 'flex', 'flex']}
                >
                  <Flex justifyContent="flex-end" alignItems="center">

                    {/* //FIX ME */}
                    <ChatButton to="/todas-juntas">
                      <Icon name="chat-ballon" color={customTheme.colors.purple} />

                      {/* <ChatButton to="/networks">
                      <Icon
                        name="chat-ballon"
                        color={customTheme.colors.purple}
                      /> */}

                      {!!unreadValues && <span>{unreadValues}</span>}
                    </ChatButton>
                    <UserWidget user={user} />
                    {/* <Nickname name={StringHelper.start(user.nickname || user.name).first().truncate(15).build()} /> */}
                  </Flex>

                </ProfileBox>
                {/* <Col w="20%" ml="auto" mb={0} d={['flex', 'none', 'none']}>
                  <UserWidget user={user} />
                </Col> */}
                <Col mt={20} w="75%" mb={10} d={['flex', 'none', 'none']} style={{ justifyContent: 'flex-end' }}>
                  {/* <SearchFormWidget /> */}
                  {/* <MenuMobile menu={menu} /> */}
                  <IconCircle onClick={toggleDropDown}>
                    <Icon name="menu" />
                  </IconCircle>
                </Col>
                {dropdown &&
                  <MenuMobile02
                    menu={menu}
                    user={user}
                    closeModal={toggleDropDown}
                  />}

              </>
            )}
          </Row>
        </Container>
      </CustomFlex>
      <FakeHeader />
    </>
  )
}

HeaderLogged.propTypes = {
  menu: PropTypes.array,
  user: PropTypes.object
}

export default HeaderLogged

const IconCircle = styled.div`
  width: 40px;
  height: 40px;
  background: #ffffff;
  margin-left: 10px;
  filter: drop-shadow(0px 4px 8px rgba(189, 189, 189, 0.2));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:  pointer;
`
const FakeHeader = styled.div`
  height: 65px;
`

const CustomFlex = styled(Flex)`
  position: fixed;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 4;

  @media (min-width: ${customTheme.breakpoints[1]}) {
    box-shadow: 0px 12px 16px rgb(0 0 0 / 15%);
  }
`

const ChatButton = styled(Link)`
  cursor: pointer;
  line-height: 1;
  margin-bottom: -5px;
  position: relative;

  span {
    background: #eb5757;
    font-size: 12px;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    text-decoration: none;
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
  }
`

const Potencias = styled.div`
    cursor: pointer;
    display: flex;
    gap: 4px;
    width: 35%;
    padding: 5px 6px;

    &:hover {
    color: ${customTheme.colors.orange};
     svg {
       fill: ${customTheme.colors.orange};
     }
  }

  a {
    font-size: 14px;
    color: #2A3C4C;
    text-decoration: none;
  }
`

// const ImageMobile = styled(Image)`
//   @media (min-width: ${customTheme.breakpoints[1]}) {
//     display: none;
//   }
// `

// const ImageDesk = styled(Image)`
//   display: none;
//   @media (min-width: ${customTheme.breakpoints[1]}) {
//     display: block;
//   }
// `

const WelcomeBox = styled(Flex)`
  align-items: center;

  img {
    margin-right: 10px;
    height: 35px;
  }

  h2 {
    font-size: 16px;
    color: ${customTheme.colors.orange};
  }
  @media(max-width: 971px) {
    width: 21vh;
  }
`

const ProfileBox = styled(Col)`
  border-left: 1px ${customTheme.colors.gray['200']} solid;
  padding: 20px 0;
`
const CustomFlags = styled.div`
  margin-right: 20px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
