const ComponentsList = {
  Input: require('../ui/components/formik/input').default,
  Textarea: require('../ui/components/formik/textarea').default,
  InputMasked: require('../ui/components/formik/input_masked').default,
  InputPassword: require('../ui/components/formik/input_password').default,
  InputPhoneWithCode: require('../ui/components/formik/input_phone_with_code').default,
  InputCreditCard: require('../ui/components/formik/input-credit-card').default,
  Select: require('../ui/components/formik/select').default,
  SelectOption: require('../ui/components/formik/select-option').default,
  Button: require('../ui/components/formik/button').default,
  Checkbox: require('../ui/components/formik/checkbox').default,
  CheckboxOption: require('../ui/components/formik/checkboxOption').default,
  checkboxOptionButton: require('../ui/components/formik/checkboxOptionButton').default,
  Recaptcha: require('../ui/components/formik/recatpcha').default,
  Space: require('../ui/components/formik/space').default,
  DatePiker: require('../ui/components/formik/date_piker').default
}

export default ComponentsList
