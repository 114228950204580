import React from 'react'
import PropTypes from 'prop-types'
import { CardContentItem, CardBookItem, CardJobItem } from '../index'

const CardType = ({ type, content, exclusiveContentModal, col, favoriteContent }) => {
  if (type === 'livros') {
    return (
      <CardBookItem
        content={content}
        exclusiveContentModal={exclusiveContentModal}
        col={col}
        favoriteContent={favoriteContent}
      />
    )
  }
  if (type === 'jobs') {
    return <CardJobItem content={content} />
  }
  return (
    <CardContentItem
      content={content}
      exclusiveContentModal={exclusiveContentModal}
      col={col}
      favoriteContent={favoriteContent}
    />
  )
}

CardType.propTypes = {
  type: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  exclusiveContentModal: PropTypes.func,
  favoriteContent: PropTypes.any.isRequired,
  col: PropTypes.any
}

export default CardType
