import { api } from '../../infra/http/remote'

export const getPage = async (slug) => {
  try {
    const response = await api.get(`/pages/slug/${slug}`)
    return response.data
  } catch (e) {
    throw new Error('Ocorreu um erro ao carregar os dados da página')
  }
}
