
import imgModal1 from './assets/modal-1.png'
import imgModal2 from './assets/modal-2.png'
import imgModal3 from './assets/modal-3.png'
import imgModal4 from './assets/modal-4.png'
import imgModal5 from './assets/modal-5.png'

const modalJson = [

  {
    img: imgModal1,
    title: 'Instruções do Evento',
    description: 'Participar do evento ao vivo na nossa plataforma é fácil! Siga o passo a passo abaixo para garantir que você acesse a transmissão no horário certo, interaja com os palestrantes e aproveite ao máximo a experiência. Se precisar de ajuda, estamos aqui para apoiar.',
    pointDescription: [],
    btnBack: false
  },
  {
    img: imgModal2,
    title: 'Acesso ao Evento',
    description: '',
    pointDescription: [
      'Caso a tela do evento não carregue após o horário de início, atualize a página.',
      'Verifique sua conexão com a internet e tente acessar a partir de uma rede estável.'
    ],
    btnBack: true
  },
  {
    img: imgModal4,
    title: 'Ative o Áudio',
    description: '',
    pointDescription: [
      'Seu microfone permanecerá desligado durante a transmissão. Para interagir, use o chat clicando no ícone de “chat” na parte inferior da tela.',
      'Para interagir use o chat clicando no icone de chat na parte inferior da tela'
    ],
    btnBack: true
  },
  {
    img: imgModal3,
    title: 'Interação Durante o Evento',
    description: '',
    pointDescription: [
      'Seu microfone permanecerá desligado durante a transmissão. Para interagir, use o chat clicando no ícone de “chat” na parte inferior da tela.',
      'Para interagir use o chat clicando no icone de chat na parte inferior da tela'
    ],
    btnBack: true
  },
  {
    img: imgModal5,
    title: 'Uso da Câmera',
    description: '',
    pointDescription: [
      'Se quiser ativar sua câmera durante interações, o navegador solicitará permissão. Clique em Permitir quando for requisitado. Verifique se sua câmera está funcionando corretamente nas configurações',
      'Verifique se sua câmera está funcionando corretamente nas configurações'
    ],
    btnBack: true
  }
]

export default modalJson
