import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Container } from '../../components/ui/components'
import NetworkHeader from '../../components/ui/layout/network-header/network-header'
import { Col, Row } from '../../components/ui/layout'
import { customTheme } from '../../styles/theme'
import { tags } from '../../helpers/string-helper/string-helper'
import { makePosts } from '../../../main/usecases/todasjuntas/todasjuntas-factory'
import { useTranslation } from 'react-i18next'

const TodasJuntasNewPost = () => {
  const [tag, setTag] = useState('')
  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const [anonymous, setAnonymous] = useState(false)
  const { t } = useTranslation()

  const TRANSLATE_TITLE = {
    Trabalho: t('todas.title4'),
    Família: t('todas.title5'),
    Saúde: t('todas.title6'),
    Lazer: t('todas.title7'),
    Assédio: t('todas.title8')
  }

  const createComment = async () => {
    try {
      if (!!tag && !!title && !!text) {
        await makePosts().post({
          tag: tag,
          title: title,
          text: text,
          anonymous: anonymous
        })
        alert('enviou')
      }
    } catch (error) {
      console.error('ERROR', error)
    }
  }

  return (
    <Container>
      <NetworkHeader />
      <Box pb={50}>
        <Row>
          <Col sm={8}>
            <Form>
              <h2>{t('todas.title16')}</h2>
              <Temas>
                {Object.keys(tags).map(key => (
                  <Tag active={key === tag} key={key} onClick={() => setTag(key)}>
                    {TRANSLATE_TITLE[tags[key]]}
                  </Tag>
                ))}
              </Temas>

              <h3>{t('todas.title9')}</h3>
              <Title onChange={e => setTitle(e.target.value)} />

              <h3>{t('todas.title10')}</h3>
              <Description onChange={e => setText(e.target.value)} />

              <Check>
                <span>{t('todas.title13')}</span>
                <input type="radio" checked={anonymous} onClick={() => setAnonymous(true)} />{t('todas.title14')}
                <input type="radio" checked={!anonymous} onClick={() => setAnonymous(false)} />{t('todas.title15')}
              </Check>

              <ButtonBox>
                <Button onClick={createComment}>{t('todas.title11')}</Button>
                <Button type="delete">{t('todas.title12')}</Button>
              </ButtonBox>
            </Form>
          </Col>
        </Row>
      </Box>
    </Container>
  )
}

export default TodasJuntasNewPost

const Form = styled.div`
  h2 {
    font-size: 34px;
    font-weight: bold;
    color: ${customTheme.colors.purple};
    margin-bottom: 25px;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
    color: ${customTheme.colors.purple};
    margin-bottom: 5px;
  }
`

const Temas = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
`

const Tag = styled.button`
  cursor: pointer;
  border: none;
  background: ${props => props.active ? customTheme.colors.purple : '#e5ebf1'};
  color: ${props => props.active ? '#fff' : customTheme.colors.purple};
  border-radius: 30px;
  padding: 10px 35px;
  font-weight: bold;
`

const Title = styled.textarea`
  width: 100%;
  height: 100px;
  border: none;
  border-radius: 10px;
  padding: 8px 15px;
  margin-bottom: 10px;
`

const Description = styled.textarea`
  width: 100%;
  height: 200px;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 8px 15px;
`

const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: bold;
  color: ${customTheme.colors.purple};
  padding: 30px 0 40px;
  border-bottom: 1px solid ${customTheme.colors.gray[300]};

  input {
    margin: 0 5px 0 15px;
  }
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 40px 0 0;
`

const Button = styled.button`
  padding: 10px 40px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  background: ${props => props.type === 'delete' ? '#F5E1E0' : customTheme.colors.purple};
  color: ${props => props.type === 'delete' ? '#EB5757' : '#fff'};
  margin-left: 25px;
  cursor: pointer;
`
