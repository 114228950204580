/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Flex } from '../../../components/ui/components'
import { customTheme } from '../../../styles/theme'
import BgImage from '../../../assets/images/bg-conquest.png'

// import View1 from '../../../assets/images/conquest-tags/view-1.png'
// import View10 from '../../../assets/images/conquest-tags/view-10.png'
// import View25 from '../../../assets/images/conquest-tags/view-25.png'
// import View50 from '../../../assets/images/conquest-tags/view-50.png'
// import View100 from '../../../assets/images/conquest-tags/view-100.png'

// import Network1 from '../../../assets/images/conquest-tags/network-1.png'
// import Network10 from '../../../assets/images/conquest-tags/network-10.png'
// import Network25 from '../../../assets/images/conquest-tags/network-25.png'
// import Network50 from '../../../assets/images/conquest-tags/network-50.png'
// import Network100 from '../../../assets/images/conquest-tags/network-100.png'
import { getUser } from '../../../utils/storage/user'
import { Icon } from '../../../assets/icons'
import Avatar from '../../../components/ui/layout/avatar/avatar'
import ConquestItem from './conquest-item'
import { Col } from '../../../components/ui/layout'
import { makeChallenges } from '../../../../main/usecases/conquests/conquests-factory'
import { useTranslation } from 'react-i18next'

const Conquests = ({ loadContent }) => {
  const { user } = getUser()
  // const [data, setData] = useState([])
  const [newData, setNewData] = useState([])
  const { t } = useTranslation()

  const TRANSLATE = {
    'Assistir Conteúdos': `${t('conquest_main.obj')}`,
    'Fazer Network': `${t('conquest_main.obj_02')}`
  }

  // const viewsTags = [View1, View10, View25, View50, View100]
  // const networksTags = [Network1, Network10, Network25, Network50, Network100]

  // const getTag = val => {
  //   if (val < 10) {
  //     return 0
  //   }
  //   if (val >= 10 && val < 25) {
  //     return 1
  //   }
  //   if (val >= 25 && val < 50) {
  //     return 2
  //   }
  //   if (val >= 40 && val < 10) {
  //     return 3
  //   }
  //   if (val >= 100) {
  //     return 4
  //   }
  // }

  // const hastConquest = (arr, val) => {
  //   let isValid = false
  //   arr.forEach(item => {
  //     if (val >= item) {
  //       isValid = true
  //     }
  //   })
  //   return isValid
  // }

  const getAtualAward = (list, value) => {
    const award = list.filter(i => i.quantity <= value).sort(function compare (a, b) {
      if (a.quantity < b.quantity) return 1
      return -1
    })[0]

    return award
  }

  useEffect(async () => {
    try {
      // const data = await makeConquest().load()
      const challenges = await makeChallenges().load()

      setNewData([
        {
          ...challenges['hydra:member'].find(item => item.slug === 'assistir-conteudos'),
          atualAward: getAtualAward(challenges['hydra:member'].find(item => item.slug === 'assistir-conteudos')?.awards, challenges['hydra:member'].find(item => item.slug === 'assistir-conteudos').currentCustomerProgress)
        },
        {
          ...challenges['hydra:member'].find(item => item.slug === 'fazer-network'),
          atualAward: getAtualAward(challenges['hydra:member'].find(item => item.slug === 'fazer-network')?.awards, challenges['hydra:member'].find(item => item.slug === 'fazer-network').currentCustomerProgress)
        }
      ])

      // setData([
      //   {
      //     title: 'Assistir Conteúdos',
      //     slug: 'assistir-conteudos',
      //     value: data.viewedContents,
      //     rewardList: [1, 10, 25, 50, 100]
      //   },
      //   {
      //     title: 'Fazer Network',
      //     slug: 'fazer-network',
      //     value: data.acceptedNetworks,
      //     rewardList: [1, 10, 25, 50, 100]
      //   }
      // ])
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <>
      <Banner>
        <BannerImage src={BgImage} alt="" />
        <BannerContainer>
          <AvatarContainer>
            {user.avatar.indexOf('/static/media/') !== -1
              ? <Icon name="user" width={180} height={180} />
              : <Avatar src={user.avatar} alt={user.name} />}
            <AvatarContent>
              <p>{t('conquest_main.parabens')}</p>
              <h2>{user.nickname || user.name}</h2>
              <p>{t('conquest_main.parabens_desc')}</p>
            </AvatarContent>
          </AvatarContainer>
          <BannerReward>
            <h2>{t('conquest_main.desc')}</h2>
            <h2>{t('conquest_main.desc_02')}</h2>
            {/* <Flex justifyContent="space-evenly" w="100%" mt={25}>
              <RewardItem className={!!data.length && !hastConquest(data[0].rewardList, data[0].value) && 'disable'}>
                {!!data.length && <img src={data.length && networksTags[getTag(data[0].value)]} />}
                <h3>{!!data.length && data[0].value}</h3>
                <span>Assistir Conteúdos</span>
              </RewardItem>

              <RewardItem className={!!data.length && !hastConquest(data[1].rewardList, data[1].value) && 'disable'}>
                {!!data.length && <img src={data.length && viewsTags[getTag(data[1].value)]} />}
                <h3>{!!data.length && data[1].value}</h3>
                <span>Fazer Network</span>
              </RewardItem>
            </Flex> */}

            <Flex justifyContent="space-evenly" w="100%" mt={25}>
              {!!newData.length &&
                <>
                  <RewardItem className={newData[0].currentCustomerProgress < newData[0].atualAward?.quantity && 'disable'}>
                    <img src={newData.length && newData[0].atualAward?.badge?.url} />
                    <h3>{!!newData.length && newData[0].currentCustomerProgress}</h3>
                    <span>{TRANSLATE[newData[0].title]}</span>
                  </RewardItem>

                  <RewardItem className={newData[1].currentCustomerProgress < newData[1].atualAward?.quantity && 'disable'}>
                    <img src={newData.length && newData[1].atualAward?.badge?.url} />
                    <h3>{!!newData.length && newData[1].currentCustomerProgress}</h3>
                    <span>{TRANSLATE[newData[1].title]}</span>
                  </RewardItem>
                </>}
            </Flex>
          </BannerReward>
        </BannerContainer>
      </Banner>
      <Container>
        <Title>{t('conquest_main.desc_03')}</Title>

        <Flex justifyContent="center">
          {!!newData.length && newData.map(item => (
            <Col sm={4} key={item.title}>
              <ConquestItem content={item} />
            </Col>
          ))}
        </Flex>
      </Container>
    </>
  )
}

export default Conquests

Conquests.propTypes = {
  loadContent: PropTypes.any.isRequired
}

const Banner = styled.div`
  width: 100%;
  aspect-ratio: 1440/584;
  position: relative;
`

const BannerImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`

const BannerContainer = styled(Container)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 40%;

  img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 50%;
  }
`

const AvatarContent = styled.div`
  color: #fff;
  margin-left: 15px;

  h2 {
    line-height: 1;
    font-size: 40px;
  }

  p {
    font-size: 20px;
    margin: 0;
  }
`

const BannerReward = styled.div`
  background: #fff;
  border-radius: 15px;
  padding: 30px 20px;
  width: calc(50% - 20px);

  h2 {
    color: ${customTheme.colors.purple};
    margin: 0;
    font-size: 30px;
    text-align: center;
    line-height: 1;
  }
`

const RewardItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;

  h3 {
    color: ${customTheme.colors.purple};
    text-align: center;
    font-size: 22px;
  }

  span {
    color: ${customTheme.colors.purple};
    text-align: center;
    font-size: 14px;
    width: 70%;
    display: flex;
    line-height: 1;
  }

  img {
    height: 70px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  &.disable {
    img {
      filter: contrast(.5) grayscale(1);
    }

    h3 {
      color: ${customTheme.colors.gray[200]};
    }

    span {
      color: ${customTheme.colors.gray[200]};
    }
  }
`

const Title = styled.h1`
  color:  ${customTheme.colors.purple};
  margin: 50px auto 60px;
  text-align: center;
  width: 50%;
  font-size: 44px;
  line-height: 1;
`
