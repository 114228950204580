import PropTypes from 'prop-types'
import React from 'react'

const Flag = ({ name, isSelected, style, ...props }) => (
  <div
    alt="flag"
    style={{
      ...style,
      padding: '10px',
      paddingBottom: '10px',
      width: '100%',
      cursor: 'pointer',
      fontSize: '14px',
      resizeMode: 'contain',
      borderBottom: style.borderBottom
    }}
    {...props}
  >
    {name}
  </div>
)

Flag.propTypes = {
  isSelected: PropTypes.bool,
  name: PropTypes.string.isRequired,
  style: PropTypes.object
}

export default Flag
