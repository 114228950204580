import React from 'react'
import styled from 'styled-components'
// import { Card } from '../ui/components'
import { Link, useLocation } from 'react-router-dom'
import { Icon } from '../../assets/icons'
import { customTheme } from '../../styles/theme'
// import { getUser } from '../../utils/storage/user'
import { useTranslation } from 'react-i18next'

const LeftBar = () => {
  const { pathname } = useLocation()

  const { t } = useTranslation()

  const TRANSLATE = {
    'Editar perfil': `${t('profile.edit')}`,
    'Meu plano': `${t('profile.signature')}`,
    'Alterar senha': `${t('profile.password')}`
  }
  // const user = getUser()
  // let data = ([])

  // if (user.user.hideSubscriptions) {
  //   data = ([
  //     { title: 'Editar perfil', path: '/editar-perfil', icon: 'pencil' },
  //     { title: 'Favoritos', path: '/favoritos', icon: 'heart-svg' },
  //     { title: 'Meu currículo', path: '/meu-curriculo', icon: 'notepad' },
  //     { title: 'Alterar senha', path: '/trocar-senha', icon: 'unlock' },
  //     { title: 'Sair', path: '/sair', icon: 'logout' }
  //   ])
  // } else {
  //   data = ([
  //     { title: 'Editar perfil', path: '/editar-perfil', icon: 'pencil' },
  //     // { title: 'Favoritos', path: '/favoritos', icon: 'heart-svg' },
  //     // { title: 'Meu currículo', path: '/meu-curriculo', icon: 'notepad' },
  //     { title: 'Meu plano', path: '/meu-plano', icon: 'card' },
  //     // { title: 'Pagamento', path: '/pagamento', icon: 'payment' },
  //     { title: 'Alterar senha', path: '/trocar-senha', icon: 'unlock' }
  //     // { title: 'Sair', path: '/sair', icon: 'logout' }
  //   ])
  // }

  const data = ([
    { title: TRANSLATE['Editar perfil'], path: '/editar-perfil', icon: 'pencil' },
    { title: TRANSLATE['Meu plano'], path: '/meu-plano', icon: 'card' },
    { title: TRANSLATE['Alterar senha'], path: '/trocar-senha', icon: 'unlock' }
  ])

  return (
    <LeftBarStyled>
      {data.map((item, index) => (
        <CustomLink
          key={index}
          to={item.path}
          className={item.path === pathname || (item.path === '/pagamento' && pathname === '/atualizar-cartao') ? 'active' : ''}
        >
          <Icon name={item.icon} />
          <span>{item.title}</span>
        </CustomLink>
      ))}
    </LeftBarStyled>
  )
}

export default LeftBar

const LeftBarStyled = styled.div`
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 15px;
`

const CustomLink = styled(Link)`
  font-weight: normal;
  padding: 7px 0;
  text-decoration: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 5px 0;
  transition: color .3s linear;
  color: ${customTheme.colors.purple};
  font-family: "Roboto","Open Sans",sans-serif;
  
  span {
    border-bottom: 1px solid #D8D8D8;
    width: 100%;
    padding: 0 10px 12px;
  }
  
  &:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }
  
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;  

    span {
      border: none;
      margin-bottom: -12px;
    }
  }
  
  &:hover {
    color: ${customTheme.colors.purple};
  }

  &.active {
    font-weight: bold;
  }
  
  svg {
    vertical-align: middle;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    color: #9D9D9D;
  }
`
