export const ddiWithMasks = {
  1: '(999) 999-9999', // Canada | United States
  55: '(99) 99999-9999', // Brasil
  7: '999999999999999',
  20: '999999999999999',
  27: '999 999 9999', // South Africa
  30: '99 9999 9999', // Greece
  31: '999 999 9999', // Netherlands
  32: '99 999 99 99', // Belgium
  33: '99 99 99 99 99', // France
  34: '999 99 99 99', // Spain
  36: '(9) 999 9999', // Hungary
  39: '99 9999 9999', // Italy
  40: '999 999 9999', // Romania
  41: '999 999 99 99', // Switzerland
  43: '9999 999999', // Austria
  44: '999 9999 9999', // United Kingdom
  45: '99 99 99 99', // Denmark
  46: '99-999 999 99', // Sweden
  47: '99 99 99 99', // Norway
  48: '99 999 99 99', // Poland
  49: '999 99999999', // Germany
  51: '(99) 9999999', // Peru
  52: '99 99 9999 9999', // Mexico
  53: '999999999999999',
  54: '999 9999-9999', // Argentina
  56: '999999999999999',
  57: '(9) 9999999', // Colombia
  58: '999999999999999',
  60: '99-9999 9999', // Malaysia
  61: '(99) 9999 9999', // Australia
  62: '999999999999999',
  63: '999999999999999',
  64: '99-999 9999', // New Zealand
  65: '9999 9999', // Singapore
  66: '999999999999999',
  81: '99-9999-9999', // Japan
  82: '99-9999-9999', // South Korea
  84: '999999999999999',
  86: '999999999999999',
  90: '999999999999999',
  91: '999999999999999',
  92: '999999999999999',
  94: '999999999999999',
  95: '999999999999999',
  98: '999999999999999',
  212: '999999999999999',
  213: '999999999999999',
  216: '999999999999999',
  218: '999999999999999',
  220: '999999999999999',
  221: '999999999999999',
  222: '999999999999999',
  223: '999999999999999',
  224: '999999999999999',
  226: '999999999999999',
  227: '999999999999999',
  228: '999999999999999',
  229: '999999999999999',
  231: '999999999999999',
  232: '999999999999999',
  233: '999999999999999',
  234: '999999999999999',
  236: '999999999999999',
  237: '999999999999999',
  238: '999999999999999',
  239: '999999999999999',
  240: '999999999999999',
  241: '999999999999999',
  242: '999999999999999',
  244: '999999999999999',
  245: '999999999999999',
  248: '999999999999999',
  249: '999999999999999',
  250: '999999999999999',
  251: '999999999999999',
  252: '999999999999999',
  253: '999999999999999',
  254: '999999999999999',
  256: '999999999999999',
  257: '999999999999999',
  258: '999999999999999',
  260: '999999999999999',
  261: '999999999999999',
  262: '999999999999999',
  263: '999999999999999',
  264: '999999999999999',
  265: '999999999999999',
  266: '999999999999999',
  267: '999999999999999',
  268: '999999999999999',
  269: '999999999999999',
  290: '999999999999999',
  291: '999999999999999',
  297: '999999999999999',
  298: '999999999999999',
  299: '999999999999999',
  350: '999999999999999',
  351: '999 999 999', // Portugal
  352: '99 99 99 99', // Luxembourg
  353: '(99) 999 9999', // Ireland
  354: '999999999999999',
  356: '9999 9999', // Malta
  357: '99 999999', // Cyprus
  358: '99 99999999', // Finland
  359: '99 999 9999', // Bulgaria
  370: '(9-9) 999 9999', // Lithuania
  371: '99 999 999', // Latvia
  372: '999 9999', // Estonia
  373: '999999999999999',
  374: '999999999999999',
  375: '999999999999999',
  376: '999999999999999',
  377: '999999999999999',
  378: '999999999999999',
  379: '999999999999999',
  380: '999999999999999',
  381: '999999999999999',
  385: '99 9999 999', // Croatia
  386: '(99) 999 99 99', // Slovenia
  387: '999999999999999',
  389: '999999999999999',
  417: '999999999999999',
  420: '999 999 999', // Czech Republic
  421: '99/999 999 99', // Slovakia
  500: '999999999999999',
  501: '999999999999999',
  502: '999999999999999',
  503: '9999 9999', // El Salvador
  504: '999999999999999',
  505: '999999999999999',
  506: '999999999999999',
  507: '999-9999', // Panama
  509: '999999999999999',
  590: '999999999999999',
  591: '999999999999999',
  592: '999999999999999',
  593: '999999999999999',
  594: '999999999999999',
  595: '999999999999999',
  596: '999999999999999',
  597: '999999999999999',
  598: '999999999999999',
  670: '999999999999999',
  671: '999999999999999',
  672: '999999999999999',
  673: '999999999999999',
  674: '999999999999999',
  675: '999999999999999',
  676: '999999999999999',
  677: '999999999999999',
  678: '999999999999999',
  679: '999999999999999',
  680: '999999999999999',
  681: '999999999999999',
  682: '999999999999999',
  683: '999999999999999',
  686: '999999999999999',
  687: '999999999999999',
  688: '999999999999999',
  689: '999999999999999',
  691: '999999999999999',
  692: '999999999999999',
  850: '999999999999999',
  852: '9999 9999', // Hong Kong
  853: '999999999999999',
  855: '999999999999999',
  856: '999999999999999',
  880: '999999999999999',
  886: '99 9999 9999', // Taiwan, Province of China
  960: '999999999999999',
  961: '999999999999999',
  962: '999999999999999',
  963: '999999999999999',
  964: '999999999999999',
  965: '999999999999999',
  966: '999999999999999',
  967: '999999999999999',
  968: '999999999999999',
  969: '999999999999999',
  971: '999999999999999',
  972: '999-999-9999', // Israel
  973: '999999999999999',
  974: '999999999999999',
  975: '999999999999999',
  976: '999999999999999',
  977: '999999999999999',
  992: '999999999999999',
  993: '999999999999999',
  994: '999999999999999',
  996: '999999999999999',
  998: '999999999999999',
  1242: '999999999999999',
  1246: '999999999999999',
  1264: '999999999999999',
  1268: '999999999999999',
  1345: '999999999999999',
  1441: '999999999999999',
  1473: '999999999999999',
  1649: '999999999999999',
  1664: '999999999999999',
  1758: '999999999999999',
  1787: '999999999999999',
  1809: '999999999999999',
  1868: '999999999999999',
  1869: '999999999999999',
  1876: '999999999999999',
  7880: '999999999999999'
}
// Dominican Republic (999) 999-9999
// Puerto Rico (999) 999-9999
