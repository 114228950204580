import React from 'react'
import styled from 'styled-components'
import ASPAS from './assets/aspass.jpg'
import LOGO from './assets/programj.jpg'
// import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Link } from 'react-router-dom'

const Banner = () => {
  return (
    <Container>
      {/* <Link to="/">
        <AiOutlineArrowLeft color="#fff" size={15} />
      </Link> */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: '-10px', cursor: 'pointer' }}>
        <Link to="/">
          <img src={ASPAS} alt="Image representando uma virgula" style={{ width: 60, height: 60 }} />
        </Link>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
        <img src={LOGO} alt="Logo" style={{ width: 260, height: 110 }} />
      </div>
    </Container>
  )
}
export default Banner

const Container = styled.div`
  width: 100%;
  height:271px;
  display: flex;
  flex-direction: column;
  gap:13px;
  background-color: #000;
  padding:25px;
  
`
