export default {
  translations: {
    profile: {
      perfil: 'Profile',
      me: 'About Me',
      notes: 'Notes',
      watch: 'Contents',
      todas: 'TODAS GROUP',
      out: 'Logout',
      about: 'About Me',
      edit: 'Edit Profile',
      signature: 'My Subscription',
      password: 'Change Password',
      about_me: 'My Data',
      title1: 'How would you like to be called?',
      title2: 'Full Name',
      title3: 'Email',
      title4: 'Date of Birth',
      title5: 'Mobile',
      title6: 'Additional Information',
      title7: 'Profession',
      title8: 'Goal at TODAS GROUP',
      title9: 'My Address',
      title10: 'Postal Code',
      title11: 'Street',
      title12: 'Complement',
      title13: 'Neighborhood',
      title14: 'City',
      title15: 'State',
      title16: 'Country',
      title17: 'Save',
      title18: 'Saving'
    },
    profile_types: {
      title1: 'Student',
      title2: 'Entrepreneur',
      title3: 'Corporate',
      title4: 'Freelancer',
      title5: 'In Transition',
      title6: 'Legal',
      title7: 'Healthcare'
    },
    profile_password: {
      title1: 'Subscription Details',
      password: 'Password',
      confirmPassword: 'Confirm Password'
    },
    components: {
      search: 'Search',
      new_content: 'New'
    },
    login_screen: {
      email: 'Email',
      password: 'Password',
      forgot_password: 'Forgot My Password',
      message: 'Already part of Todas Group and it’s your first time on the new platform?',
      update_password: 'Update your password',
      login: 'Login',
      login_load: 'Logging in',
      login_microsoft: 'Sign in with Microsoft AD',
      register: 'Don’t have an account yet?',
      make_part: 'Join'
    },
    footer: {
      copyright: 'All rights reserved.',
      home: {
        title: 'SOS Todas Juntas',
        description: 'Send your question to one of our specialists and receive personalized guidance within 48 hours.',
        details: 'Talk to a specialist'
      }
    },
    header_logged: {
      welcome: 'Welcome to TODAS',
      home: 'Home',
      habilidades: 'Skills',
      conquistas: 'Achievements',
      potencias: 'Your Strength'
    },
    conquest_main: {
      parabens: 'Congratulations',
      parabens_desc: 'Your achievements are amazing',
      desc: 'What a fantastic evolution!',
      desc_02: 'We are very proud',
      desc_03: 'Keep growing and going further',
      obj: 'Watch Contents',
      obj_02: 'Do Networking',
      obj_03: 'See Progress'
    },
    conquest_internal: {
      gift: 'see award',
      shared: 'Share',
      start: 'Start',
      unblock: 'Unlock new badges and awards',
      content_watch: 'watched contents',
      conquest: 'Current achievement',
      progress: 'In progress',
      gift_name: 'Award',
      title_desc: 'Your constant evolution is very important for TODAS GROUP. With each special badge earned, you receive an award and further strengthen our user community.',
      award_01: 'Class 01 - Self-esteem for positioning',
      award_02: 'Click here to access your award'
    },
    watch: {
      watch: 'Watch now',
      watch_now: 'Watch the classes now',
      keep_watching: 'Keep watching',
      keep_description: 'Evolving together',
      watch_live: 'Session Replays',
      live_description: 'Our mentors unfiltered and uncut'
    },
    skills: {
      trail: 'Skill Trails',
      title_01: 'The Science of Self-confidence',
      title_02: 'Strategic Positioning',
      title_03: 'Emotional intelligence',
      title_04: 'Focus and Mindfulness',
      title_05: 'Authorship and Leadership',
      title_06: 'Adaptability',
      title_07: 'Positive Discipline',
      title_08: 'Negotiation and Influence',
      title_09: 'Critical Thinking',
      title_10: 'Personal Branding',
      title_11: 'Innovation',
      title_12: 'Leadership',
      title_13: 'Networking',
      title_14: 'Strategic Communication',
      title_15: 'Motherhood and Career',
      title_16: 'Team Development',
      title_17: 'Communication',
      title_18: 'Negotiation - Camila Farani and Paula Paschoal',
      title_19: 'Emotional Balance',
      title_20: 'Positioning is Present',
      title_21: 'Entrepreneurial Learnings',
      title_22: 'Leadership Advice',
      title_23: 'How to be the CEO of Your Own Career',
      title_24: 'Pillars to Build Your Brand',
      title_25: 'Creating Your Space',
      title_26: 'Brave Choices',
      title_27: 'Identities and Learnings',
      title_28: 'Building a Career Beyond Standards',
      title_29: 'Women at the Top',
      title_30: 'Career Possibilities',
      title_31: 'Choices that Make a Difference',
      title_32: 'Leadership with Authenticity',
      title_33: 'The Courage to Embrace the New',
      title_34: 'Entrepreneurship with Purpose',
      title_35: 'Humanized Leadership',
      title_36: 'Growth Mindset',
      title_37: 'How to Create Relevance for Your Brand',
      title_38: 'Leading in Times of Crisis',
      title_39: 'Career, Journey, and Worldview',
      title_40: '10 Learnings for Success',
      title_41: 'Marketing and Purpose',
      title_42: 'Essential Points in Innovative Business',
      title_43: 'Building Your Brand in Times of Crisis',
      title_44: 'Positioning',
      title_45: 'Emotional Intelligence',
      title_46: 'Self-confidence',
      title_47: '',
      details_01: 'LinkedIn TopVoice and Company Advisor',
      details_02: 'Diversity Consultant',
      details_03: 'HR Director Diageo and TedX Speaker',
      details_04: 'Commercial Director Cielo',
      details_05: 'Lifestyle Psychiatrist',
      details_06: 'Head of Marketing TikTok Latin America'
    },
    watch_content: {
      watch01: 'Watched',
      watch02: 'In Progress',
      watch03: 'Saved',
      watch04: 'No Content',
      watch05: 'liked',
      watch06: 'Start watching now',
      watch07: 'Recommended Contents',
      watch08: 'Description',
      watch09: 'Do Networking',
      watch10: 'Create Notes now',
      watch11: 'List Notes',
      watch12: 'Saved',
      watch13: 'Watch later',
      watch14: 'Methodology',
      watch15: 'You are in',
      watch16: 'liked',
      watch17: 'Rate',
      watch18: 'Like',
      watch19: 'What is the ideal profile for Networking in this content',
      watch20: 'Hello, we are looking for profiles that completed this class, as soon as we finish the checks, we will send you a notification',
      watch21: 'Women',
      watch22: 'Send request',
      watch23: 'Your Networking request is awaiting response',
      watch24: 'We are looking for the ideal profile for this Networking',
      watch25: 'Message Center',
      watch26: 'You have a Networking request awaiting response',
      watch27: 'You have an active Networking for this content',
      watch28: 'Create note',
      watch29: 'Selected time',
      watch30: 'Select a tag',
      watch31: 'Note title ...',
      watch32: 'Write here ...',
      watch33: 'Save note',
      watch34: 'Notes',
      watch35: 'Save',
      watch36: 'Select a note',
      watch37: 'Fill in the comment field',
      watch38: 'Comments',
      watch39: 'Newest',
      watch40: 'Oldest',
      watch41: 'Write a comment',
      watch42: 'See more',
      watch43: 'comments',
      watch44: 'comment',
      watch45: '',
      watch46: '',
      watch47: '',
      watch48: '',
      watch49: '',
      watch50: ''
    },
    video_header_title: {
      title1: 'Motherhood and Career',
      title2: 'Positive Discipline',
      title3: 'Networking',
      title4: 'Team Development',
      title5: 'Strategic Communication'
    },
    video_job_title: {
      title1: 'Co-Founder of Todas Group',
      title2: 'Founder of B2Mamy',
      title3: 'Neuroscientist'
    },
    video_title: {
      title01: 'Joana - Lesson 01',
      title02: 'What is essential for team development, with CFO of Gerando Falcões, Julia Machado',
      title03: '“Awareness, preparation, and difficult conversations. How to deal with leadership challenges?”, by consultant, mentor, and speaker Marisa Salgado',
      title04: 'Self-awareness and self-confidence to develop teams - Caring for yourself before caring for others, by consultant, mentor, and speaker Marisa Salgado',
      title05: 'Assertive Communication Exercise, with neuroscientist Dr. Carla Tieppo, faculty at Singularity University Brazil',
      title06: 'The role of neuroscience in communication, with neuroscientist Dr. Carla Tieppo, faculty at Singularity University Brazil',
      title07: 'Discipline Exercise with career coach Lívia Kawano',
      title08: 'Positive discipline and consistent progress over time, with Manoela Mitchell, CEO and Co-Founder of Pipo Saúde',
      title09: 'The importance of consistency for building a more disciplined life, with Andrezza Zago, Marketing Director at BMW Group Financial Services',
      title10: 'Discipline as a way to build self-love, with Andrezza Zago, Marketing Director at BMW Group Financial Services',
      title11: 'Positive Discipline Exercise',
      title12: 'The power of discipline as a way to solve problems more easily, with neuroscientist Dr. Carla Tieppo, faculty at Singularity University Brazil',
      title13: 'From mother to daughter and from mother to mother with the mentors of Todas',
      title14: 'The importance of support networks, with Marcela Rezende, Vice President of Marketing at MadeiraMadeira',
      title15: 'Motherhood and empathy, with Marcela Rezende, Vice President of Marketing at MadeiraMadeira',
      title16: 'Being a happy mother and professional, with Luciana Viegas, creator of the Movement Black Lives with Disabilities Matter',
      title17: 'Motherhood and life transformation, with Luciana Viegas, creator of the Movement Black Lives with Disabilities Matter',
      title18: 'The power of being a mother, with Dhafyni Mendes, Co-Founder of Todas Group',
      title19: 'Problem-solving within motherhood, with Dhafyni Mendes, Co-Founder of Todas Group',
      title20: 'Plural motherhoods, with Dani Junco, CEO and Founder of B2Mamy',
      /// new
      title21: 'How does our brain handle the changes around us?',
      title22: 'Cognitive and emotional adaptability with Kim Farrell - General Manager and Marketing Director of TikTok Latin America',
      title23: '10 practical tips for adaptability with Kim Farrell - General Manager and Marketing Director of TikTok Latin America',
      title24: 'Todas Tips on Adaptability',
      title25: 'The importance of adaptability nowadays, with Lívia Kawano - Career Development Specialist',
      title26: 'Adapting to new environments, with Camila Velzi - Head of E-commerce D2C at Mondelez International',
      title27: 'Discovering new paths and possibilities with Dilma Campos - CEO and Founder of Outra Praia (Live Marketing)',
      title28: 'New paths with Rachel Muller - Executive Business Director at Nestlé',
      title29: 'Introduction',
      title30: 'Self-confidence - Serotonin and Validation - Lesson 1',
      title31: 'Self-confidence - Purpose and Potential - Lesson 2',
      title32: 'Camila Farani - 5 Essential Points',
      title33: 'Self-awareness - Lesson 1',
      title34: 'Self-awareness - Lesson 2',
      title35: 'Multiple Intelligences - Lesson 3',
      title36: 'Self-image - Lesson 4',
      title37: 'Self-criticism - Lesson 5',
      title38: 'Self-recognition - Lesson 6',
      title39: 'How to position ourselves assertively to achieve our goals?',
      title40: 'Developing a virtuous cycle for Positioning',
      title41: 'Positioning in practice with Gabriela Prioli - CNN Host',
      title42: 'Self-esteem for positioning, with Vivi Duarte - Director of Connections for Meta/Facebook in Latin America',
      title43: 'Planning for positioning: technique and methods to create your action plan!',
      title44: 'Mastery and Preparation!',
      title45: 'Non-verbal communication for positioning with Luciana Guimarães - Serial Entrepreneur Endeavor',
      title46: 'Verbal communication for positioning with Public Speaking Teacher Mª Peroni',
      title47: 'Protagonism in positioning with Denise Paludett... Specialist in career self-management!',
      title48: 'Emotional management',
      title49: 'Emotional system and Anticipation',
      title50: 'Emotional balance with psychiatrist and lifestyle specialist Dr. Ana Paula Carvalho',
      title51: 'What is well-being and where is it?',
      title52: 'Self-management of internal factors',
      title53: 'Wheel of Life',
      title54: 'Flow state',
      title55: 'Conclusion',
      title56: 'Lesson 01 - Dr. Carla Tieppo - Focus and full attention',
      title57: 'Lesson 02 - Dr. Carla Tieppo - Focus and Full Attention',
      title58: 'Lesson 01 - Focus and Attention',
      title59: 'Lesson 02 - Focus and Attention',
      title61: 'Lesson 01 - Focus and Awareness',
      title62: 'Lesson 02 - Stress Management and Breathing',
      title63: 'Lesson 03 - Organization with Full Attention',
      title64: 'Lesson 04 - Empathy and Conscious Communication',
      title65: 'Lesson 05 - Living with Full Attention',
      title66: 'Meditation 01 - Three Breaths',
      title67: 'Meditation 02 - Conscious Breathing',
      title68: 'Meditation 03 - Body Scan',
      title69: 'Meditation 04 - Generating connection with others',
      title70: 'Meditation 05 - Gratitude',
      title71: 'Deconstructing the concept of Authorship with neuroscientist Dr. Carla Tieppo',
      title72: 'Protagonism Tips with neuroscientist Dr. Carla Tieppo',
      title73: 'Looking for references to build our authorship with journalist Alexandra Loras',
      title74: 'Self-awareness to develop Authorship and Protagonism with journalist Alexandra Loras',
      title75: 'How to overcome adversities on the journey in search of authorship and protagonism with education manager at Grupo Boticário, Liz Camargo',
      title76: 'Authorship Test',
      title77: 'Understanding the results of the Authorship Test',
      title78: 'A step beyond Authorship with Todas Tips!',
      title79: '3 inspiring tips from Mentors Todas! - Ana Laura Magalhães (Forbes under 30), Flávia Bittencourt (CEO of Adidas Brazil), and Suzan Rivetti (Chairman Company Group Johnson & Johnson)',
      title80: 'Ana K. Lesson 01',
      title81: 'Ana K. Lesson 02',
      title82: 'Building a more positive discipline with small acts, with Andrezza Zago, Marketing Director at BMW Group Financial Services',
      title83: 'Positive discipline in practice, with the mentors of Todas',
      title84: 'Negotiating using emotionality, with neuroscientist Dr. Carla Tieppo, faculty at Singularity University Brazil',
      title85: 'Negotiation Exercise with neuroscientist Dr. Carla Tieppo, faculty at Singularity University Brazil',
      title86: 'Negotiation Experiences with Renata Daltro, Commercial Director at Cielo',
      title87: 'Negotiation is for Everyone! Negotiation Tips from the Mentors of Todas',
      title88: '4 negotiation examples with Carolina Ragazzi, Executive Director at Goldman Sachs',
      title89: 'Emotional management and self-awareness for negotiating with Carolina Ragazzi, Executive Director at Goldman Sachs',
      title90: 'People-focused negotiation, with Deidi Souza, Commercial Manager at Suzano S.A.',
      title91: 'Negotiation talk with Paula Paschoal, Global Director of Google Pay, and Camila Farani, Founder of G2 Capital',
      title92: 'Critical Thinking through the lens of neuroscience',
      title93: 'Building Critical Thinking for social transformation with psychologist Mafoane Odara',
      title94: 'Internal dialogues for developing Critical Thinking with psychologist Mafoane Odara',
      title95: 'Critical Thinking methods with Gabriela Costa, Senior Director at AB Inbev in New York',
      title96: 'The importance of being informed to develop Critical Thinking with Alessandra Blanco, General Manager of Yahoo Brazil',
      title97: 'Examples and methods for Critical Thinking with Alessandra Blanco, General Manager of Yahoo Brazil',
      title98: 'Tips for critical thinking based on self-esteem with actress and activist Tathi Piancastelli',
      title99: 'Critical Thinking studies and practices',
      title100: 'Critical Thinking development session with therapist Gabriela Sayago',
      title101: 'Communicative exchanges with diverse people, with therapist Gabriela Sayago',
      title102: 'How to build your personal brand with authenticity!',
      title103: '5 tips for personal brand development with communication specialist Luiza Brasil',
      title104: 'Tips for personal brand with actress and activist Tathi Piancastelli',
      title105: 'Building your Personal Brand with neuroscientist Dr. Carla Tieppo',
      title106: 'Positioning to build your personal brand with communication specialist Luiza Brasil',
      title107: 'Innovating and personal brand with neuroscientist Dr. Carla Tieppo',
      title108: 'Building personal brand with journalist Alexandra Loras',
      title109: 'The importance of personal brand with journalist Alexandra Loras',
      title110: 'Mentors and Innovation!',
      title111: 'What is innovation for you?',
      title112: '4 tips for creative innovation with Juliana Vilela, Innovation Manager at Aegea Saneamento',
      title113: 'How to innovate by breaking paradigms, with founder and CEO of Vereda Educação, Celso Lopes',
      title114: '5 inspiring tips from the mentors of Todas for Innovation!',
      title115: 'Innovation and Creativity Lesson 1',
      title116: 'Main Barriers to Innovation in Companies',
      title117: 'Finding New Solutions and Unexpected Innovations - with Co-Founder of Todas Group Dhafyni Mendes',
      title118: 'Inov(Ação) in Practice, with Co-Founder of Todas Group Dhafyni Mendes',
      title119: 'Building New Futures, with the Head of Future Beverages at Ambev, Nathália Brandão.',
      title120: 'Methods for Strategic Planning in Innovation, with the Head of Future Beverages at Ambev, Nathália Brandão.',
      title121: '5 Innovation Exercises with Dr. Carla Tieppo, Neuroscientist',
      title122: 'Studies and Practices on Leadership with Neuroscientist Dr. Carla Tieppo, Faculty of Singularity University Brazil',
      title123: 'Leadership Test with Neuroscientist Dr. Carla Tieppo, Faculty of Singularity University Brazil',
      title124: 'Perspectives on Leadership with the General Director of Dior Brazil, Daniela Ota',
      title125: 'Expanding Our View on Leadership and the Importance of Female Leadership with Thiago Coelho, Commercial Vice President of Iguatemi S.A.',
      title126: 'Seeing Privileges and Changing Scenarios with Thiago Coelho, Commercial Vice President of Iguatemi S.A.',
      title127: 'Mapping Leadership Profiles with Leadership Coach and Author, Caroline Marcon',
      title128: 'Emotional Intelligence for Leadership with Leadership Coach, Caroline Marcon',
      title129: 'Leadership as a Tool to Boost Careers, with the President of Match Group, Eugênia Del Vigna',
      title130: 'Practical Tips to Exercise Our Leadership Capacity with the President of Match Group, Eugênia Del Vigna',
      title131: 'Social Relations and How Our Brain Helps Us Network',
      title132: 'Get to Know the Story of the Head of Marketing for Adobe Latin America, Núbia Mota!',
      title133: 'Tips and Secrets from Núbia Mota for Good Networking!',
      title134: 'Joana - Class 02',
      title135: 'Strategically Working on Your Possibilities - With Tati Oliva, CEO of Cross Networking.',
      title136: 'Methods to Stand Out in Networking with CEO of Cross Networking - Tati Oliva',
      title137: 'Practices and Studies on Networking',
      title138: '“Communicating is Not Just Talking,” with Diversity & Inclusion Leader, Simone Coutinho',
      title139: 'Connecting with the Team Using Assertive Communication, with Diversity & Inclusion Leader, Simone Coutinho',
      title140: 'Tips for Assertive Communication with Todas Mentors',
      title141: 'Assertive Communication with Nonviolent Communication Techniques, by Therapist Gabriela Sayago',
      title142: 'Test - What is Your Communication Profile?',
      title143: 'Preparation as a Key Element to Communicate Better, with Journalist Mariana Guidolini',
      title144: 'Three Practical Tips for Developing Good Communication and Oratory, with Journalist Mariana Guidolini',
      title145: 'Using the Right Words to Communicate, with Journalist Mariana Guidolini',
      title146: 'Building Self-Confidence for More Assertive Communication, with Journalist Mariana Guidolini',
      title147: 'Insights on the Dynamics of the Brain System with Motherhood, with Neuroscientist Dr. Carla Tieppo, Faculty of Singularity University Brazil',
      title148: 'Motherhood and the Dynamics Built Around Mothers’ Careers, with Neuroscientist Dr. Carla Tieppo, Faculty of Singularity University Brazil',
      title149: 'Motherhood and Ancestry, with Gabriela Ferreira, Director of Diversity and Inclusion at Serasa Experian',
      title150: 'Challenges of Motherhood and Career, with Gabriela Ferreira, Director of Diversity and Inclusion at Serasa Experian',
      title151: 'The Importance of Choosing Partnerships in Motherhood, with Gabriela Ferreira, Director of Diversity and Inclusion at Serasa Experian',
      title152: 'Facts About Work and Motherhood with Dani Junco, CEO and Founder of B2mamy',
      title153: 'Superpowers Developed in Motherhood, with Dani Junco, CEO and Founder of B2 Mamy',
      title154: 'What NOT to Do for Team Development, with Neuroscientist Dr. Carla Tieppo, Faculty of Singularity University Brazil',
      title155: 'Consolidation Exercise to Develop Teams, with Neuroscientist Dr. Carla Tieppo, Faculty of Singularity University Brazil',
      title156: '“How to Lead and Connect with Your Team?”, with the Vice President of the Brazilian Stock Exchange, called [B]³, Ana Buchaim',
      title157: '“Women and Diversity in Leadership for Better Results”, with the Vice President of the Brazilian Stock Exchange, called [B]³, Ana Buchaim.',
      title158: 'Team Development Tips with Todas Mentors!',
      title159: 'Strategic networking for women’s careers, with Adriana Alves, LinkedIn Top Voice and co-founder of Lupa!',
      title160: 'Much more than a resume, use LinkedIn strategically to advance your career, with Adriana Alves!',
      title161: 'Genuine Connections: The Key to Success, with Marisa Salgado, LinkedIn Top Voice and HR consultant!'
    },
    video_config: {
      config01: 'Playback Speed',
      config02: 'Options',
      config03: 'Normal'
    },
    description_skills: {
      title1: 'Mind',
      title2: 'Modeling',
      title3: 'Methodology',
      sub1: 'Understanding how your brain reacts to this skill',
      sub2: 'Learning from the experience of a woman who is a reference in this skill',
      sub3: 'Step-by-step to develop this skill in yourself',

      sub4: '<p>Self-confidence is the main foundation for your professional and personal growth, which is why this is our first skill to develop practically at Todas! Learn through neuroscience, the experience of Camila Farani, the top investor in Brazil, and an innovative methodology created by leading experts in human development on how to build a more confident life! Let’s go together?</p>',
      sub5: `<p>For 5 months, we studied 40 women in prominent positions in Latin America and mapped which skills and points were common to all for their professional success! A good positioning was one of the most cited factors in this research, highlighting how essential it is to develop this skill for women who wish to grow! Learn how to position yourself assertively and strategically with the techniques, methods, and examples from the Todas team and women who are references in positioning, sharing their key insights for you!

    In this module:

    Lesson 1 and 2: Neuroscience of positioning with neuroscientist and Singularity faculty: Dr. Carla Tieppo

    Lesson 3: Positioning in practice with Gabriela Prioli - CNN presenter

    Lesson 4: Self-esteem for positioning with Vivi Duarte - Director of connections for Meta/Facebook in Latin America

    Lesson 5: Planning for positioning: techniques and methods to create your action plan!

    Lesson 6: Mastery and Preparation: How to execute your positioning plan!

    Lesson 7: Non-verbal communication for positioning with Luciana Guimarães - Serial Entrepreneur Endeavor

    Lesson 8: Verbal communication for positioning with Oratory Professor Mª Peroni.

    Lesson 9: Protagonism in positioning with Denise Paludetto - HR Director and leader mentor, specialist in self-management of career!

    Enjoy your growth moment!</p>`,
      sub6: `<p>Check out the experiences and advice from Nicole Vendramini for building healthier habits based on focus and mindfulness.

        Nicole is co-founder and co-CEO of the health and wellness platform Holistix.

        After many years working as a marketing specialist within large brands in the industry, Nicole decided to dive into holistic health studies and apply her knowledge by developing her company's products. Now she shares all her tips with you!</strong></p>`,
      sub7: '<p>Is there a right formula for authorship? Being authentic and positioning ourselves as the protagonists of our own story also comes with a series of challenges. How to deal with frustration, projects that don’t work out, and taking steps back can happen along the way.</p>'
    },
    summary: {
      title1: `<p>In this lesson, understand what produces self-confidence for your brain, and what way of thinking and acting enhances or diminishes your self-confidence!</p>\r\n' +
        '\r\n' +
        '<p>Learn from Dr. Carla Tieppo, a pioneer in the study of the brain in human behavior and a reference in neuroscience in Latin America! Carla is part of the faculty in Brazil at Singularity, the largest innovation center in the world, founded in the NASA research field! Enjoy your class!</p>`
    },
    assessment: {
      title1: 'Impact Survey',
      title2: 'An essential step for you to clearly identify your strengths and discover improvement opportunities,',
      title3: 'it will only take 3 minutes of your time.',
      title4: 'Let’s go',
      title5: 'Estimated time 3 minutes',
      title6: 'WARNING: Answer the questions below. This is a completely anonymous survey.',
      title7: 'Analyze the following statements and select according to the types of scale:',
      title8: '(1) Rarely, (2) Sometimes, (3) Neutral, (4) Often, (5) Frequently.',
      title9: 'You did not select all the questions',
      title10: 'Responses saved successfully',
      title11: 'Discovery of Strengths',
      title12: 'This process offers a unique opportunity to explore your specific skills and talents, which will result in personalizing your profile with goals and objectives,',
      title13: 'it will only take 5 minutes of your time.',
      title14: 'Estimated time 5 minutes',
      title15: 'How much do you identify with this statement?',
      title16: 'Strengths  Assessment',
      title17: 'When we recognize our skills and talents, we can put our energy into what we’re good at, while spotting areas to improve helps us grow and sharpen our technical skills.',
      title18: 'Please answer the impact and strengths survey to see your result',
      title19: 'check your Result',
      title20: 'ENABLING SKILLS',
      title21: 'SKILL ANALYSIS',
      title22: 'Start',
      assessmentButton: 'Submit'
    },
    assessment_question: {
      info1: 'Rarely',
      info2: 'Frequently',
      info3: 'Finish',
      info4: 'Sometimes',
      info5: 'Neutral',
      info6: 'Often',
      title1: 'I feel confident/capable of taking on new challenges.',
      title2: 'I know my strengths.',
      title3: 'I can express my ideas clearly.',
      title4: 'I can balance my personal and professional life.',
      title5: ' I know I can go further.',
      title6: 'I can tell that people around me are noticing and appreciating the positive changes in my posture and attitude.',
      title7: 'I believe I have inspired other women to grow as well.',
      title8: 'I see that the company I work for is a good place for women to grow.',
      title9: ' In my company, women have equal opportunities as men.',
      title10: 'My direct manager encourages my development.',
      title11: 'The senior leadership team is committed to supporting the growth of women within the company.',
      title12: 'I feel a sense of community among the women in the company where I work.',
      title13: 'I accept to take calculated risks for new projects.',
      title14: 'I accept unforeseen events and turn them into opportunities.',
      title15: 'I’m confident I’ll get where I want to be, regardless of the rejections I face along the way.',
      title16: 'I know what triggers me emotionally and consider my own reactions.',
      title17: 'I identify complex problems and solve them in a simple way.',
      title18: 'I can establish long-lasting and mutually beneficial relationships.',
      title19: 'I can influence decisions around me.',
      title20: 'I can commit from start to finish on the activities I propose.',
      title21: 'I build connections and establish trust quickly and assertively.',
      title22: 'I have a clear vision and drive the team to achieve it successfully.',
      title23: 'I understand each team member’s individual needs and can provide support.',
      title24: 'I understand the value of consistency in actions.',
      title25: 'I am open to evaluate that there are different points of view for the same challenge.',
      title26: 'I’m willing to adapt and have flexibility.',
      title27: 'I genuinely care about the other person’s point of view.',
      title28: 'I feel capable of making decisions individually based on my own analysis.',
      title29: 'I feel comfortable expressing my opinion clearly and firmly, regardless of others’ opinions.',
      title30: 'I acknowledge my mistakes and can change my attitude towards them.',
      title31: 'I recognize my limits and know when to ask for help.',
      title32: 'I recognize the value of my talents to achieve professional results.',
      title33: 'I recognize that there are various solutions to the same problem.',
      title34: 'I respect my own style in establishing a work model.',
      title35: 'I know how to balance urgent and important demands.',
      title36: 'I know how to take the first step to connect with others for professional exchanges.',
      title37: 'I know how to choose the right moment to take a stand or let it pass.',
      title38: 'I know my strengths and can use them to my advantage.',
      title39: 'I follow through with the best idea even if it’s not mine.',
      title40: 'I am capable of bringing new solutions to a problem that has already been discussed.',
      title41: 'I am aware that everything about me communicates and I can adjust accordingly.',
      title42: 'I have the ability to learn new skills and put them into practice.',
      title43: 'I get the big picture and and positively influence the environment and results.',
      title44: 'I am proud of my journey.',
      title45: 'I believe I can achieve what I’m aiming for.'
    },
    assessment_description: {
      title1: 'You are great at networking! Your social skills are incredible, as is your ability to read people and help them achieve their goals when needed, while also building relationships that provide you with tools to find help when facing challenges. You have the ability to connect people, creating bridges and solutions. This is a highly empowering skill for professional growth. Congratulations! We know that in our busy lives, where women have so many demands, maintaining a network is a significant challenge. Since you have an ease in opening connections, don’t forget to keep the flame of those connections alive. Organize your schedule to have a specific space for ongoing interaction with your contacts, from a calendar of birthdays or important dates to messages to check in on how they are doing. This will further enhance your networking agenda and the strength of your relationships. In our networking module at Todas, the class by Núbia Motta, an expert in corporate relations in Latin America, offers several tips on how to genuinely and strategically increase your networking. By the way, networking among women is one of our missions here at Todas, so count on us to create great and powerful connections for you as well. Together, we all grow!',
      title2: 'Innovation is your strength! You have the ability to see movements that most people haven’t even noticed yet, transforming your business vision into something powerful, adding very interesting perspectives to the current landscape and generating transformations. This is very powerful! Innovative people are agents of the change that the world needs, so recognize your value in being the innovative woman that you are! Innovative individuals think differently, anticipating trends and connecting points that still seem disconnected to others. A great tip to further enhance your power of innovation is to invest in improving your communication skills so that you have the tools to explain clearly what seems obvious to you but is not yet visible to others. This way, in addition to positioning yourself as a visionary professional, you also unite allies to generate innovative changes alongside you! One point of caution for innovative people is that, due to having many great ideas, the focus on execution can often become unclear. Know how to prioritize and evaluate which idea deserves to be put into practice now and which ones can be saved for a later time. In our app, there’s a class on innovation with our co-founder Dhafyni Mendes, where she shares a method she learned from Walt Disney on how to choose the best idea for current execution—you’re going to enjoy it! Another amazing tip for you is to watch the inspiring futurist Mônica Magalhães discuss the elements of innovation that generate the most value in companies worldwide... this class will definitely further boost your innovative talent to take you even higher!',
      title3: 'Authorship and protagonism are sources of strength in your life! You can use your personal characteristics as a differentiator to stand out, becoming unique in your environment. This is a trait of brilliant professionals, and all of us at TODAS are very happy when we find women who embody this strength! In a world with so many biases that encourage women to fit in within the job market, professionals who take pride in their differences and know how to use them positively with protagonism are agents of significant change in the entire professional ecosystem. Believe me, you inspire much more than you can imagine! People around you, whom you might not even realize, find in your courage to be who you are a pathway to being themselves as well, and that is transformational! However, the flip side of proudly embracing your journey and refusing to dim your light to fit in is that not everyone will understand your authorship and protagonist strength. Therefore, make sure to surround yourself with people who value your uniqueness, seek internal allies in your workplace who recognize your brilliance, and find spaces where your talent can be showcased. To motivate you, watch in our app the class on Authorship and Protagonism with Alexandra Loras, where she shares her inspiring journey and how she could have limited herself by events in her life that she used to further amplify her success, positioning herself in places where she could express her full uniqueness. Our collective wish is for you to shine brighter and brighter!',
      title4: 'Positioning is one of your strengths! You have the incredible ability to pick up information from your environment, find solutions, and present them strategically. Know that positioning is one of the main challenges in the growth of women’s careers, and if you have already mastered this skill, your competitive advantage in the market is immense. The good news is that positioning is like a muscle: the more you exercise it, the stronger it becomes, and this strength of yours will become even more extraordinary the more you use it! Don’t miss opportunities to express your point of view in strategic meetings and present your results in relevant contexts. A point to pay attention to regarding your ease of positioning is knowing when you truly need to take a stand. Don’t waste your energy positioning yourself on everything; one of the greatest intelligences of positioning is being strategic about the best moment and manner to do so. In our app, Gabriela Prioli’s class on strategic positioning addresses exactly this—great advice for you! Leverage your positioning power and conquer the world!',
      title5: 'Self-confidence is one of your strengths! You have knowledge of your strengths and know how to use them, bringing the lessons you have learned throughout your journey as internal tools to tackle external challenges. This is a skill often found in women at the top of leadership, indicating that you are on the right path to achieving your goals and inspiring more women to realize their dreams as well... Congratulations! On the other hand, one point of attention regarding excess self-confidence is self-centeredness, where you become so focused on your vision that you start to overlook other perspectives around you that can also be important parts of problem-solving. Practice your active listening so that your self-confidence can be increasingly enhanced positively and expand your repertoire of lessons and achievements. In the self-confidence class by Camila Farani in our app, she, who was considered one of the 500 most influential women in Latin America, gives great tips on managing self-confidence so you can soar even higher! We are ALL cheering for you and eager to applaud your next achievements, as we are sure you will accomplish many!',
      title6: 'Adaptability is one of your strengths! In a world that changes so much and so quickly, your ability to perceive transformations and quickly make changes to enhance results in new scenarios is very valuable! It is no coincidence that after the pandemic, adaptability became the most sought-after skill in the professional environment, so make the most of this moment to highlight your talent. You are sensitive to capturing recent information, clever in thinking of innovative possible solutions, and agile in executing new attempts... and that is amazing! Value this strength of yours! One point of caution for highly adaptable women is not to over-adapt! Do not compromise your beliefs by conforming to something you do not agree with! In her adaptability class at Todas, Rachel Muller, an inspiring executive from Nestlé, addresses exactly this theme of the healthy limit for adaptability; you will definitely enjoy it... a great tip for you to further enhance this powerful skill of adaptability! Also, don’t miss Kim Farrel, General Director of Marketing Operations at TikTok in Latin America, who shares her 10 steps to enhance adaptability, and soar even higher!',
      title7: 'Negotiation is your strength! You know how to read scenarios and find the best arguments to resolve conflicts and create good opportunities. You also have excellent persuasive power and interaction skills, knowing how to show the best side of the situation, building trust and connection with your positioning. Your listening skills, communication, and knowing when to be flexible also make you stand out in your negotiations... Congratulations! This is a powerful skill in women’s professional growth! One point of attention for those who excel in negotiations is to also pay attention to the deliverables you negotiated, so your next negotiation with that person can be just as efficient as the first. In her Negotiation class at Todas, Renata Daltro, VP at Cielo, who is recognized for her negotiation skills, shares her journey to become a reference in a still predominantly male environment. It will surely inspire you to use your negotiation talent to reach all the environments you dream of!',
      title8: 'Discipline is your strong point! You have the ability to clearly see the necessary points to achieve the goals you desire, building structured goals for executing that scenario, assigning responsibility and consistency in this process. Congratulations! The most successful people in the world have discipline as an essential part of their extraordinary results, signaling that you are already on the right track for your growth. You also have a super positive ability to organize and prioritize tasks; value this and apply it more and more in your work processes and teams, as it will bring increasing efficiency to the operations you are involved in. One point of attention for highly determined individuals is the tendency to be overly demanding; don’t forget to be kinder to yourself in the process of reaching your goals! Be nice to yourself! In our app, we have an entire module on positive discipline with great leaders from Latin America to further enhance this skill of yours, and a great tip for you is to also watch the emotional intelligence class with Dr. Ana Paula Carvalho, a psychiatrist from Harvard Medical School, which discusses how to be kinder to ourselves practically during our growth journey. All growing together!',
      title9: 'Emotional Intelligence is a power of yours! You have the ability to analyze facts and choose the best way to react, minimizing negative impacts on your life and those around you, and this is one of the most valuable skills in our current society! Often, our strengths are so natural to us that we fail to measure their value; you likely have this natural sensibility and cannot even imagine the positive results your presence generates in your professional and personal environment. But since awareness is the first step to growth, now that you know that emotional intelligence is your strength, make sure to leverage it and value this power of yours. Don’t miss opportunities to express your point of view, as you have a great vision and understanding of reactions, effects, and consequences; use your wisdom to stand out. One point of attention, given your great capacity for emotional understanding of the environment, is to ensure that people around you do not take advantage of your understanding by being disrespectful and expecting your calm reactions, overloading you with tasks or not properly recognizing your contributions and value. Remember how valuable you are, and use all your good emotional discernment to find the best way to be valued as you deserve. This skill will definitely take you far!!',
      title10: 'Wow, your strength is critical thinking! You have the incredible ability to gather different information, analyze opposing points of view sensibly, and find elements in various scenarios to build your opinions, and that is very valuable! In such a polarized world where most people just want to convince others of their convictions, a person like you, who knows how to listen and engage in dialogue with different positions and is willing to change your opinions about a topic while weighing the facts... is not only rare but also necessary for the growth of both corporations and the evolution of society. You are an agent of the changes our world needs! Your critical thinking skills give you a huge competitive advantage to suggest solutions; trust in this ability and articulate your opinions more in work meetings, with your leadership or peers. Another positive point of your critical thinking strength is that it contributes to a positive organizational climate, as you have the power to read different scenarios, potentially reducing conflicts and impacts among team members who think completely differently. Mafoane Odara, a renowned leader in Latin America known as a bridge builder in conflict environments, discusses in her critical thinking class at Todas how to balance the positive and negative sides of being a woman with critical thinking and how to maximize this magnificent skill you already possess! We are all here rooting for you and supporting you, as we are convinced that having more people with high critical thinking like you in leadership positions is the way to create better organizations for ALL people!',
      title11: 'Leadership is a strength of yours! You have a strong business vision, determination to build a goal-driven path, and the ability to create influence and trust to motivate allies to walk with you in that direction! You know how to inspire, develop, and manage conflicts in teams, finding solutions and fostering growth. We truly believe that more women in leadership positions improve the work environment for ALL people, so we count on you for this mission. Thank you for being the woman you are; we know it wasn’t easy to get to where you are today, and you have an immense list of significant challenges that you have faced and continue to face. So now we want to ask you to take 30 seconds to breathe, valuing the leadership you have built within yourself! May you now feel proud of your journey and strength! May this breath renew your energy to reach even higher, achieving all the dreams you aspire to! To inspire you on this journey, in our app, we have great leaders from Latin America sharing openly about the challenges they faced in their leadership roles and how they overcame those obstacles. Don’t miss watching Flávia Bittencourt: CEO of Adidas Latin America, Fiama Zariffe: CEO of Airbnb, Daniela Ota: CEO of Dior, and Beatriz Bottesi: CMO of Meta Latin America, among many other inspiring women in our app, who openly discuss topics that you will surely relate to. We are all here rooting for your growth and success!'
    },
    assessment_opt_in: {
      title: 'Step',
      title1: 'back',
      title2: 'Registration - Corporate Plan',
      title3: 'Sign up now to access the platform and be part of this movement.',
      title4: 'Name',
      title5: 'Email',
      title6: 'Received by email',
      title7: 'Activation Code',
      title8: 'Received by email',
      title9: 'Mobile Number',
      title10: 'Create a Password',
      title11: 'Confirm Password',
      title12: 'I identify as a woman*',
      title13: 'I accept the Terms of Use and Privacy Policy*',
      title14: 'I accept the Terms of Use and Privacy Policy of Todas Group and JNJ*',
      title15: 'I accept the Terms of Use and Privacy Policy of Todas Group and NTT*',
      title16: 'By signing up, you agree to our',
      title17: 'Terms of Use',
      title18: '. In addition,',
      title19: ' you accept Data Sharing for demographic mapping purposes',
      title20: ' and agree to receive news and promotions.',
      title21: 'Privacy Policy',
      title22: 'Sign Up',
      title23: 'The required field',
      title24: 'The activation code must have at least 6 digits',
      title25: 'Complete your Profile!',
      title26: 'Fill in some information about your company and your position',
      title27: 'Company',
      title28: 'Vice Presidency',
      title29: 'Select your business unit',
      title30: 'Current Position',
      title31: 'Enter the link to your profile',
      title32: 'Finish',
      title33: 'Document number',
      title34: 'Start your journey at Todas!',
      title35: 'Select an option to join the platform',
      title36: 'Corporate Plan',
      title37: 'To use the benefit offered by your company',
      title38: 'Individual Plan',
      title39: 'Join our community as an individual',
      title40: 'Already have an account?',
      title41: 'Welcome to the future of female leadership!',
      title42: ' Ready to boost your career? For that, we need some information from you. Don’t worry,',
      title43: 'it will only take 10 minutes of your time.',
      title44: 'Let’s go',
      title45: '',
      title46: '',
      title47: '',
      title48: '',
      title49: '',
      title50: ''
    },

    networks: {
      title1: 'No network',
      title2: 'No chat selected',
      title3: 'You have already watched',
      title4: 'Watch',
      title5: 'Would you like to go to the full content of the request?',
      title6: ' Go to the full content',
      title7: 'Cancel',
      title8: 'Your request for Network is pending response',
      title9: 'is requesting Network for this content',
      title10: 'We are looking for the ideal profile for this Network',
      title11: 'Accept',
      title12: 'accepted your Network request for the content',
      title13: 'You accepted the Network request from',
      title14: 'for this content',
      title15: 'Start Network',
      title16: 'Delete Network',
      title17: 'Do you want to delete this Network request?',
      title18: 'Delete',
      title19: 'Cancel',
      title20: 'Your request has been accepted',
      title21: 'Awaiting response',
      title22: 'Network request accepted',
      title23: 'Network request',
      title24: 'Details',
      title25: 'Content',
      title26: 'Start',
      title27: 'Accept',
      title28: '',
      title29: '',
      title30: ''
    },
    todas: {
      title1: 'Create a post',
      title2: 'Anonymous user',
      title3: 'responses',
      title4: 'Work',
      title5: 'Family',
      title6: 'Health',
      title7: 'Leisure',
      title8: 'Harassment',
      title9: 'Title',
      title10: 'Description',
      title11: 'Send',
      title12: 'Delete',
      title13: 'Send as an anonymous user?',
      title14: 'Yes',
      title15: 'No',
      title16: 'Select the theme',
      title17: '',
      title18: '',
      title19: '',
      title20: ''
    },
    home: {
      message: 'Hello World!'
    },
    faq: {
      whatsSpan1: 'Support via WhatsApp',
      whatsSpan2: 'Start a conversation on WhatsApp',
      whatsSpan3: 'Frequently Asked Questions',
      whatsTxT: 'Did you try to find your question in “Frequently Asked Questions” and didn’t find what you were looking for? Contact our support:',
      message: 'No questions registered',
      question_1: 'What is Todas Group?',
      question_2: 'How do I download the Todas app?',
      question_3: 'What content will I find on the platform?',
      question_4: 'How much does Todas Group cost?',
      question_5: 'Do the courses provide certificates?',
      question_6: 'How does the women’s community work?',
      question_7: 'How do I cancel my subscription?',
      answer_1: '<p>We are a digital platform that democratizes access to highly inspiring leaders to boost women\'s professional journeys.</p>\r\n\r\n<p>The Todas app was developed to provide an unprecedented knowledge path based on the skills of the most successful women in Brazil and around the world.</p>\r\n\r\n<p>Classes and exercises are delivered through an innovative methodology designed to help you develop a success mindset in just a few weeks.</p>\r\n\r\n<p>We also have a community full of women to exchange experiences, as well as gamification to facilitate learning and rewards for your performance.</p>',
      answer_2: '<p><a href="https://apps.apple.com/br/app/todas-group/id1564341168" rel="noopener" target="_blank">iOS</a></p>\r\n\r\n<p><a href="https://play.google.com/store/apps/details?id=com.todasgroup&amp;hl=pt" rel="noopener" target="_blank">GooglePlay</a></p>',
      answer_3: '<p>You will have access to our knowledge path with essential skills for your professional development, each skill consists of video classes and practical exercises.</p>\r\n\r\n<p>Don’t worry, everything has been designed with the modern woman’s routine in mind; classes are quick and dynamic!</p>\r\n\r\n<p>Additionally, you will have access to Todas Books with audio summaries and talks from internationally recognized female leaders.</p>',
      answer_4: '<p>Annual upfront – R$2,600.00<br />\r\nAnnual installment – 12x of R$260.00</p>',
      answer_5: '<p>Upon completing each skill, you will receive a certificate related to it, and you can even share it on your LinkedIn!</p>',
      answer_6: '<p>Our community consists of diverse women, each with various challenges and professional experiences.</p>\r\n\r\n<p>Through #TodasJuntas, you will have the opportunity to share doubts and challenges; within 48 hours, you will receive a response.</p>',
      answer_7: '<p><strong>iOS:</strong>&nbsp;To cancel your subscription via the App Store, follow the steps below:</p>\r\n\r\n<p>1- Go to “Settings”</p>\r\n\r\n<p>2- Click on your name</p>\r\n\r\n<p>3- Click on “Subscriptions”</p>\r\n\r\n<p>4- Click on your Todas subscription</p>\r\n\r\n<p>5- Click on “Cancel Subscription”</p>\r\n\r\n<p><strong>Android:</strong>&nbsp;To cancel your subscription via the Play Store, follow the steps below:</p>\r\n\r\n<p>1- Go to the Play Store</p>\r\n\r\n<p>2- In the top corner, click on your profile picture</p>\r\n\r\n<p>3- Click on “Payments and Subscriptions”</p>\r\n\r\n<p>4- Click on “Subscriptions”</p>\r\n\r\n<p>5- Select your Todas subscription</p>\r\n\r\n<p>6- Click on “Cancel subscription”</p>'
    },
    PageNotFound: {
      title: 'Page Not Found',
      p: 'Try our other content'
    },
    sign_up: {
      title1: 'Register Now',
      title2: 'Full Name',
      title3: 'E-mail',
      title4: 'Mobile',
      title5: 'Password',
      title6: 'Confirm Password',
      title7: 'I recognize myself as a woman',
      title8: 'Register',
      title9: 'Registering',
      title10: 'Back to Login',
      title11: 'The ',
      title12: 'lessons and guidance that great leaders in Brazil',
      title13: 'have learned over many years, extensive specializations, and diverse experiences.',
      title14: 'Now available for you!',
      title15: 'You grow, Todas grows!',
      title16: 'Be Part of It!',
      title17: '',
      title18: '',
      title19: '',
      title20: ''
    },
    recovery: {
      title1: 'I Forgot My Password',
      title2: 'E-mail',
      title3: 'Send Code',
      title4: 'Sending',
      title5: 'Login',
      title6: 'Recover Password',
      title7: 'Code',
      title8: 'Password',
      title9: 'Confirm Password',
      title10: 'Recover',
      title11: 'Recovering',
      title12: '',
      title13: '',
      title14: '',
      title15: '',
      title16: '',
      title17: '',
      title18: '',
      title19: '',
      title20: ''
    }
  }
}
