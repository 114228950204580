import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const ModalBoxContext = createContext(null)

export const ModalBoxProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [content, setContent] = useState(null)
  const [title, setTitle] = useState(null)
  const [customModalClose, setCustomModalClose] = useState(null)

  const modalBox = (ctt, title) => {
    setOpen(true)
    setContent(ctt)
    setTitle(title)
  }

  const modalClose = () => {
    setOpen(false)
    setContent(null)
    setTitle(null)
  }

  return (
    <ModalBoxContext.Provider value={{
      open,
      setOpen,
      content,
      setContent,
      modalBox,
      customModalClose,
      setCustomModalClose,
      title,
      setTitle,
      modalClose
    }}
    >
      {children}
    </ModalBoxContext.Provider>
  )
}

ModalBoxProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useModalBox = () => {
  const context = useContext(ModalBoxContext)

  if (!context) throw new Error('useModalBox must be used within a provider ModalBoxProvider')

  return { ...context }
}
