import React from 'react'
import styled from 'styled-components'
import { IconStyled } from './default_styled'

export const QuestionIcon = (props) => {
  return (
    <QuestionStyled {...props}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.333 17.1158V18.6665C17.333 19.4038 16.737 19.9998 15.9997 19.9998C15.2623 19.9998 14.6663 19.4038 14.6663 18.6665V15.9998C14.6663 15.2625 15.2623 14.6665 15.9997 14.6665C17.1023 14.6665 17.9997 13.7692 17.9997 12.6665C17.9997 11.5638 17.1023 10.6665 15.9997 10.6665C14.897 10.6665 13.9997 11.5638 13.9997 12.6665C13.9997 13.4038 13.4037 13.9998 12.6663 13.9998C11.929 13.9998 11.333 13.4038 11.333 12.6665C11.333 10.0932 13.4263 7.99982 15.9997 7.99982C18.573 7.99982 20.6663 10.0932 20.6663 12.6665C20.6663 14.7732 19.253 16.5372 17.333 17.1158ZM15.9998 23.9998C15.2625 23.9998 14.6665 23.4038 14.6665 22.6665C14.6665 21.9292 15.2625 21.3332 15.9998 21.3332C16.7372 21.3332 17.3332 21.9292 17.3332 22.6665C17.3332 23.4038 16.7372 23.9998 15.9998 23.9998ZM15.9998 2.6665C8.64784 2.6665 2.6665 8.64784 2.6665 15.9998C2.6665 23.3518 8.64784 29.3332 15.9998 29.3332C23.3518 29.3332 29.3332 23.3518 29.3332 15.9998C29.3332 8.64784 23.3518 2.6665 15.9998 2.6665Z" fill="#9D9D9D" />
      </svg>
    </QuestionStyled>
  )
}

const QuestionStyled = styled(IconStyled)``
