import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Box, Button, Flex, Image } from '../ui/components'
import { Icon } from '../../assets/icons'
import { customTheme } from '../../styles/theme'
import { ImageHelper } from '../../helpers/image-helper/image-helper'
import { useAlertBox, useModalBox } from '../../contexts'
import ReactEasyCrop from '../../adapts/react-easy-crop/react-easy-crop'
import { setUser } from '../../utils/storage/user'
import { Col, Row } from '../ui/layout'

const AvatarUpload = ({ user, uploadAvatar }) => {
  const history = useHistory()
  const { modalBox, setOpen, setContent, setCustomModalClose } = useModalBox()
  const { alertBox } = useAlertBox()
  const initialValues = {
    image: null,
    showModal: false,
    crop: {
      x: 0,
      y: 0
    },
    zoom: 1,
    aspect: 1,
    rotation: 0,
    croppedAreaPixels: null,
    preview: null
  }
  const [state, setState] = useState(initialValues)

  const handleCustomModalClose = () => {
    setState(initialValues)
  }

  useEffect(() => {
    setCustomModalClose(handleCustomModalClose)
  }, [])

  const handleCropChange = (crop) => {
    setState(old => ({ ...old, crop }))
  }

  const handleZoomChange = (zoom) => {
    setState(old => ({ ...old, zoom }))
  }

  const handleRotationChange = (rotation) => {
    setState(old => ({ ...old, rotation }))
  }

  const handleCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setState(prev => ({
      ...prev,
      croppedAreaPixels
    }))
  }, [])

  const handleFileChange = async (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length) {
      const file = event.currentTarget.files[0]
      const imageHelper = new ImageHelper({ file })
      const image = await imageHelper.build()

      setState(prev => ({
        ...prev,
        image,
        showModal: true
      }))
    }
  }

  const handleSliderZoom = (event) => {
    handleZoomChange(Number(event.target.value))
  }

  const handleSliderRotate = (event) => {
    handleRotationChange(Number(event.target.value))
  }

  const onSubmitImage = async (image) => {
    try {
      const file = new File([image], `${new Date().getTime()}.jpg`, { type: 'image/jpeg' })
      const data = new FormData()
      data.append('image', file)

      const { url } = await uploadAvatar.updateAvatar(data)
      return url
    } catch (error) {
      throw new Error('Ocorreu um erro ao fazer o upload da imagem')
    }
  }

  const handleUpload = async () => {
    try {
      const imageHelper = new ImageHelper({
        image: state.image,
        pixelCrop: state.croppedAreaPixels,
        rotation: state.rotation
      })
      const croppedImage = await imageHelper.croppedImage()
      const avatar = await onSubmitImage(croppedImage)

      setOpen(false)
      setContent(null)

      const uploadUser = {
        info: user.info,
        user: {
          ...user.user,
          avatar: avatar
        }
      }
      await setUser(uploadUser)
      setState({
        ...initialValues,
        preview: avatar
      })
      history.go()
    } catch (_) {
      alertBox('Ocorreu um erro ao salvar a imagem')
    }
  }

  const CroppedImage = () => {
    return (
      <Box>
        <CroppedBody>
          <ReactEasyCrop
            image={state.image}
            crop={state.crop}
            zoom={state.zoom}
            aspect={state.aspect}
            rotation={state.rotation}
            onCropChange={handleCropChange}
            onZoomChange={handleZoomChange}
            onRotationChange={handleRotationChange}
            onCropComplete={handleCropComplete}
          />
        </CroppedBody>

        <Row>
          <Col xs={12} md={6}>
            <CustomFlex alignItems="center" mb={5}>
              <Box mr={15} w={50} textAlign="right">Zoom:</Box>
              <input type="range" value={state.zoom} min={1} max={5} step={0.1} aria-labelledby="Zoom" onChange={handleSliderZoom} />
            </CustomFlex>
          </Col>
          <Col xs={12} md={6}>
            <CustomFlex alignItems="center" mb={5}>
              <Box mr={15} w={50} textAlign="right">Girar:</Box>
              <input type="range" value={state.rotation} min={0} max={360} step={1} aria-labelledby="Girar" onChange={handleSliderRotate} />
            </CustomFlex>
          </Col>
          <Col xs={12} md={4} ml="auto">
            <Button onClick={() => handleUpload()}>Salvar imagem</Button>
          </Col>
        </Row>
      </Box>
    )
  }

  useEffect(() => {
    if (state.showModal && state.image) {
      modalBox(CroppedImage)
    }
  }, [state])

  return (
    <CustomBox mx="auto" pos="relative" mb={20}>
      <Avatar src={state.preview || user.user.avatar} alt={user.user.name} />
      <IconCustom>
        <Icon name="camera" />
        <AvatarFile type="file" onChange={handleFileChange} />
      </IconCustom>
    </CustomBox>
  )
}

AvatarUpload.propTypes = {
  user: PropTypes.object.isRequired,
  uploadAvatar: PropTypes.object.isRequired
}

export default AvatarUpload

const CustomBox = styled(Box)`
  width: 100%;
  aspect-ratio: 1/1;
  margin-bottom: -70px;
`

const Avatar = styled(Image)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

const AvatarFile = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
`

const IconCustom = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${customTheme.colors.pink['600']};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  overflow: hidden;

  svg {
    color: #ffffff;
    max-width: 25px;
  }
`

const CroppedBody = styled.div`
  position: relative;
  overflow: hidden;
  height: 240px;
  margin-bottom: 15px;
  border-radius: 5px;
`

const CustomFlex = styled(Flex)`
  input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    height:5px;
    width:100%;
    cursor: pointer;
    background: ${customTheme.colors.pink['600']};
    margin-bottom: 10px;
  }
  
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 2px solid ${customTheme.colors.pink['600']};
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background: #fff;
    cursor: pointer;
    margin-top: 0;
  }
  
  input[type=range]:focus {
    outline: none;
  }
`
