import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import momentPT from 'moment/locale/pt-br'
// import Avatar from '../../../ui/layout/avatar/avatar'
import { Icon } from '../../../../assets/icons'
import { getUser } from '../../../../utils/storage/user'
import { Box, Button } from '../../components'

const CommentItem = ({ comment, deleteCommentary, editeCommentary }) => {
  moment.updateLocale('pt-br', momentPT)
  const [editedCommentary, setEditedCommentary] = useState(comment.text)
  const [optionsSubOpen, setOptionsSubOpen] = useState(false)
  const [editSubActive, setEditSubActive] = useState(false)
  const { user } = getUser()

  const handleEdit = () => {
    setEditSubActive(true)
    setOptionsSubOpen(false)
  }

  return (
    <CommentItemStyled>
      <div className="head">
        {/* {comment.user.avatar
          ? <Icon name="user" />
          : <Avatar src={comment.user.avatar} alt={comment.user.name} />} */}
        <Icon name="user" />

        <h3>{comment.user.name}</h3>
        <span>{moment(comment.createdAt).format('D MMM YYYY')}</span>

        {user['@id'] === comment.user['@id'] &&
          <div className="options">
            <button onClick={() => setOptionsSubOpen(!optionsSubOpen)}>...</button>
            {optionsSubOpen &&
              <div className="options-list">
                <button onClick={handleEdit}>Editar</button>
                <button onClick={() => deleteCommentary(comment.id)}>Excluir</button>
              </div>}
          </div>}
      </div>
      {editSubActive
        ? (
          <CommentEdit>
            <textarea
              value={editedCommentary}
              onChange={(e) => setEditedCommentary(e.target.value)}
            />
            <Box w="30%">
              <Button onClick={() => editeCommentary(comment.id, editedCommentary)}>Atualizar</Button>
            </Box>
          </CommentEdit>
          )
        : <p>{comment.text}</p>}
    </CommentItemStyled>
  )
}

export default CommentItem

CommentItem.propTypes = {
  comment: PropTypes.any,
  deleteCommentary: PropTypes.func,
  editeCommentary: PropTypes.func
}

const CommentItemStyled = styled.div`
  background: #fdf9f7;
  border: solid #D2B5B4 1px;
  border-left: solid #D2B5B4 10px;
  padding: 15px;
  margin-bottom: 5px;
  min-height: 100px;

  .head {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    position: relative;

    h3 {
      font-family:  Open Sans;
      font-weight: bold;
      font-size: 16px;
      margin: 0 7px;
    }

    span {
      color: #AE7576;
      font-size: 12px;
    }

    path {
      fill: #AE7576;
    }

    .options {
      position: absolute;
      right: 0;
      top: -10px;

      button {
        background: none;
        border: none;
        font-weight: 800;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 20px;
        padding: 0;
        margin: 0;
        color: #676767;
      }

      .options-list {
        display: flex;
        position: absolute;
        background: #f1f3f4;
        right: 0;
        box-shadow: 0 4px 22px rgb(0 0 0 / 15%);
        padding: 5px;
        flex-direction: column;
        align-items: flex-start;
        top: 120%;
        
        button {
          font-size: 14px;
          padding: 15px 5px;
          font-weight: normal;
          display: flex;
        }
      }
    }
  }

  p {
    margin: 0;
    font-size: 14px;
  }
`

const CommentEdit = styled.div`
  textarea {
    width: 100%;
    font-size: 14px;
    height: 100px;
    padding: 10px;
    border: 1px solid #616161;
    border-radius: 5px;
  }
`
