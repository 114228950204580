import React from 'react'
import styled from 'styled-components'
import SessionOne from './components/session-one'
import SessionTwo from './components/session-two'
import SessionThree from './components/session-three'
import SessionFour from './components/session-four'

const SideStepArray = [{
  id: '1',
  title: 'Passo 1',
  active: true

},

{
  id: '2',
  title: 'Passo 2',
  active: false

},
{
  id: '3',
  title: 'Passo 3',
  active: false

},
{
  id: '4',
  title: 'Passo 4',
  active: false

}

]

const StepOne = () => {
  const [items, setItems] = React.useState(SideStepArray)
  const [selectSession, setSelectSession] = React.useState({ id: '1' })

  const handleItemClick = (id) => {
    const updatedItems = items.map(item => {
      return {
        ...item,
        active: item.id === id
      }
    })

    const findSession = updatedItems.find(item => item.id === id)
    setSelectSession(findSession)
    setItems(updatedItems)
  }

  return (
    <Container>
      <HeaderTitle>Pesquisa Perfilática</HeaderTitle>
      <NormalText>Essa pesquisa irá identificar os pontos a serem trabalhados nos próximos encontros, alinhados aos seus objetivos e perfil como mentorada.</NormalText>
      {/* <NormalTextGray>Sua coach personalizará as sessões de acordo com os resultados para seu desenvolvimento profissional.</NormalTextGray> */}

      <Content>
        {items.map((item) => {
          return (
            <StepBtn
              key={item.id} onClick={() => {
                handleItemClick(item.id)
              }} active={item.active}
              disabled={item.disabled}
            >
              <Title active={item.active}>{item.title}</Title>
            </StepBtn>
          )
        })}

      </Content>

      <SubContent>
        {selectSession?.id === '1' && <SessionOne />}
        {selectSession?.id === '2' && <SessionTwo />}
        {selectSession?.id === '3' && <SessionThree />}
        {selectSession?.id === '4' && <SessionFour />}
      </SubContent>
    </Container>
  )
}

export default StepOne

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:13px;
  margin-top: 30px;
  @media(max-width: 820px) {
  // padding: 30px;
  
  }

`

const HeaderTitle = styled.h4`
  font-size: 25.6px;
  font-weight: 400;
  color: #121214;
  line-height: 25.6px;
  font-family: "Playfair Display","Roboto","Open Sans",sans-serif;
`

const NormalText = styled.p`
  font-size: 16px;
  font-weight:500;
  color: #e14b2b;
  line-height: 22.4px;
  font-family: "Roboto","Open Sans",sans-serif;`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  min-width: 60%;
  align-items: center;
  gap: 10px;
  justify-content: space-around;
  
`
const SubContent = styled.div`
  width: 100%;
  display: flex;
  /* flex-direction: column;
  gap:10px; */
  margin-top: 30px;


`

const StepBtn = styled.button`
display: flex;
opacity: ${({ disabled }) => disabled ? 0.5 : 1};
border: none;
width: 100px;
min-width: 35%;
max-width: 100px;
height: 50px;
align-items: center;
justify-content: center;
border-radius: 7px;

cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
background-color: ${({ active }) => active ? '#e14b2b' : '#ededed'}; 
:hover {
  background-color: ${({ active }) => active ? '#e14b2b' : '#ededed'};
}

`

const Title = styled.p`
  color:${({ active }) => active ? 'white' : '#e14b2b'};
  font-size: 16px;
  font-weight: ${({ active }) => active ? 'bold' : '600'};
  margin-top: 10px;
 

`
