import { getOrientation } from 'get-orientation/browser'
import { FormHelper } from '../form-helper/form-helper'

export class ImageHelper {
  #file
  #image
  #rotation = 0
  #pixelCrop = null

  #ORIENTATION_TO_ANGLE = {
    3: 180,
    6: 90,
    8: -90
  }

  constructor ({ file, image, rotation, pixelCrop }) {
    this.#file = file
    this.#image = image
    this.#rotation = rotation
    this.#pixelCrop = pixelCrop
  }

  createImage () {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error) => reject(error))
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = this.#image
    })
  }

  async rotationImage () {
    const image = await this.createImage()
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    const orientationChanged = [90, -90, 270, -270].includes(this.#rotation)

    canvas.width = orientationChanged ? image.height : image.width
    canvas.height = !orientationChanged ? image.height : image.width

    context.translate(canvas.width / 2, canvas.height / 2)
    context.rotate(this.#rotation * Math.PI / 180)
    context.drawImage(image, -image.width, -image.height)

    return new Promise(resolve => canvas.toBlob(file => resolve(URL.createObjectURL(file)), 'image/jpeg'))
  }

  radianAngle () {
    return (this.#rotation * Math.PI) / 180
  }

  async croppedImage () {
    const image = await this.createImage()
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * (maxSize / 2) * Math.sqrt(2)

    canvas.width = safeArea
    canvas.height = safeArea

    context.translate(safeArea / 2, safeArea / 2)
    context.rotate(this.radianAngle())
    context.translate(-safeArea / 2, -safeArea / 2)

    context.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
    const data = context.getImageData(0, 0, safeArea, safeArea)

    canvas.width = this.#pixelCrop.width
    canvas.height = this.#pixelCrop.height

    context.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - this.#pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - this.#pixelCrop.y
    )

    return new Promise(resolve => canvas.toBlob(file => resolve(file), 'image/jpeg'))
  }

  async build () {
    const orientation = await getOrientation(this.#file)
    this.#image = await FormHelper.readFile(this.#file)
    this.#rotation = this.#ORIENTATION_TO_ANGLE[orientation]

    if (!this.#rotation) return this.#image

    return await this.rotationImage()
  }
}
