import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { customTheme } from '../../../../styles/theme'

const newSize = (source, number) => {
  const num = Number(source.replace(/[A-z]/g, '') - number)
  return `${num}rem`
}

const renderTypography = (attr) => {
  switch (attr) {
    case 'h1':
    case 'h2':
      return css`
        position: relative;
        margin-bottom: 30px;
        font-size: ${newSize(customTheme.fontSizes.lg, 0.350)};
        
        @media (min-width: ${customTheme.breakpoints[0]}) {
          font-size: ${customTheme.fontSizes.lg};
        }
        color: ${customTheme.colors.purple};
        line-height: 2.4rem;
        font-weight: 400;
        text-align: center;
      `
    case 'h3': return css`
      font-family: ${customTheme.fonts.body};
      font-weight: bold;
      font-size: ${customTheme.fontSizes.md};
      line-height: 1.375rem;
      color: ${customTheme.colors.blue['700']};
    `
    default: return css`
      font-size: ${customTheme.fontSizes.md};
      color: ${customTheme.colors.blue['700']};
    `
  }
}

export const Typography = styled.div.attrs(props => ({
  as: props.attr
}))`
  ${(props) => renderTypography(props.attr)}
  ${({ bar }) => bar && css`
    &:after {
      content: '';
      background: ${customTheme.colors.pink['600']};
      height: 3px;
      width: 43px;
      display: flex;
      margin: 7px auto 0;
    }
`}
`

Typography.defaultProps = {
  attr: 'div'
}

Typography.propTypes = {
  attr: PropTypes.oneOf(['div', 'p', 'h1', 'h2', 'h3', 'h4', 'h5'])
}
