import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { customTheme } from '../../styles/theme'

const FaqItem = ({ content }) => {
  const [open, setOpen] = useState(false)
  return (
    <PerguntaBox>
      <Pergunta open={open} onClick={() => setOpen(!open)}>
        <h4>{content.question}</h4>
        <div />
      </Pergunta>
      <Resposta open={open}>
        <div dangerouslySetInnerHTML={{ __html: content.answer }} />
      </Resposta>
    </PerguntaBox>
  )
}

FaqItem.propTypes = {
  content: PropTypes.any
}

export default FaqItem

const PerguntaBox = styled.div`
  width: 100%;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 15px;
`

const Pergunta = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: ${props => props.open ? '#fff' : customTheme.colors.purple};
  background: ${props => !props.open ? '#fff' : customTheme.colors.purple};
  position: relative;
  cursor: pointer;

  >div {
    border-left: 3px #fff solid;
    border-top: 3px #fff solid;
    border-color: ${props => props.open ? '#fff' : customTheme.colors.purple};;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: ${props => props.open ? 'translateY(-80%) rotate(225deg)' : 'translateY(-50%) rotate(45deg)'};
    width: 10px;
    height: 10px;
  }
`

const Resposta = styled.div`
  height: ${props => props.open ? 'auto' : '0'};
  padding: ${props => props.open ? '15px' : '0'};
  overflow: hidden;
  color: ${customTheme.colors.purple};
`
