export class Enum {
  static frequency (str) {
    switch (str) {
      case 'daily': return 'Diário'
      case 'weekly': return 'Semanal'
      case 'monthly': return 'Mensal'
      case 'yearly': return 'Anual'
      case 'quarterly': return 'Trimestral'
      case 'semiannual': return 'Semestral'
      default: return str
    }
  }

  static status (str) {
    switch (str) {
      case 'created': return 'Criado'
      case 'active': return 'Ativo'
      case 'canceled': return 'Cancelado'
      case 'future': return 'Trial'
      case 'expired': return 'Expirado'
      default: return str
    }
  }

  static billingStatus (str) {
    switch (str) {
      case 'waiting': return 'Esperado'
      case 'review': return 'Revisão'
      case 'pending': return 'Pendente'
      case 'paid': return 'Pago'
      case 'canceled': return 'Cancelado'
      case 'scheduled': return 'Agendado'
      default: return str
    }
  }

  static paymentProfile (str) {
    switch (str) {
      case 'active': return 'Ativo'
      case 'canceled': return 'Cancelado'
      default: return str
    }
  }

  static customers (str) {
    switch (true) {
      case /city/ig.test(str): return str.replace(/city/ig, 'Cidade')
      case /company/ig.test(str): return str.replace(/company/ig, 'Empresa')
      case /companyIdentity/ig.test(str): return str.replace(/companyIdentity/ig, 'CNPJ')
      case /companyJobRole/ig.test(str): return str.replace(/companyJobRole/ig, 'Cargo')
      case /country/ig.test(str): return str.replace(/country/ig, 'País')
      case /complement/ig.test(str): return str.replace(/complement/ig, 'Complemento')
      case /foreignAddress/ig.test(str): return str.replace(/foreignAddress/ig, 'Endereço estrangeiro')
      case /foreign/ig.test(str): return str.replace(/foreign/ig, 'Estrangeiro')
      case /professionalGoal/ig.test(str): return str.replace(/professionalGoal/ig, 'Objetivo profissional')
      case /goal/ig.test(str): return str.replace(/goal/ig, 'Objetivo no Todas Group')
      case /houseNumber/ig.test(str): return str.replace(/houseNumber/ig, 'Número')
      case /identity/ig.test(str): return str.replace(/identity/ig, 'CPF')
      case /name/ig.test(str): return str.replace(/name/ig, 'Nome completo')
      case /neighborhood/ig.test(str): return str.replace(/neighborhood/ig, 'Bairro')
      case /nickname/ig.test(str): return str.replace(/nickname/ig, 'Como gostaria de ser chamado')
      case /phoneNumber/ig.test(str): return str.replace(/phoneNumber/ig, 'Celular')
      case /state/ig.test(str): return str.replace(/state/ig, 'Estado')
      case /street/ig.test(str): return str.replace(/street/ig, 'Rua')
      case /zipCode/ig.test(str): return str.replace(/zipCode/ig, 'CEP')
      default: return str
    }
  }
}

export const tagsNotes = [
  {
    value: 'relevant',
    label: 'Importante',
    fontColor: '#9B51E0',
    backgroundColor: '#fff'
  },
  {
    value: 'inspirational_phrase',
    label: 'Frase inspiradora',
    fontColor: '#EB5757',
    backgroundColor: '#f4e0df'
  },
  {
    value: 'write_later',
    label: 'Anotar mais tarde',
    fontColor: '#F2994A',
    backgroundColor: '#f6e9df'
  }
]
