import React from 'react'
import styled from 'styled-components'
import { IconStyled } from '../default_styled'

export const IconDOCX = (props) => {
  return (
    <DocxStyled {...props}>
      <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2031 4.45312L13.5469 0.796874C13.1133 0.36328 12.2461 -1.43051e-06 11.625 -1.43051e-06H1.125C0.503906 -1.43051e-06 0 0.503906 0 1.125V19.875C0 20.4961 0.503906 21 1.125 21H16.875C17.4961 21 18 20.4961 18 19.875V6.375C18 5.75391 17.6367 4.88672 17.2031 4.45312ZM12 1.59375C12.1992 1.66406 12.3984 1.76953 12.4805 1.85156L16.1484 5.51953C16.2305 5.60156 16.3359 5.80078 16.4063 6H12V1.59375ZM16.5 19.5H1.5V1.5H10.5V6.375C10.5 6.99609 11.0039 7.5 11.625 7.5H16.5V19.5ZM2.73047 9H6.24609V10.2539H5.19141L6.35156 15.3867C6.39844 15.5742 6.42188 15.7734 6.43359 15.9258L6.45703 16.1719H6.50391L6.55078 15.9258C6.58594 15.7734 6.59766 15.5742 6.64453 15.3867L8.33203 9H9.66797L11.3555 15.3867C11.4023 15.5742 11.4258 15.7734 11.4609 15.9258C11.4727 16.0078 11.4961 16.1016 11.4961 16.1719H11.543L11.5664 15.9258C11.5781 15.7734 11.6016 15.5742 11.6484 15.3867L12.8086 10.2539H11.7539V9H15.2695V10.2539H14.4492L12.5273 18H10.6641L9.16406 12.3164C9.10547 12.1289 9.09375 11.9414 9.05859 11.7773L9.02344 11.4961H8.97656C8.97656 11.5898 8.96484 11.6836 8.95313 11.7773C8.92969 11.9648 8.89453 12.1406 8.83594 12.3164L7.33594 18H5.47266L3.55078 10.2539H2.73047V9Z" fill="#4F4F4F"/>
      </svg>
    </DocxStyled>
  )
}

const DocxStyled = styled(IconStyled)`
  margin: 0 5px;
`
