import React from 'react'
import PropTypes from 'prop-types'
import { AngleDownLight } from './angle_down_light'
import { AngleLeftLight } from './angle_left_light'
import { AngleRightLight } from './angle_right_light'
import { Heart, HeartFull } from './heart'
import { MinusCircleLight } from './minus_circle_light'
import { PlusCircleLight } from './plus_circle_light'
import { Comma } from './comma'
import { Goblet } from './goblet'
import { Book } from './book'
import { Compass } from './compass'
import { Balloons } from './balloons'
import { Diamond } from './diamond'
import { IconPDF } from './files/pdf'
import { IconDOCX } from './files/docx'
import { IconZIP } from './files/zip'
import IconSocial from './social/icon-social'
import IconSvg from './svg/icon-svg'
import PaymentIcon from './payment'

export const Icon = ({ name, ...rest }) => {
  switch (name) {
    case 'angle-down-light': return <AngleDownLight {...rest} />
    case 'angle-left-light': return <AngleLeftLight {...rest} />
    case 'angle-right-light': return <AngleRightLight {...rest} />
    case 'heart-full': return <HeartFull {...rest} />
    case 'heart': return <Heart {...rest} />
    case 'minus-circle-light': return <MinusCircleLight {...rest} />
    case 'plus-circle-light': return <PlusCircleLight {...rest} />
    case 'comma': return <Comma {...rest} />
    case 'goblet-svg': return <Goblet {...rest} />
    case 'book': return <Book {...rest} />
    case 'ballons': return <Balloons {...rest} />
    case 'diamond': return <Diamond {...rest} />

    case 'facebook': return <IconSocial name="facebook" {...rest} />
    case 'twitter': return <IconSocial name="twitter" {...rest} />
    case 'instagram': return <IconSocial name="instagram" {...rest} />
    case 'linkedin': return <IconSocial name="linkedin" {...rest} />
    case 'youtube': return <IconSocial name="youtube" {...rest} />

    case 'card': return <IconSvg name="card" {...rest} />
    case 'payment': return <PaymentIcon />
    case 'heart-svg': return <IconSvg name="heart" {...rest} />
    case 'logout': return <IconSvg name="logout" {...rest} />
    case 'notepad': return <IconSvg name="notepad" {...rest} />
    case 'pencil': return <IconSvg name="pencil" {...rest} />
    case 'unlock': return <IconSvg name="unlock" {...rest} />
    case 'camera': return <IconSvg name="camera" {...rest} />
    case 'search': return <IconSvg name="search" {...rest} />
    case 'user': return <IconSvg name="user" {...rest} />
    case 'menu': return <IconSvg name="menu" {...rest} />
    case 'home-svg': return <IconSvg name="home" {...rest} />
    case 'ballons-svg': return <IconSvg name="ballons" {...rest} />
    case 'gift-svg': return <IconSvg name="gift" {...rest} />
    case 'chat-ballon': return <IconSvg name="chat-ballon" {...rest} />

    case 'eye-svg': return <IconSvg name="eye" {...rest} />
    case 'star-svg': return <IconSvg name="star" {...rest} />
    case 'flag-svg': return <IconSvg name="flag" {...rest} />

    case 'book-svg': return <IconSvg name="book" {...rest} />
    case 'community-svg': return <IconSvg name="community" {...rest} />
    case 'diamond-svg': return <IconSvg name="diamond" {...rest} />
    case 'jobs-svg': return <IconSvg name="jobs" {...rest} />
    case 'potencia': return <IconSvg name="potencia" {...rest} />
    case 'compass-svg': return <Compass {...rest} />

    case 'pdf': return <IconPDF {...rest} />
    case 'docx': return <IconDOCX {...rest} />
    case 'zip': return <IconZIP {...rest} />
    default: return null
  }
}

Icon.propTypes = {
  name: PropTypes.oneOf([
    'angle-down-light',
    'angle-left-light',
    'angle-right-light',
    'heart-full',
    'heart',
    'minus-circle-light',
    'plus-circle-light',
    'comma',
    'goblet',
    'book',
    'ballons',
    'diamond',

    'facebook',
    'twitter',
    'instagram',
    'linkedin',
    'youtube',

    'card',
    'heart-svg',
    'logout',
    'notepad',
    'pencil',
    'unlock',
    'camera',
    'search',
    'user',
    'menu',
    'ballons-svg',
    'book-svg',
    'community-svg',
    'diamond-svg',
    'jobs-svg',
    'compass-svg',

    'pdf',
    'docx',
    'zip'
  ]).isRequired
}
