import React from 'react'
import { BaseLayout } from '../../../presentation/components/layouts'
import { makeRemoteMenuFooter } from '../../usecases/menus/footer/menu-footer'
import { makeRemoteMenuDefault } from '../../usecases/menus/default/menu-default'

const makeBaseLayout = ({ children }) => (
  <BaseLayout
    menuFooter={makeRemoteMenuFooter()}
    menuDefault={makeRemoteMenuDefault()}
  >
    {children}
  </BaseLayout>
)

export default makeBaseLayout
