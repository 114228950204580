import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormInput, IcoView } from '../form'
import { Field } from 'formik'
import TplGroup from './tpl_group'

const InputPassword = ({ name, title, placeholder, isRequired, tabIndex, ...el }) => {
  const [viewPass, setViewPass] = useState(false)

  // const enableInput = event => {
  //   event.target.readOnly = false
  // }

  const handleChange = event => {
    if (el.setFieldValue) {
      el.setFieldValue(name, event.target.value.replace(/\s/g, ''))
    }
  }

  return (
    <TplGroup name={name} title={title} isRequired={isRequired}>
      <IcoView
        role="button"
        aria-label="Exibir conteúdo"
        className={viewPass && 'slash'}
        onClick={() => setViewPass(prev => !prev)}
      />
      <Field
        type={viewPass ? 'text' : 'password'}
        name={name}
        placeholder={placeholder || ''}
        tabIndex={tabIndex}
        onChange={handleChange}
        autoComplete="off"
        as={FormInput}
      />
    </TplGroup>
  )
}

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number
}
export default InputPassword
