import { DateHelper } from '../date-helper/date-helper'

export class CustomerHelper {
  #data

  constructor (data) {
    this.#data = data
  }

  static mapper (data) {
    return new CustomerHelper(data)
  }

  get () {
    if (this.#data['@type'] === 'Education') return this._educationMap()

    return this._workExperiencesMap()
  }

  _educationMap () {
    let subtitle = ''
    if (this.#data.degree && this.#data.fieldOfStudy) subtitle = this.#data.degree + ' • ' + this.#data.fieldOfStudy
    else if (this.#data.degree && !this.#data.fieldOfStudy) subtitle = this.#data.degree
    else if (this.#data.fieldOfStudy) subtitle = this.#data.fieldOfStudy

    return ({
      type: this.#data['@type'].toLowerCase(),
      title: this.#data.schoolName,
      subtitle,
      startAt: this.#data.startAt,
      endAt: this.#data.endAt,
      description: this.#data.description
    })
  }

  _workExperiencesMap () {
    return ({
      type: this.#data['@type'].toLowerCase(),
      title: this.#data.title,
      subtitle: this.#data.companyName,
      startAt: this.#data.startAt,
      endAt: this.#data.endAt,
      description: this.#data.description
    })
  }

  static _mapper (type, data) {
    switch (type) {
      case 'startAt':
      case 'endAt':
        return DateHelper.toInputDate(data)
      default: return data
    }
  }

  static setWorkExperienceFormData (data, experience, index) {
    return data.map(items => {
      return ({
        ...items,
        items: [
          {
            name: 'index',
            type: 'hidden',
            component: 'Input',
            value: index.toString()
          },
          ...items.items.map(item => {
            return ({
              ...item,
              ...(experience[item.name]) && { value: this._mapper(item.name, experience[item.name]) }
            })
          })]
      })
    })
  }
}
