import React from 'react'
import styled from 'styled-components'
import { IconStyled } from './default_styled'

export const ContentIcon = (props) => {
  return (
    <ContentIconStyled {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.9296 2C6.44845 2 2 6.48 2 12C2 17.52 6.44845 22 11.9296 22C17.4107 22 21.8592 17.52 21.8592 12C21.8592 6.48 17.4107 2 11.9296 2ZM9.94365 16.5001L15.9014 12.0001L9.94365 7.5001V16.5001ZM3.98591 11.9999C3.98591 16.4099 7.55063 19.9999 11.9296 19.9999C16.3085 19.9999 19.8732 16.4099 19.8732 11.9999C19.8732 7.5899 16.3085 3.9999 11.9296 3.9999C7.55063 3.9999 3.98591 7.5899 3.98591 11.9999Z" fill="#9D9D9D" />
      </svg>

    </ContentIconStyled>
  )
}

const ContentIconStyled = styled(IconStyled)``
