import { getImage } from '../../utils/image'

export class UsersHelper {
  #me

  constructor (me) {
    this.#me = me
  }

  static run (me) {
    return new UsersHelper(me)
  }

  build () {
    const {
      user,
      hasActiveSubscription,
      hasCustomerFolders,
      hasSubscription,
      hasTrialSubscription,
      hasMinimumAddress,
      hasMinimumCurriculum,
      unreadNetworks,
      unreadPosts
    } = this.#me

    return {
      user: {
        ...user,
        avatar: getImage(user.avatar)
      },
      info: {
        hasActiveSubscription,
        hasCustomerFolders,
        hasSubscription,
        hasTrialSubscription,
        hasMinimumAddress,
        hasMinimumCurriculum,
        unreadNetworks,
        unreadPosts
      }
    }
  }
}
