import React from 'react'
import PropTypes from 'prop-types'
// import VimeoVideo from './vimeo-video'
import VideoFile from './video-file'
import AudioFile from './audio-file'
import Loading from '../../../loading'
import { makeWatchContent } from '../../../../../main/usecases/contents/watch-content-factory'

const ContentMedia = ({ content, lastEpisodes, noNotes = false, onHandleFinish = () => { } }) => {
  let flag = false

  const onFinishContent = async (progress) => {
    try {
      await makeWatchContent().watch({
        percent: 100,
        content: `/v1/contents/${content.id}`
      })
      onHandleFinish()
    } catch (error) {
      console.error('ContentMedia.onFinishContent', error.message)
    }
  }

  const onListenContent = async (type, eventParams) => {
    const timePassed = parseInt(type === 'video' ? eventParams.seconds : eventParams.target.currentTime, 10)
    const timeToUpdate = 15

    if (timePassed % timeToUpdate === 0 && !flag && timePassed > 1) {
      flag = true

      try {
        await makeWatchContent().watch({
          progress: timePassed,
          content: content['@id']
        })
      } catch (error) {
        console.error(error.message)
      }
    } else if (timePassed % (timeToUpdate + 1) === 0 && flag && timePassed > 1) {
      flag = false
    }
  }

  const Medias = () => {
    switch (content['@type']) {
      case 'VimeoContent':
      case 'Skill':
        return (
          // <VimeoVideo
          //   reference={content.contentReference}
          //   onListenContent={onListenContent}
          //   onFinishContent={onFinishContent}
          // />
          <VideoFile
            contentId={content?.id}
            reference={content?.download[0]?.link}
            duration={content?.duration}
            type={content?.download[0]?.type}
            onListenContent={onListenContent}
            onFinishContent={onFinishContent}
            noNotes={noNotes}
            idVimeo={content.contentReference}
            previewReference={content?.previewReference}
            lastEpisodes={lastEpisodes}
          />
        )
      case 'AudioContent':
        return (
          <AudioFile
            reference={content.audioFile}
            onListenContent={onListenContent}
            onFinishContent={onFinishContent}
          />
        )
      case 'TextContent':
        return null
      default:
        return <Loading />
    }
  }

  return (
    <div className="medias">
      <Medias />
    </div>
  )
}

export default ContentMedia

ContentMedia.propTypes = {
  content: PropTypes.object.isRequired,
  noNotes: PropTypes.any,
  onHandleFinish: PropTypes.func,
  lastEpisodes: PropTypes.bool
}
