import React from 'react'
import styled from 'styled-components'
import { IconStyled } from './default_styled'

export const Calendar = (props) => {
  return (
    <CalendarStyled {...props}>
      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2305 18.0001H3.24242C2.38276 17.9991 1.55859 17.6571 0.950726 17.0492C0.342862 16.4414 0.000949257 15.6172 0 14.7575L0 4.76951C0.000949257 3.90985 0.342862 3.08567 0.950726 2.47778C1.55859 1.8699 2.38276 1.52796 3.24242 1.52698H13.2305C14.0902 1.52796 14.9144 1.8699 15.5222 2.47778C16.1301 3.08567 16.472 3.90985 16.473 4.76951V14.7575C16.472 15.6172 16.1301 16.4414 15.5222 17.0492C14.9144 17.6571 14.0902 17.9991 13.2305 18.0001ZM3.24242 2.6743C2.68695 2.67494 2.1544 2.8959 1.76163 3.28869C1.36886 3.68148 1.14794 4.21404 1.14732 4.76951V14.7575C1.14794 15.313 1.36886 15.8455 1.76163 16.2383C2.1544 16.6311 2.68695 16.8521 3.24242 16.8527H13.2305C13.786 16.8521 14.3186 16.6311 14.7113 16.2383C15.1041 15.8455 15.325 15.313 15.3256 14.7575V4.76951C15.325 4.21404 15.1041 3.68148 14.7113 3.28869C14.3186 2.8959 13.786 2.67494 13.2305 2.6743H3.24242Z" fill="#AE7576"/>
        <path d="M15.8994 5.82751H0.57373V6.97484H15.8994V5.82751Z" fill="#AE7576"/>
        <path d="M13.1474 4.70583C13.0721 4.70583 12.9975 4.691 12.9279 4.66217C12.8583 4.63334 12.795 4.59109 12.7417 4.53782C12.6885 4.48455 12.6462 4.4213 12.6174 4.3517C12.5886 4.2821 12.5737 4.2075 12.5737 4.13216V0.573663C12.5737 0.421518 12.6342 0.275604 12.7418 0.168022C12.8493 0.0604393 12.9952 0 13.1474 0C13.2995 0 13.4455 0.0604393 13.553 0.168022C13.6606 0.275604 13.7211 0.421518 13.7211 0.573663V4.13216C13.7211 4.2075 13.7062 4.2821 13.6774 4.3517C13.6486 4.4213 13.6063 4.48455 13.553 4.53782C13.4998 4.59109 13.4365 4.63334 13.3669 4.66217C13.2973 4.691 13.2227 4.70583 13.1474 4.70583Z" fill="#AE7576"/>
        <path d="M3.32537 4.70583C3.25004 4.70583 3.17544 4.691 3.10583 4.66217C3.03623 4.63334 2.97299 4.59109 2.91972 4.53782C2.86645 4.48455 2.82419 4.4213 2.79536 4.3517C2.76654 4.2821 2.7517 4.2075 2.75171 4.13216V0.573663C2.75171 0.421518 2.81215 0.275604 2.91973 0.168022C3.02731 0.0604393 3.17323 0 3.32537 0C3.47752 0 3.62343 0.0604393 3.73101 0.168022C3.8386 0.275604 3.89903 0.421518 3.89903 0.573663V4.13216C3.89904 4.2075 3.88421 4.2821 3.85538 4.3517C3.82655 4.4213 3.7843 4.48455 3.73103 4.53782C3.67775 4.59109 3.61451 4.63334 3.54491 4.66217C3.47531 4.691 3.40071 4.70583 3.32537 4.70583Z" fill="#AE7576"/>
      </svg>
    </CalendarStyled>
  )
}

const CalendarStyled = styled(IconStyled)``
