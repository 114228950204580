import React from 'react'
import styled from 'styled-components'
import { IconStyled } from './default_styled'

export const Goblet = (props) => {
  return (
    <GobletStyled {...props}>
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAtCAYAAADV2ImkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI+SURBVHgB7ZmNUcJAEIX3MhZAB4QKxA6gA61ArECpQKxArECsADsQKxAr4OiACsC3zAWP40juJ8fATL6ZzOSWveSx2dtsErFYLHpCiGe6ADabzZvgHYieQPQ9nTefeZ7fZWrwhG1O54tEdB94RxQWRDlHlL+wmxvOMzotXWwtbcxi+51OR/JA6J4Q3VWidxMwHrTb7Q86Acvl8hHixroN4xuI3V39TP+Rf4DD0Jgw5j9CieFzWMQOdbFMZk6EwwSOL5qphShPOWUoESodp7qNNUDL2PQVJQcxK8dc5dKKagTn4YD80P7a2VYEm7+oOBDns54OK7XVSYsOF9nNscAIKqGkcqRiryLYKBXM2CpHKsyKYKNSMAPRA4h+N8wz2CQFAGGcZl3DNrQtMhMnwQxEj4yeo/LyHTnOQZqpijByme8sWJ0sqnL4VgQbGflh9hzdLMteHeeSSqtcM+16BFe8BHMkcQKOhixsGA9wS61sT5XPrWaSIXXdKyUKfHsOlx7BFd+U2OLTc7j2CK4ECWZceg6fHsGVoJTQOVY5tgePrAg2rigerhzX9H8j2FYOiOb8zjU/74pgIzrCjEPPEXSTsVGLYKas5witCDaCF52JrXIwMRXBRm0RLpBS8kIr8nmGRdanGqktwhp1N/h7pBCclEZwahrBqWkEp6YRnJpGcGoawalpBKcmuIFXbzRt3/b0r0DcG+89beAJ5Nv1xZ+NmKfmHFuvwqdl+uCJWlIEF5cSMRGWFPDRcb1e/1IEf+gYPZC9u9d7AAAAAElFTkSuQmCC" alt="icone com um cálice"/>
    </GobletStyled>
  )
}

const GobletStyled = styled(IconStyled)``
