export class StringHelper {
  #str = ''

  constructor (str) {
    this.#str = str
  }

  static start (str) {
    return new StringHelper(str)
  }

  first () {
    this.#str = this.#str.split(' ')[0]
    return this
  }

  last () {
    const arr = this.#str.split(' ')
    this.#str = arr[arr.length - 1]
    return this
  }

  truncate (len) {
    if (this.#str.length >= len) this.#str = this.#str.substr(0, len).concat('...')
    return this
  }

  build () {
    return this.#str
  }
}

export const tags = {
  work: 'Trabalho',
  family: 'Família',
  health: 'Saúde',
  leisure: 'Lazer',
  harassment: 'Assédio'
}
