export const customTheme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1400px'],
  fonts: {
    body: '"Open Sans", sans-serif',
    heading: '"Roboto", "Open Sans", sans-serif',
    // heading: 'Playfair Display, "Open Sans", sans-serif',
    mono: 'monospace'
  },
  fontSizes: {
    xs: '0.875rem', // 14px
    sm: '1rem', // 16px
    md: '1.125rem', // 18px
    lg: '1.75rem', // 28px
    xl: '2.125rem' // 34px
  },
  gutters: '10px',
  colors: {
    black: '#313131',
    white: '#ffffff',
    orange: '#E14B2B',
    purple: '#3D1252',
    bgFooter: '#E3E3F2',
    gray: {
      200: '#DAD6D0',
      300: '#BDBDBD',
      400: '#D8D8D8',
      500: '#828282',
      600: '#4A5568'
    },
    blue: {
      700: '#2A3C4C'
    },
    pink: {
      600: '#AE7576'
    }
  },
  social: {
    width: '20px',
    height: '20px',
    color: '#E14B2B'
  }
}
