import React from 'react'
import PropTypes from 'prop-types'
import { FormInput } from '../form'
import { TplGroup } from './index'
import { Field } from 'formik'

const Input = ({ name, title, type, placeholder, isRequired, tabIndex, disabled, ...el }) => {
  const options = {
    ...(el.max) && { max: el.max },
    ...(el.maxLength) && { max: el.maxLength },
    ...(el.readonly) && { readonly: el.readonly }
  }

  const handleChange = event => {
    let value = event.target.value
    if (el.validations?.includes('email')) value = event.target.value.replace(/\s/g, '')

    if (el.setFieldValue) {
      el.setFieldValue(name, value)
    }
  }

  return (
    <TplGroup name={name} title={title} isRequired={isRequired}>
      <Field
        type={type || 'text'}
        name={name}
        placeholder={placeholder || ''}
        tabIndex={tabIndex}
        disabled={!!disabled}
        {...options}
        onChange={event => handleChange(event)}
        as={FormInput}
      />
    </TplGroup>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool
}
export default Input
