import React from 'react'
import PropTypes from 'prop-types'

const Blank = ({ children }) => {
  return (
    <>
      {children}
    </>
  )
}

Blank.propTypes = {
  children: PropTypes.node.isRequired
}
export default Blank
