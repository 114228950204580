import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Question from './components/question'
import { useFormik, FormikProvider, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { getUser } from '../../../utils/storage/user'
import { FaRegShareSquare } from 'react-icons/fa'
import { CreateQuestionsAnswer, GetQuestionsAnswer, UpdateQuestionsAnswer } from '../../../pages/mentor/controller'

const Questions = ['question_01', 'question_02', 'question_03']

const validationSchema = Yup.object().shape({
  question_01: Yup.string().required('Campo obrigatório*'),
  question_02: Yup.string().required('Campo obrigatório*'),
  question_03: Yup.string().required('Campo obrigatório*')

})
const StepTwo = () => {
  const user = getUser()
  const [userInfo, setUserInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const fase = 2

  const formik = useFormik({
    initialValues: {
      question_01: '',
      question_02: '',
      question_03: ''

    },
    validationSchema,
    onSubmit: values => handleSubmit(values)
  })

  useEffect(() => {
    if (userInfo !== null) {
      formik.setValues({
        question_01: userInfo?.data?.item?.find(item => item.question === 'question_01')?.response || '',
        question_02: userInfo?.data?.item?.find(item => item.question === 'question_02')?.response || '',
        question_03: userInfo?.data?.item?.find(item => item.question === 'question_03')?.response || ''
      })
    }
  }, [userInfo])

  const GetMyAnswer = useCallback(async () => {
    try {
      setLoading(true)
      const response = await GetQuestionsAnswer(user?.user?.email, fase)
      setUserInfo(JSON.parse(response))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    GetMyAnswer()
  }, [GetMyAnswer])

  const handleSubmit = async values => {
    const details = Object.values(values)
    const questionsAnswer = details?.map((item, index) => {
      return {
        id: Questions[index],
        question: Questions[index],
        response: item
      }
    })
    const create = {
      email: user?.user?.email,
      item: questionsAnswer,
      fase: 2,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }

    const update = {
      _id: userInfo?.data?._id,
      email: user?.user?.email,
      item: questionsAnswer,
      fase: 2,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()

    }
    if (userInfo?.data?._id) {
      await UpdateQuestionsAnswer(update)
    } else {
      await CreateQuestionsAnswer(create)
    }
    await GetMyAnswer()
  }

  return (
    <Container>
      <HeaderTitle>Direcionamento:</HeaderTitle>
      <NormalText>Agora vamos trabalhar para direcionar sua carreira.</NormalText>
      <div style={{ marginTop: '35px', display: 'flex', flexDirection: 'column', gap: 15 }}>
        <NormalTextGray>Clique abaixo para agendar suas 3 sessões de coaching individual
        </NormalTextGray>
        <a href="https://calendly.com/todasgroup/elas-lideram-primeira-sessao?month=2024-08" rel="noreferrer" target="_blank" style={{ textDecoration: 'none', width: '270px' }}>
          <ButtonStep>
            <FaRegShareSquare size={20} color="#fff" />
            Sessão 1
          </ButtonStep>
        </a>
        <a href="https://calendly.com/todasgroup/elas-lideram-segunda-sessao?month=2024-09" rel="noreferrer" target="_blank" style={{ textDecoration: 'none', width: '270px' }}>
          <ButtonStep>
            <FaRegShareSquare size={20} color="#fff" />
            Sessão 2
          </ButtonStep>
        </a>
        <a href="https://calendly.com/todasgroup/elas-lideram-terceira-sessao" rel="noreferrer" target="_blank" style={{ textDecoration: 'none', width: '270px' }}>
          <ButtonStep>
            <FaRegShareSquare size={20} color="#fff" />
            Sessão 3
          </ButtonStep>
        </a>
      </div>
      <NormalTextGray>Registre seus principais insights e combinados.</NormalTextGray>
      {loading
        ? (
          <NormalTextGrayBold>Carregando...</NormalTextGrayBold>
          )
        : (
          <FormikProvider value={formik}>
            <SubContainer onSubmit={formik.handleSubmit}>
              <Question placeHolder="Sessão 1" name="question_01" />
              <ErrorMessage name="question_01" component={ErrorTitle} className="error" />
              <Question placeHolder="Sessão 2" name="question_02" />
              <ErrorMessage name="question_02" component={ErrorTitle} className="error" />
              <Question placeHolder="Sessão 3" name="question_03" />
              <ErrorMessage name="question_03" component={ErrorTitle} className="error" />

              <StepBtn type="submit">
                Salvar
              </StepBtn>
            </SubContainer>
          </FormikProvider>
          )}
    </Container>
  )
}

export default StepTwo

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:13px;
  margin-top: 30px;
`

const HeaderTitle = styled.h4`
  font-size: 25.6px;
  font-weight: 400;
  color: #121214;
  line-height: 25.6px;
  font-family: "Playfair Display","Roboto","Open Sans",sans-serif;
`

const NormalText = styled.p`
  font-size: 16px;
  font-weight:500;
  color: #e14b2b;
  line-height: 16px;
  font-family: "Roboto","Open Sans",sans-serif;`

const NormalTextGray = styled.span`
  font-size: 16px;
  font-weight:400;
  color: #7A7A7A;
  line-height: 24px;
  font-family: "Roboto","Open Sans",sans-serif;
  `

const SubContainer = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:13px;
`
const StepBtn = styled.button`
display: flex;
border: none;
width: 170px;
min-width: 90px;
height: 50px;
align-items: center;
justify-content: center;
border-radius: 7px;
cursor: pointer;
background-color: #e14b2b;
color:white;
font-size: 1.0rem;
`

const ErrorTitle = styled.div`
font-size: 15px;
color: #121214;
margin-left: 15px;
`
const ButtonStep = styled.button`
display: flex;
  width: 270px;
  min-width: 270px;
  height: 50px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #e14b2b;
  border-color: #e14b2b;
  color: #FFF;
  font-weight: 700;
  font-size: 16px;
  gap: 10px;
`

const NormalTextGrayBold = styled.span`
font-size: 16px;
font-weight:500;
color: #7A7A7A;
line-height: 24px;
text-align: center;
font-family: "Roboto","Open Sans",sans-serif;
`
