/* eslint-disable no-console */
import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import Question from '../stepTwo/components/question'
import * as Yup from 'yup'
import { getUser } from '../../../utils/storage/user'
import { useFormik, FormikProvider, ErrorMessage } from 'formik'
import { CreateQuestionsAnswer, GetQuestionsAnswer, UpdateQuestionsAnswer } from '../../../pages/mentor/controller'

const Questions = ['question_01', 'question_02', 'question_03', 'question_04', 'question_05']

const validationSchema = Yup.object().shape({
  question_01: Yup.string().required('Campo obrigatório*'),
  question_02: Yup.string().required('Campo obrigatório*'),
  question_03: Yup.string().required('Campo obrigatório*'),
  question_04: Yup.string().required('Campo obrigatório*'),
  question_05: Yup.string().required('Campo obrigatório*')

})

const StepThree = () => {
  const user = getUser()
  const [userInfo, setUserInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const fase = 3

  const formik = useFormik({
    initialValues: {
      question_01: '',
      question_02: '',
      question_03: '',
      question_04: '',
      question_05: ''

    },
    validationSchema,
    onSubmit: values => handleSubmit(values)
  })

  useEffect(() => {
    if (userInfo !== null) {
      formik.setValues({
        question_01: userInfo?.data?.item?.find(item => item.question === 'question_01')?.response || '',
        question_02: userInfo?.data?.item?.find(item => item.question === 'question_02')?.response || '',
        question_03: userInfo?.data?.item?.find(item => item.question === 'question_03')?.response || '',
        question_04: userInfo?.data?.item?.find(item => item.question === 'question_04')?.response || '',
        question_05: userInfo?.data?.item?.find(item => item.question === 'question_05')?.response || ''
      })
    }
  }, [userInfo])

  const GetMyAnswer = useCallback(async () => {
    try {
      setLoading(true)
      const response = await GetQuestionsAnswer(user?.user?.email, fase)
      setUserInfo(JSON.parse(response))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    GetMyAnswer()
  }, [GetMyAnswer])

  const handleSubmit = async values => {
    const details = Object.values(values)
    const questionsAnswer = details?.map((item, index) => {
      return {
        id: Questions[index],
        question: Questions[index],
        response: item
      }
    })
    const create = {
      email: user?.user?.email,
      item: questionsAnswer,
      fase: 3,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }

    const update = {
      _id: userInfo?.data?._id,
      email: user?.user?.email,
      item: questionsAnswer,
      fase: 3,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()

    }
    if (userInfo?.data?._id) {
      await UpdateQuestionsAnswer(update)
    } else {
      await CreateQuestionsAnswer(create)
    }
    await GetMyAnswer()
  }

  return (
    <Container>
      <HeaderTitle>Desenvolvimento</HeaderTitle>
      <NormalText>Agora vamos trabalhar e desenvolver suas habilidades e potencialidades.</NormalText>
      <NormalTextGray>Espaço para sua <strong style={{ color: 'black', opacity: 0.8 }}>sessões em grupo</strong> com as mentoras. Registre seus principais insights.</NormalTextGray>
      <NormalTextItalic>"Apostar em suas fortalezas é trabalhar sua autenticidade." <strong style={{ fontStyle: 'normal' }}>Simone Murata</strong></NormalTextItalic>
      <NormalText>AGENDA DE WORKSHOPS</NormalText>
      {loading
        ? (
          <NormalTextGrayBold>Carregando...</NormalTextGrayBold>
          )
        : (
          <FormikProvider value={formik}>
            <SubContainer onSubmit={formik.handleSubmit}>
              <Schedule>
                <ContainerSchedule>
                  <TextTitleSchedule>Disciplina Positiva</TextTitleSchedule>
                  <DescriptionSchedule><strong style={{ color: 'black', opacity: 0.8 }}>Data:</strong> 01/08 <strong style={{ color: 'black', opacity: 0.8 }}>Horario:</strong> 09h às 12h</DescriptionSchedule>
                </ContainerSchedule>
                <Question placeHolder="Registre aqui seus insights" name="question_01" />
                <ErrorMessage name="question_01" component={ErrorTitle} className="error" />
              </Schedule>
              <Schedule>
                <ContainerSchedule>
                  <TextTitleSchedule>Autoconfiança</TextTitleSchedule>
                  <DescriptionSchedule><strong style={{ color: 'black', opacity: 0.8 }}>Data:</strong> 22/08 <strong style={{ color: 'black', opacity: 0.8 }}>Horario:</strong> 09h às 12h</DescriptionSchedule>
                </ContainerSchedule>
                <Question placeHolder="Registre aqui seus insights " name="question_02" />
                <ErrorMessage name="question_02" component={ErrorTitle} className="error" />
              </Schedule>
              <Schedule>
                <ContainerSchedule>
                  <TextTitleSchedule>Pensamento Crítico</TextTitleSchedule>
                  <DescriptionSchedule><strong style={{ color: 'black', opacity: 0.8 }}>Data:</strong> 26/09 <strong style={{ color: 'black', opacity: 0.8 }}>Horario:</strong> 09h às 12h</DescriptionSchedule>
                </ContainerSchedule>
                <Question placeHolder="Registre aqui seus insights " name="question_03" />
                <ErrorMessage name="question_03" component={ErrorTitle} className="error" />
              </Schedule>
              <Schedule>
                <ContainerSchedule>
                  <TextTitleSchedule>Posicionamento</TextTitleSchedule>
                  <DescriptionSchedule><strong style={{ color: 'black', opacity: 0.8 }}>Data:</strong>  24/10 <strong style={{ color: 'black', opacity: 0.8 }}>Horario:</strong> 09h às 12h</DescriptionSchedule>
                </ContainerSchedule>
                <Question placeHolder="Registre aqui seus insights " name="question_04" />
                <ErrorMessage name="question_04" component={ErrorTitle} className="error" />
              </Schedule>
              <Schedule>
                <ContainerSchedule>
                  <TextTitleSchedule>Inteligência Emocional</TextTitleSchedule>
                  <DescriptionSchedule><strong style={{ color: 'black', opacity: 0.8 }}>Data:</strong> 14/11 <strong style={{ color: 'black', opacity: 0.8 }}>Horario:</strong> 09h às 12h</DescriptionSchedule>
                </ContainerSchedule>
                <Question placeHolder="Registre aqui seus insights " name="question_05" />
                <ErrorMessage name="question_05" component={ErrorTitle} className="error" />
              </Schedule>
              <StepBtn type="submit">
                Salvar
              </StepBtn>
            </SubContainer>
          </FormikProvider>
          )}
    </Container>
  )
}

export default StepThree

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:13px;
  margin-top: 30px;
`

const HeaderTitle = styled.h4`
  font-size: 25.6px;
  font-weight: 400;
  color: #121214;
  line-height: 25.6px;
  font-family: "Playfair Display","Roboto","Open Sans",sans-serif;
`

const NormalText = styled.p`
  font-size: 16px;
  font-weight:500;
  color: #e14b2b;
  line-height: 16px;
  font-family: "Roboto","Open Sans",sans-serif;`

const NormalTextGray = styled.span`
  font-size: 16px;
  font-weight:400;
  color: #7A7A7A;
  line-height: 24px;
  font-family: "Roboto","Open Sans",sans-serif;
`
const NormalTextItalic = styled.p`
  font-size: 16px;
  color: #121214;
  font-style: italic;
  line-height: 24px;
  font-family: "Roboto","Open Sans",sans-serif;
`

const SubContainer = styled.form`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:13px;

  @media(max-width: 1127px) {
    display: flex;
    flex-direction: column;
  }
`
const Schedule = styled.div`
display: flex;
flex-direction: column;
`

const StepBtn = styled.button`
display: flex;
border: none;
width: 170px;
min-width: 90px;
height: 50px;
align-items: center;
justify-content: center;
border-radius: 7px;
cursor: pointer;
background-color: #e14b2b;
color: white;
font-size: 1.0rem;
position: relative;
top: 96px;
left: 30px;

@media(max-width: 1127px) {
  top: 0;
  left: 0;
  }
`

const ErrorTitle = styled.div`
font-size: 15px;
color: #121214;
margin-left: 15px;
`

const NormalTextGrayBold = styled.span`
font-size: 16px;
font-weight:500;
color: #7A7A7A;
line-height: 24px;
text-align: center;
font-family: "Roboto","Open Sans",sans-serif;
`

const ContainerSchedule = styled.div`
margin-bottom: -10px;
margin-top: 10px;
`

const TextTitleSchedule = styled.p`
font-size: 16px;
color: #000000;
font-weight: 600;
font-family: "Roboto","Open Sans",sans-serif;
`
const DescriptionSchedule = styled.p`
font-size: 16px;
font-family: "Roboto","Open Sans",sans-serif;
`
