import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'

const SubTitle = ({ title }) => {
  return (
    <SubTitleStyled>{title}</SubTitleStyled>
  )
}

SubTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default SubTitle

const SubTitleStyled = styled.h3`
  color: ${customTheme.colors.purple};
  font-size: 28px;
  font-weight: 700;
  position: relative;
  margin-bottom: 20px;
  line-height: 1;
`
