import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { Field } from 'formik'
const Question = ({ placeHolder, name }) => {
  return (
    <Container>
      <Label>{placeHolder}</Label>
      <Field as="textarea" name={name} rows="4" cols="50" />
    </Container>
  )
}

export default Question

Question.propTypes = {
  placeHolder: propTypes.string,
  name: propTypes.string
}

const Container = styled.div`
display:flex;
flex-direction: column;
height: 100%;
width: 100%;
`

const Label = styled.label`
  font-size: 14px;
  font-family: 'Roboto';
  color: #575757;
  margin-bottom: 8px;
  margin: 4px 6px;
  font-weight: 500;
`

// const StyledTextarea = styled.textarea`
// width: 100%;
// height: 70px;
// `
