import React from 'react'
import PropTypes from 'prop-types'
import { TextArea } from '../form'
import { TplGroup } from './index'
import { Field } from 'formik'

const Textarea = ({ name, title, placeholder, isRequired, tabIndex, disabled, ...el }) => {
  return (
    <TplGroup name={name} title={title} isRequired={isRequired}>
      <Field
        name={name}
        placeholder={placeholder || ''}
        tabIndex={tabIndex}
        disabled={!!disabled}
        as={TextArea}
        onChange={val => {
          if (el.setFieldValue) {
            el.setFieldValue(name, val.target.value)
          }
        }}
      />
    </TplGroup>
  )
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool
}

export default Textarea
