import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'

export const Button = styled.button`
  text-align: center;
  border: 1px solid transparent;
  transition: all .3s ease;
  cursor: pointer;
  font-weight: 700;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  font-size: ${customTheme.fontSizes.sm};
  background: ${customTheme.colors.orange};
  color: ${customTheme.colors.white};

  &:hover {
    background-color: ${customTheme.colors.purple};
    color: ${customTheme.colors.orange};
  }
  &:disabled {
    background: ${() => {
      const [r, g, b] = customTheme.colors.orange.match(/\w\w/g).map(x => parseInt(x, 16))
      return `rgba(${r}, ${g}, ${b}, .3)`
    }};
    color: ${customTheme.colors.white};
    border-color: transparent;
    cursor: not-allowed;
  }
`

export const ButtonCancel = styled(Button)`
  background: ${customTheme.colors.gray['500']};
  color: ${customTheme.colors.white};

  &:hover {
    background-color: transparent;
    color: ${customTheme.colors.pink['600']};
    border-color: ${customTheme.colors.pink['600']};
  }

  &:disabled {
    background: ${() => {
      const [r, g, b] = customTheme.colors.pink['600'].match(/\w\w/g).map(x => parseInt(x, 16))
      return `rgba(${r}, ${g}, ${b}, .3)`
    }};
      color: ${customTheme.colors.white};  
    }
`
export const ButtonOutline = styled.button`
  text-align: center;
  border: 1px solid transparent;
  transition: all .3s ease;
  cursor: pointer;
  font-weight: 700;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  font-size: ${customTheme.fontSizes.sm};
  background-color: transparent;
  color: ${customTheme.colors.pink['600']};
  border-color: ${customTheme.colors.pink['600']};
  &:hover {
    background: ${customTheme.colors.pink['600']};
    color: ${customTheme.colors.white};
  }
  &:disabled {
    background: ${() => {
      const [r, g, b] = customTheme.colors.pink['600'].match(/\w\w/g).map(x => parseInt(x, 16))
      return `rgba(${r}, ${g}, ${b}, .3)`
    }};
    color: ${customTheme.colors.white};
    border-color: transparent;
    cursor: not-allowed;
  }
`
